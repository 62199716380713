import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { T } from '@/modules/Language'
import { orderMutations } from '@/modules/Order'
import { salesHooks } from '@/modules/Sales'

import { OfferAction } from '~generated-types'

import { Offer as OfferFull, OfferInfo } from '../types'

type Offer = OfferFull | OfferInfo

export default function OfferService() {
  const { confirm } = useDialogService()
  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [createOfferMutation] = orderMutations.useCreateOfferMutation()
  const [removeOfferMutation] = orderMutations.useRemoveOfferMutation()
  const [triggerOfferActionMutation] =
    orderMutations.useTriggerOfferActionMutation()
  const [updateOfferMutation] = orderMutations.useUpdateOfferMutation()

  const updateOffers = (
    orderId: string,
    getOffers: (offers: Offer[]) => Offer[]
  ) =>
    setOrdersById((ordersById) => ({
      ...ordersById,
      [orderId]: {
        ...ordersById[orderId],
        offers: getOffers(ordersById[orderId].offers),
      },
    }))

  const createOffer = (orderId: string) =>
    createOfferMutation({ variables: { input: { orderId } } })
      .then(({ data }) => {
        if (data) {
          const offer = data.orderCreateOffer.offer

          updateOffers(orderId, (offers) => {
            const isAlreadyCreated = !!offers.find(({ id }) => id === offer.id)

            return isAlreadyCreated
              ? offers.map((o) => (o.id === offer.id ? offer : o))
              : [...offers, offer]
          })

          return offer
        }
      })
      .catch(() => undefined)

  const removeOffer = (offerId: string, orderId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>Orders:Confirmation.REMOVE_OFFER.description</T>,
      title: <T>Orders:Confirmation.REMOVE_OFFER.title</T>,
    })
      .then(() =>
        removeOfferMutation({ variables: { input: { offerId } } })
          .then(({ data }) => {
            if (data) {
              updateOffers(orderId, (offers) =>
                offers.filter(({ id }) => id !== offerId)
              )
            }

            return data
          })
          .catch(() => undefined)
      )
      .catch(() => undefined)

  const triggerOfferAction = (
    action: OfferAction,
    offerId: string,
    orderId: string
  ) =>
    triggerOfferActionMutation({ variables: { input: { action, offerId } } })
      .then(({ data }) => {
        if (data && data.orderOfferAction.offer) {
          const offer = data.orderOfferAction.offer

          updateOffers(orderId, (offers) =>
            offers.map((o) => (o.id === offerId ? offer : o))
          )
        }
      })
      .catch(() => undefined)

  const updateOffer = (
    offerId: string,
    orderId: string,
    validTo: string | null,
    validToDuration: number | null
  ) =>
    updateOfferMutation({
      variables: {
        input: {
          offerId,
          validTo,
          validToDuration,
        },
      },
    })
      .then(({ data }) => {
        if (data) {
          const offer = data.orderUpdateOffer.offer

          updateOffers(orderId, (offers) =>
            offers.map((o) => (o.id === offerId ? offer : o))
          )
        }
      })
      .catch(() => undefined)

  return {
    createOffer,
    removeOffer,
    triggerOfferAction,
    updateOffer,
  }
}
