import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import type { CalendarResourceElastic } from '@/modules/Registry'
import { generateCompareFn } from '@/utils/arrays'

import { SelectedResourceButton } from './SelectedResourceButton'

type Props = {
  addResourceSelection: (resourceId: string) => void
  allResources: CalendarResourceElastic[]
  disabled?: boolean
  loadingResources: boolean
  removeResourceSelection: (resourceId: string) => void
  selectedResources: CalendarResourceElastic[]
}

export const ResourceSelector = ({
  addResourceSelection,
  allResources,
  disabled,
  loadingResources,
  removeResourceSelection,
  selectedResources,
}: Props) => {
  const { language } = useLanguageContext()

  const selectedResourceIds = selectedResources.map(({ id }) => id)

  const resourceOptions = allResources
    .sort(generateCompareFn('name'))
    .filter(({ id }) => !selectedResourceIds.includes(id))
    .map(({ id, name }) => ({
      label:
        name || translate('ResourceReservations:unnamedResource', language),
      searchValue: name || '',
      value: id,
    }))

  return (
    <FilterSection
      label={<T>ResourceReservationsCalendar:ResourceSelector.title</T>}
      render={() => (
        <>
          <ThemedSelect
            blurInputOnSelect
            disabled={disabled}
            isDisabled={loadingResources}
            isLoading={loadingResources}
            isSearchable
            loadingMessage={() => (
              <T>ResourceReservationsCalendar:ResourceSelector.loading</T>
            )}
            name="resource-selector"
            noOptionsMessage={() => (
              <T>ResourceReservationsCalendar:ResourceSelector.empty</T>
            )}
            onChange={(target: Option | null | undefined) => {
              if (target && target.value) {
                addResourceSelection(target.value)
              }
            }}
            options={resourceOptions}
            placeholder={
              <>
                <FontAwesomeIcon icon="magnifying-glass" size="sm" />{' '}
                <T>ResourceReservationsCalendar:ResourceSelector.placeholder</T>
              </>
            }
            value={null}
          />
          <FlexRow wrap="wrap">
            {selectedResources.map(({ id, name }) => (
              <SelectedResourceButton
                key={`selected-resource-${id}`}
                label={name || id}
                onClick={() => removeResourceSelection(id)}
              />
            ))}
          </FlexRow>
        </>
      )}
    />
  )
}

export type ResourceSelectorProps = Props
