import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  Reservation,
  resourceReservationHooks,
  ResourceReservationSales,
  ResourceReservationTask,
} from '@/modules/Reservations/ResourceReservation'
import {
  Assignee,
  BasicDetails,
  Description,
  DueDate,
  DueTime,
} from '@/modules/Task/components'
import { useTheme } from '@/theme'

import { UseResourceReservationQuery } from '~generated-types'

import { EditButton, IconWrapper, Placeholder } from '../../components'
import { Context, Section } from '../../types'
import { Purchase } from './Purchase'

type Props = {
  context: Context
  readOnly: boolean
  refetchReservation: () => Promise<
    ApolloQueryResult<UseResourceReservationQuery>
  >
  reservationId: string
  sales: ResourceReservationSales
  setCurrentSection: (section: Section) => void
  task: ResourceReservationTask
  updateReservations: (reservations: Reservation[]) => void
}

export const TaskManager = ({
  context,
  readOnly,
  refetchReservation,
  reservationId,
  sales,
  setCurrentSection,
  task,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()

  const {
    assignee,
    description,
    dueDate,
    dueTime,
    id,
    isOpen,
    name,
    number,
    purchaseProducts,
  } = task

  const { closeTask, openTask, updateTask, setTaskAssignee } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isHover, setHover] = useState<boolean>(false)

  return (
    <>
      <BasicDetails
        closeTask={() => closeTask(id, reservationId)}
        isOpen={isOpen}
        name={name}
        number={number}
        openTask={() => openTask(id, reservationId)}
        readOnly={readOnly}
        updateName={(name: string) =>
          updateTask(task, { name: name }, reservationId)
        }
      />

      <Assignee
        assignee={assignee}
        readOnly={readOnly}
        updateAssignee={(assignee) =>
          setTaskAssignee(id, assignee, reservationId)
        }
      />

      <DueDate
        dueDate={dueDate}
        readOnly={readOnly}
        updateDueDate={(value: string) =>
          updateTask(task, { dueDate: value || null }, reservationId)
        }
      />

      <DueTime
        dueTime={dueTime}
        readOnly={!dueDate || readOnly}
        updateDueTime={(value: string) =>
          updateTask(task, { dueTime: value || null }, reservationId)
        }
      />

      <InlineModalLine
        style={{ margin: `${spacing.gutter} 0 ${spacing.gu(1)}rem 0` }}
      />

      <Description
        description={description}
        readOnly={readOnly}
        updateDescription={(value: string) =>
          updateTask(task, { description: value }, reservationId)
        }
      />

      <InlineModalLine
        style={{ margin: `${spacing.gu(1.5)}rem 0 ${spacing.gu(1)}rem 0` }}
      />

      {!!purchaseProducts.length &&
        [...purchaseProducts]
          .sort(
            (a, b) =>
              moment(a.auditLog.createdAt).valueOf() -
              moment(b.auditLog.createdAt).valueOf()
          )
          .map((purchase) => (
            <Purchase
              context={context}
              key={purchase.id}
              purchase={purchase}
              readOnly={readOnly}
              refetchReservation={refetchReservation}
              reservationId={reservationId}
              sales={sales}
              taskId={id}
              updateReservations={updateReservations}
            />
          ))}

      {!readOnly && (
        <InlineModalIconSection>
          <FlexRow>
            <EditButton
              onClick={() =>
                setCurrentSection({
                  previous: 'TASK_MANAGER',
                  taskId: id,
                  type: 'TASK_PURCHASE_SEARCH',
                })
              }
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <Placeholder>
                <T>ReservationModal:action.addProduct</T>
              </Placeholder>

              <IconWrapper>
                {isHover && (
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="plus"
                    size="sm"
                  />
                )}
              </IconWrapper>
            </EditButton>
          </FlexRow>
        </InlineModalIconSection>
      )}
    </>
  )
}
