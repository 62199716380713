import { ReactNode, useEffect, useState } from 'react'
import { parse, stringify } from 'query-string'
import ReactLoading from 'react-loading'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { List, ListContent, ListControls } from '@/components/List'
import type { RenderProps, SortOption } from '@/components/Reactivesearch'
import {
  ElasticBaseUrl,
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
  SortToggle,
} from '@/components/Reactivesearch'
import { H3 } from '@/components/Typography'
import { useKeycloakContext } from '@/config/keycloak'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import ListFooter from './components/ListFooter'
import ListPlaceholder from './components/ListPlaceholder'

type Align = 'left' | 'center' | 'right'

interface SelectedFiltersProps {
  clearAllLabel?: ReactNode
  l10nPrefixes?: Record<string, string>
  showClearAll?: 'never' | 'always' | 'default' | true | false
}

interface SortProps {
  options: SortOption[]
  setValue: (target: SortOption | null) => void
  value: SortOption | null
}

interface Query {
  [key: string]: any
}

interface ExactQueryProps {
  fields: string | string[]
  key: string
}

interface ElasticFilterSearchListProps<DataType> {
  align?: Align
  columnCount: number
  compact?: boolean
  indexName: string
  placeholders?: {
    empty?: ReactNode
    error?: ReactNode
  }
  reactiveListProps: any
  renderListControls?: () => ReactNode
  renderListFilters?: () => ReactNode | null | undefined
  renderListHeader: () => ReactNode
  renderListItem: (item: DataType, i: number) => ReactNode
  elasticData?: (data: any[]) => void
  selectedFiltersProps?: SelectedFiltersProps
  sortProps?: SortProps
  title?: ReactNode
}

const PAGE_SIZES = [5, 10, 20, 50]
const SIDEBAR_WIDTH = '320px'

function ElasticFilterSearchList<DataType>({
  align = 'center',
  columnCount,
  compact,
  indexName,
  placeholders: { empty: emptyLabel, error: errorLabel } = {},
  reactiveListProps,
  renderListControls,
  renderListFilters,
  renderListHeader,
  renderListItem,
  elasticData,
  selectedFiltersProps,
  sortProps: inputSortProps,
  title,
}: ElasticFilterSearchListProps<DataType>) {
  const { openRefreshModal } = useKeycloakContext()
  const history = useHistory()
  const { pathname } = useLocation()
  const theme = useTheme()

  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(
    reactiveListProps?.size || PAGE_SIZES[0]
  )

  const renderData = (data: DataType[]) =>
    data.length ? (
      data.map(renderListItem)
    ) : (
      <ListPlaceholder
        columnCount={columnCount}
        compact={compact}
        content={emptyLabel ? emptyLabel : <T>ElasticFilterSearchList:empty</T>}
        icon="circle-info"
      />
    )

  const renderError = () => (
    <ListPlaceholder
      columnCount={columnCount}
      compact={compact}
      content={errorLabel ? errorLabel : <T>ElasticFilterSearchList:error</T>}
      icon="circle-exclamation"
    />
  )

  const renderLoader = () => (
    <ListPlaceholder
      columnCount={columnCount}
      compact={compact}
      content={
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ReactLoading type={'spin'} color={theme.palette.smoke.main} />
          <span style={{ marginTop: theme.spacing.gutter }}>
            <T>ElasticFilterSearchList:loading</T>
          </span>
        </div>
      }
    />
  )

  const sortProps = inputSortProps && {
    options: inputSortProps.options,
    setValue: (target: SortOption | null) => {
      const { sort, ...rest } = parse(window.location.search)

      if (target) {
        history.replace(
          `${pathname}?${stringify({
            ...rest,
            sort: `${target.dataField}__${target.direction}`,
          })}`
        )
      } else {
        history.replace(`${pathname}?${stringify({ ...rest })}`)
      }

      inputSortProps.setValue(target)
    },
    value: inputSortProps.value,
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [pageSize])

  // Initialise sort order if in URL
  useEffect(() => {
    const { sort, ...rest } = parse(window.location.search)

    if (inputSortProps && typeof sort === 'string') {
      const [dataField, direction] = sort.split('__')
      const target = inputSortProps.options.find(
        (x) => x.dataField === dataField && x.direction === direction
      )

      if (target) {
        inputSortProps.setValue(target)
        return
      }
    }

    history.replace(`${pathname}?${stringify({ ...rest })}`)
  }, [])

  const defaultQuery = () => ({
    ...reactiveListProps.defaultQuery(),
    from: currentPage === 0 ? 0 : currentPage * pageSize,
  })

  return (
    <ReactiveBase app={indexName} url={ElasticBaseUrl}>
      <ReactiveList
        {...reactiveListProps}
        onQueryChange={(prevQuery: Query, nextQuery: Query) => {
          const prevString = JSON.stringify(prevQuery?.query ?? '')
          const nextString = JSON.stringify(nextQuery?.query ?? '')

          const prev = prevString.replace(/"queryVersion":\d*/, '')
          const next = nextString.replace(/"queryVersion":\d*/, '')

          if (prev !== next) {
            setCurrentPage(0)
          }

          return reactiveListProps.exactQueryProps
            ? getExactQuery(nextQuery, reactiveListProps.exactQueryProps)
            : null
        }}
        defaultQuery={defaultQuery}
        pagination={false}
        render={({
          data,
          error,
          loading,
          resultStats,
        }: RenderProps<DataType>) => {
          // Don't show the loader if we have data we can show
          const showLoader = loading && !data.length

          if (error?.status === 401) {
            openRefreshModal()
          }

          elasticData && elasticData(showLoader ? [] : data)

          const numberOfPages =
            resultStats?.numberOfPages || currentPage + 1 || 1
          const numberOfResults = resultStats?.numberOfResults || 0

          const hideControls: boolean =
            !!compact || (!title && !renderListControls)

          return (
            <ListWrapper align={align}>
              {!hideControls && (
                <ListControlsWrapper hasFilters={!!renderListFilters}>
                  <ListControls>
                    <FlexRow
                      alignItems="flex-end"
                      justifyContent="space-between"
                    >
                      {title && <ListTitle>{title}</ListTitle>}
                      {renderListControls && renderListControls()}
                      {sortProps && <SortToggle {...sortProps} />}
                    </FlexRow>
                    <FlexRow>
                      <SelectedFilters
                        clearAllLabel={
                          <T>ElasticFilterSearchList:resetFilters</T>
                        }
                        showClearAll="default"
                        {...selectedFiltersProps}
                      />
                    </FlexRow>
                  </ListControls>
                </ListControlsWrapper>
              )}
              <ListContainer isCompact={compact}>
                {renderListFilters &&
                  (compact ? (
                    renderListFilters()
                  ) : (
                    <ListFiltersWrapper isCompact={compact}>
                      {renderListFilters()}
                    </ListFiltersWrapper>
                  ))}
                <List>
                  {renderListHeader()}
                  <ListContent>
                    {showLoader
                      ? renderLoader()
                      : !!error
                      ? renderError()
                      : renderData(data)}
                  </ListContent>
                  <ListFooter
                    columnCount={columnCount}
                    currentPage={currentPage}
                    numberOfPages={numberOfPages}
                    numberOfResults={numberOfResults}
                    onSetPage={setCurrentPage}
                    onSetPageSize={setPageSize}
                    pageSize={pageSize}
                    pageSizes={PAGE_SIZES}
                    compact={compact}
                  />
                </List>
              </ListContainer>
            </ListWrapper>
          )
        }}
        renderError={() => null}
        renderNoResults={() => null}
        showLoader={false}
        showResultStats={false}
        size={pageSize}
      />
    </ReactiveBase>
  )
}

export default ElasticFilterSearchList

////////////

const getExactQuery = (nextQuery: Query, exactQueryProps: ExactQueryProps) => {
  if (nextQuery) {
    const { key, fields } = exactQueryProps

    const inputKey = `[aria-label="${key}"]`
    const query = (document.querySelector(inputKey) as HTMLInputElement).value

    if (query) {
      nextQuery.query.bool = {
        should: [
          ...nextQuery.query.bool.must,
          {
            bool: {
              must: [
                {
                  multi_match: {
                    fields,
                    query,
                    type: 'phrase',
                  },
                },
              ],
            },
          },
        ],
      }
    }
  }
}

interface ListWrapperProps {
  align?: Align
}

const ListWrapper = styled(FlexColumn)<ListWrapperProps>`
  max-width: 1500px;
  ${({ align }) =>
    align === 'left'
      ? 'margin-right: auto;'
      : align === 'right'
      ? 'margin-left: auto;'
      : 'margin: auto;'}
`

interface ListContainerProps {
  isCompact?: boolean
}

const ListContainer = styled.div<ListContainerProps>`
  display: flex;
  flex-direction: ${({ isCompact }) => (isCompact ? 'column' : 'row')};
  align-items: flex-start;
`

interface ListControlsWrapperProps {
  hasFilters?: boolean
}

const ListControlsWrapper = styled(FlexColumn)<ListControlsWrapperProps>`
  ${({ hasFilters }) =>
    !hasFilters
      ? css`
          margin: 0;
        `
      : css`
          margin-left: ${SIDEBAR_WIDTH};
        `}
`

interface ListFiltersWrapperProps {
  isCompact?: boolean
}

const ListFiltersWrapper = styled(FlexColumn)<ListFiltersWrapperProps>`
  align-self: flex-start;
  align-items: stretch;
  border-radius: 8px;

  ${({ theme }) => css`
    flex: 0 0 calc(${SIDEBAR_WIDTH} - ${theme.spacing.gutter});
    padding: ${theme.spacing.gutter};
    margin-right: ${theme.spacing.gutter};
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
  `}
`

const ListTitle = styled(H3)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gutterSmall} 0;
  `}
`
