import { ReactNode, Ref } from 'react'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

type Props = {
  icon: IconProp
  innerRef?: Ref<HTMLButtonElement>
  label: ReactNode
  onClick: () => void
  size: SizeProp
}

export const HeaderButton = ({
  icon,
  innerRef,
  label,
  onClick,
  size,
}: Props) => (
  <Button onClick={onClick} ref={innerRef}>
    <IconWrapper>
      <FontAwesomeIcon icon={icon} size={size} />
    </IconWrapper>
    <Label>{label}</Label>
  </Button>
)

////////////

const Button = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  height: 100%;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
    padding: 0 ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      background: ${theme.palette.smoke.light};
    `}
  }
`

const IconWrapper = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Label = styled.span`
  font-variant-caps: all-small-caps;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gutterSmall};
  `}
`
