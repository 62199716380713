import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'

import { useSalesDetailsContext } from '../../..'
import { Probability, Source, Totals } from './components'

export const Estimation = () => {
  const {
    data: { type, probabilityEstimate, probabilitySource, event },
  } = useSalesDetailsContext()

  const [estimation, setEstimation] = useState<number>(
    event?.probabilityEstimate || probabilityEstimate
  )

  return (
    <Wrapper flex={1} justifyContent="space-between">
      <Probability estimation={estimation} setEstimation={setEstimation} />

      {probabilitySource && type !== 'SALES' && (
        <Source source={probabilitySource} />
      )}

      <Totals estimation={estimation} />
    </Wrapper>
  )
}

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
  `}
`
