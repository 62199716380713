import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import { OPEN_STATES } from '@/modules/Sales'

import {
  PacketMealsListingQuery as QueryData,
  PacketMealsListingQueryVariables as QueryVariables,
} from '~generated-types'

type ScheduledMeal = QueryData['scheduledMeals'][0]
type MealType = ScheduledMeal['meal']
type MealParticipant = ScheduledMeal['participants'][0]
type Quantity = ScheduledMeal['quantities'][0]
type Restaurant = NonNullable<ScheduledMeal['location']>
type Sales = ScheduledMeal['sales']

const QUERY = gql`
  fragment PacketMealsListingCustomer on CustomerReference {
    customer {
      id
      ... on CustomerPerson {
        person {
          firstName
          lastName
        }
      }
      ... on CustomerOrganization {
        organization {
          businessId
          name
        }
      }
    }
  }

  query PacketMealsListing($input: ScheduledMealsInput!) {
    scheduledMeals(input: $input) {
      duration
      id
      location {
        id
        name
      }
      meal {
        id
        name
      }
      notes
      participants(input: { filter: { mustHaveAllergyOrDiet: true } }) {
        ageCategory {
          key
          name
          shortName
        }
        allergies
        allergyDescription
        diets
        firstName
        lastName
        id
      }
      quantities {
        ageCategory {
          key
          name
          shortName
        }
        quantity
      }
      sales {
        customer {
          ...PacketMealsListingCustomer
        }
        estimatedDates {
          end
          start
        }
        event {
          customer {
            ...PacketMealsListingCustomer
          }
          estimatedDates {
            end
            start
          }
          facet {
            abbreviation
            color
            id
            name
          }
          lifecycle {
            state
          }
          id
          name
          orderNumber
        }
        facet {
          abbreviation
          color
          id
          name
        }
        id
        lifecycle {
          state
        }
        name
        orderNumber
        type
      }
      start
      status
      totalQuantity
    }
  }
`

type Props = {
  from: Moment
  to: Moment
}

const usePacketMealsListing = ({ from, to }: Props) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
      },
    },
  })

  // Only include active sales
  const meals: ScheduledMeal[] = data
    ? data.scheduledMeals.filter(({ sales: { lifecycle } }) =>
        OPEN_STATES.includes(lifecycle.state)
      )
    : []

  return {
    error,
    loading,
    meals,
  }
}

export default usePacketMealsListing

export type ListingRestaurant = Restaurant
export type ListingScheduledMeal = ScheduledMeal
export type ListingMealType = MealType
export type ListingMealParticipant = MealParticipant
export type ListingSales = Sales
export type ListingQuantity = Quantity
