import { gql, useMutation } from '@apollo/client'

import type {
  CloseTaskMutation,
  CloseTaskMutationVariables,
} from '~generated-types'

import { task } from '../fragments'

export type { CloseTaskMutation, CloseTaskMutationVariables }

export const CLOSE_TASK_MUTATION = gql`
  ${task}

  mutation CloseTask($id: ID!) {
    closeTask(id: $id) {
      ...Task
    }
  }
`

export function useCloseTaskMutation() {
  return useMutation<CloseTaskMutation, CloseTaskMutationVariables>(
    CLOSE_TASK_MUTATION
  )
}

export default useCloseTaskMutation
