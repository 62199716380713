import { useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import {
  ActionButton,
  LoaderWrapper,
  RoomOptionsModal,
} from '@/modules/Products/components'
import { useSalesProductListContext } from '@/modules/Products/hooks'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

type Props = {
  productId: string
  purchaseId: string
  quantity: number
}

type SelectedRoom = {
  emptyBeds: number
  id: string
}

export const ExtractToRoomsButton = ({
  productId,
  purchaseId,
  quantity,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { extractPurchase, updatePurchase } = useSalesProductListContext()
  const {
    data: { id: salesId },
  } = useSalesDetailsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [selectedRooms, setSelectedRooms] = useState<SelectedRoom[]>([])

  const handleExtractPurcaseToRooms = () => {
    setModalOpen(false)
    setProcessing(true)

    return extractPurchase({
      productId,
      purchaseId,
      rooms: selectedRooms.map(({ id, emptyBeds }) => ({
        quantity: emptyBeds,
        roomReservationId: id,
      })),
    }).then((res) => {
      res && !res.source ? null : setProcessing(false)
      setSelectedRooms([])
    })
  }

  const handleCloseModal = () => {
    setSelectedRooms([])
    setModalOpen(false)
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <RoomOptionsModal
          handleExtract={handleExtractPurcaseToRooms}
          onClose={handleCloseModal}
          purchaseId={purchaseId}
          quantity={quantity}
          salesId={salesId}
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          updatePurchase={updatePurchase}
        />
      }
      onClose={handleCloseModal}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Wrapper ref={ref}>
          {isProcessing ? (
            <LoaderWrapper>
              <ReactLoading
                color={palette.smoke.dark}
                height={18}
                type="spin"
                width={18}
              />
            </LoaderWrapper>
          ) : (
            <ActionButton
              content={<T>Products:ProductManager.action.extractToRooms</T>}
              icon="door-open"
              iconColor={palette.primary.main}
              onClick={() => setModalOpen(true)}
              style={{ marginLeft: spacing.gutterSmall }}
            />
          )}
        </Wrapper>
      )}
      zIndex={10006}
    />
  )
}

///////

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
