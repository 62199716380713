import { useEffect, useState } from 'react'
import { get } from 'dot-prop'

import { FlexColumn } from '@/components/Layout'
import { ListItemSection } from '@/components/List'
import { CalendarResourcesService } from '@/modules/Registry'

import { ElasticTaskReservation } from '../../../../../types'

type Props = {
  reservation: ElasticTaskReservation | null | undefined
}

const ReservationInfo = ({ reservation }: Props) => {
  const [buildingName, setBuildingName] = useState<string | null | undefined>(
    null
  )
  const [resourceName, setResourceName] = useState<string | null | undefined>(
    null
  )

  useEffect(() => {
    const fetchResourceDetails = async (id: string) => {
      try {
        const resource = await CalendarResourcesService.find(id)

        setBuildingName(get(resource, 'building.name'))
        setResourceName(get(resource, 'name'))
      } catch (err) {
        console.warn('Error', err)
      }
    }

    if (reservation && reservation.resourceId) {
      fetchResourceDetails(reservation.resourceId)
    }
  }, [reservation])

  const locationValue = `${resourceName ? `${resourceName} ` : ''}${
    buildingName ? `(${buildingName})` : ''
  }`

  return (
    <ListItemSection>
      {reservation ? (
        <FlexColumn noPadding>
          {locationValue && <span>{locationValue}</span>}
          {reservation.description && <small>{reservation.description}</small>}
        </FlexColumn>
      ) : null}
    </ListItemSection>
  )
}

export default ReservationInfo
