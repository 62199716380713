import { useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'

import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { Gutter } from '@/components/Layout'
import type { SortOption } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { SearchListContext } from '@/modules/Sales'

import {
  InvoicesQuery as InvoicesQueryType,
  InvoicesQueryVariables,
} from '~generated-types'

import ListFilters from './components/ListFilters'
import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'
import InvoicesQuery from './Invoices.query'
import { ElasticSearchInvoice } from './Invoices.types'

const INVOICE_INDEX = 'order.invoice'

const ComponentIds = Object.freeze({
  BOOKKEEPING: 'bookkeepingTransferred',
  CHECKS: 'checks',
  INVOICE_DATE: 'invoiceDate',
  INVOICE_TYPE: 'type',
  LIST: 'page',
  PAYMENT_TYPE: 'paymentType',
  SALE_TYPE: 'sale-type',
  SALES_END_DATE: 'sales-start-date',
  SALES_START_DATE: 'sales-end-date',
  SALES_STATE: 'sales-state',
  SEARCH: 'invoice-search',
  SEARCH_SALES: 'invoice-sales-search',
  STATE: 'state',
  STATUS: 'paymentStatus',
})

type SalesSearchListProps = {
  hideFilters?: boolean
}

export const InvoiceSearchList = ({ hideFilters }: SalesSearchListProps) => {
  const { language } = useLanguageContext()

  const history = useHistory()
  const { pathname } = useLocation()

  const allElasticDataIds = useRef<string[]>([])

  const handleOpenItem = (itemId: string) => {
    // We need to use window.location.search, because we use ReactiveSearch
    // components in the filters and their URLParams-functionality does not play
    // nicely with search in useLocation()
    const search = window.location.search

    history.push(
      `/sales/details/${itemId}?view[0]=checks&view[1]=orders&close-url=${encodeURIComponent(
        `${pathname}${search}`
      )}`
    )
  }

  const sortOptions: SortOption[] = [
    {
      dataField: 'invoiceNumber',
      direction: 'desc',
      label: translate(
        'InvoicesSearchList:SortOptions.invoiceNumberDesc',
        language
      ),
    },
    {
      dataField: 'invoiceNumber',
      direction: 'asc',
      label: translate(
        'InvoicesSearchList:SortOptions.invoiceNumberAsc',
        language
      ),
    },
    {
      dataField: 'invoiceDate',
      direction: 'desc',
      label: translate(
        'InvoicesSearchList:SortOptions.invoiceDateDesc',
        language
      ),
    },
    {
      dataField: 'sales.searchDates.start',
      direction: 'desc',
      label: translate(
        'InvoicesSearchList:SortOptions.salesStartDate',
        language
      ),
    },
    {
      dataField: 'sales.searchDates.end',
      direction: 'desc',
      label: translate('InvoicesSearchList:SortOptions.salesEndDate', language),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { [sortProperty.dataField]: sortProperty.direction },
        { createdAt: 'desc' },
      ]
    : { createdAt: 'desc' }

  const defaultQueryFn = () => ({})

  const [loadInvoices, { data: invoicesData, loading }] = useLazyQuery<
    InvoicesQueryType,
    InvoicesQueryVariables
  >(InvoicesQuery)

  const handleLoadInvoices = (ids: string[]) =>
    loadInvoices({
      variables: {
        input: {
          ids,
        },
      },
    })

  const onElasticData = (data: ElasticSearchInvoice[]) => {
    if (!data || loading) {
      return
    }

    const gqlDataIds = invoicesData?.invoicesByIds
      ? invoicesData?.invoicesByIds.invoices.map(({ id }) => id)
      : []
    const elasticDataIds = data.map(({ id }) => id)
    const prevElasticDataIds = allElasticDataIds.current

    const isElasticIdsEqual =
      JSON.stringify(prevElasticDataIds.sort()) ===
      JSON.stringify(elasticDataIds.sort())

    if (isElasticIdsEqual) {
      return
    }

    const isIdsEqual =
      JSON.stringify(gqlDataIds.sort()) ===
      JSON.stringify(elasticDataIds.sort())

    if (!isIdsEqual) {
      allElasticDataIds.current = elasticDataIds
      handleLoadInvoices(elasticDataIds)
    }
  }

  const FILTERS = [
    'bookkeepingTransferred',
    'salesEndDate',
    'salesStartDate',
    'invoiceDate',
    'type',
    'paymentStatus',
    'invoiceState',
    'saleState',
    'paymentType',
    'saleType',
    'checks',
  ]

  return (
    <Gutter type={[0, 5, 10]}>
      <ElasticFilterSearchList
        columnCount={8}
        indexName={INVOICE_INDEX}
        reactiveListProps={{
          componentId: ComponentIds.LIST,
          dataField: 'name',
          defaultQuery: () => ({
            ...defaultQueryFn(),
            sort,
          }),
          react: {
            and: [
              ComponentIds.CHECKS,
              ComponentIds.INVOICE_DATE,
              ComponentIds.PAYMENT_TYPE,
              ComponentIds.SALE_TYPE,
              ComponentIds.SALES_END_DATE,
              ComponentIds.SALES_START_DATE,
              ComponentIds.SALES_STATE,
              ComponentIds.SEARCH,
              ComponentIds.SEARCH_SALES,
              ComponentIds.STATE,
              ComponentIds.STATUS,
              ComponentIds.BOOKKEEPING,
              ComponentIds.INVOICE_TYPE,
            ],
          },
          size: 50,
        }}
        renderListFilters={
          hideFilters
            ? undefined
            : () => (
                <ListFilters
                  searchContext={SearchListContext.INVOICE}
                  filterNames={FILTERS}
                  componentIds={ComponentIds}
                  getDefaultQuery={defaultQueryFn}
                  URLParams
                />
              )
        }
        renderListHeader={() => <ListHeader />}
        renderListItem={(i: ElasticSearchInvoice) => (
          <ListItem
            data={i}
            gqlData={
              invoicesData?.invoicesByIds?.invoices.find(
                (invoice) => invoice.id === i.id
              ) || null
            }
            key={i.id}
            onClick={handleOpenItem}
          />
        )}
        selectedFiltersProps={{
          l10nPrefixes: {
            paymentStatus: 'Orders:PaymentStatus',
            paymentType: 'Orders:PaymentType',
            'sales-state': 'SalesDetails:Lifecycle.state',
            state: 'Orders:InvoiceState',
            tasksStatus: 'InvoicesSearchList:ListHeader.filter.tasksStatuses',
            type: 'Orders:Header.title',
          },
        }}
        sortProps={{
          options: sortOptions,
          setValue: setSortProperty,
          value: sortProperty,
        }}
        title={<T>InvoicesSearchList:title</T>}
        elasticData={onElasticData}
      />
    </Gutter>
  )
}

export default InvoiceSearchList
