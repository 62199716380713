import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lighten } from 'polished'
import Media from 'react-media'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'

import LastUsedModal from './LastUsedModal'

const LastUsedButton = () => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <ModalContainer
        isOpen={isOpen}
        modal={<LastUsedModal closeSelector={() => setOpen(false)} />}
        onClose={() => setOpen(false)}
        placement="bottom-end"
        referenceElement={({ ref }) => (
          <NavigationLinkWrapper ref={ref}>
            <StyledButton
              activeClassName="is-active"
              isActive={() => isOpen}
              to={'/'}
              onClick={(e) => {
                e.preventDefault()
                setOpen(true)
              }}
            >
              <FontAwesomeIcon icon="clock-rotate-left" />
              <Media query={{ minWidth: 1150 }}>
                {(matches) =>
                  matches && (
                    <Label>
                      <T>Sales:last</T>
                    </Label>
                  )
                }
              </Media>
            </StyledButton>
          </NavigationLinkWrapper>
        )}
      />
    </>
  )
}

export default LastUsedButton

const Label = styled.span`
  font-weight: 600;
  user-select: none;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const NavigationLinkWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
`

const StyledButton = styled(NavLink)`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  position: relative;
  border: 0;
  text-decoration: none;
  font-weight: 500;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  &.is-active {
    ${({ theme }) => css`
      color: ${theme.palette.primary.dark};
      background: ${lighten(0.05, theme.palette.primary.extraLight)};
    `}
  }

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
      background: ${theme.palette.primary.extraLight};
    `}
  }
`
