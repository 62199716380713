import { Fragment, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { RadioInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { SalesDimension } from '@/modules/Dimensions'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { EmptySpace, ExpansionButton, LineLeader } from './common'
import { activateRadioButtonItem, expandItem, Hierarchy } from './utils'

type Props = {
  items: Hierarchy[]
  selectedItems: SalesDimension[]
  setSelectedItems: (item: SalesDimension[]) => void
}

export const RadioButtonTree = ({
  items,
  selectedItems,
  setSelectedItems,
}: Props) => {
  const theme = useTheme()

  const [data, setData] = useState(items)

  const sortData = (data: Hierarchy[]): Hierarchy[] =>
    data.sort(generateCompareFn('name')).map((item) => ({
      ...item,
      childs: sortData(item.childs),
    }))

  const dataSorted = sortData([...data])

  const renderRadioButtonLevel = (levelItems: Hierarchy[]) => (
    <>
      {levelItems.map((levelItem: Hierarchy, index: number) => (
        <Fragment key={levelItem.key}>
          <FlexRow alignItems="center">
            {levelItem.nestingLevel > 0 && (
              <>
                {levelItem.nestingLevel > 1 && (
                  <EmptySpace width={levelItem.nestingLevel - 1} />
                )}
                <LineLeader
                  isLast={levelItems.length === index + 1}
                  isLong={!levelItem.childs.length}
                  height={levelItem.nestingChilds + 1}
                />
              </>
            )}
            {!!levelItem.childs.length && (
              <ExpansionButton
                onClick={() => expandItem(levelItem.key, data, setData)}
              >
                <FontAwesomeIcon
                  icon={levelItem.isExpanded ? 'angle-down' : 'angle-right'}
                />
              </ExpansionButton>
            )}
            <RadioInput
              checked={levelItem.isActive}
              onChange={() =>
                activateRadioButtonItem(
                  levelItem.uniqueKey,
                  data,
                  selectedItems,
                  setData,
                  setSelectedItems
                )
              }
              style={{ margin: `${theme.spacing.guPx(1) - 3}px 0` }}
            >
              {levelItem.name}
            </RadioInput>
          </FlexRow>
          {levelItem.isExpanded && renderRadioButtonLevel(levelItem.childs)}
        </Fragment>
      ))}
    </>
  )

  return renderRadioButtonLevel(dataSorted)
}
