import {
  MobileSalesMeal as Meal,
  MobileSalesResourceReservation as Reservation,
  MobileSalesResourceReservationWithTasks as ReservationWithTasks,
  MobileSalesTask as Task,
} from '@/modules/Mobile/SalesList'

import { ResourceReservationCustomerVisibility as CustomerVisibility } from '~generated-types'

import { getDimensionValues } from './getDimensionValues'

export enum ProgramTypeKey {
  Meal = 'MEAL',
  Reservation = 'RESERVATION',
  ReservationGroup = 'RESERVATION_GROUP',
  Task = 'TASK',
}

type ProgramType = { __type: ProgramTypeKey }

export type Group = {
  end: string
  id: string
  name: string | null
  reservations: ReservationWithTasks[]
  start: string
}

type MealWithType = Meal & ProgramType
type ReservationWithType = ReservationWithTasks & ProgramType
type GroupWithType = Group & ProgramType
type TaskWithType = Task & ProgramType & { start: string }

export type ProgramLine =
  | GroupWithType
  | MealWithType
  | ReservationWithType
  | TaskWithType

type ProgramByDate = {
  [dateKey: string]: ProgramLine[]
}

type Props = {
  meals: Meal[]
  reservations: Reservation[]
  showAllResources: boolean
  tasks: Task[]
}

export const getProgramByDateKey = ({
  meals: inputMeals,
  reservations: inputReservations,
  showAllResources,
  tasks: inputTasks,
}: Props) => {
  const meals: MealWithType[] = inputMeals.map((x) => ({
    ...x,
    __type: ProgramTypeKey.Meal,
  }))

  const tasks: TaskWithType[] = inputTasks
    .filter(({ reservation }) => !reservation)
    .map((x) => ({
      ...x,
      __type: ProgramTypeKey.Task,
      start: x.dueTime ? `${x.dueDate}T${x.dueTime}` : `${x.dueDate}T23:59:59`,
    }))

  const reservations = inputReservations
    .map((r) => ({
      ...r,
      __type: ProgramTypeKey.Reservation,
      tasks: inputTasks.filter(({ reservation }) => reservation?.id === r.id),
    }))
    .filter((x) => {
      if (showAllResources) {
        return true
      }

      const isVisible = x.customerVisibility === CustomerVisibility.Visible
      const isResourceHidden =
        x.customerVisibility === CustomerVisibility.ResourceNameHidden

      const hasDescription = !!x.description
      const hasDimensions = !!getDimensionValues(x.dimensions).length
      const hasTasks = !!x.tasks.filter((t) => !!t.purchaseProducts.length)
        .length
      const hasData = hasDescription || hasDimensions || hasTasks

      return isVisible || (isResourceHidden && hasData)
    })

  const reservationsById: { [id: string]: ReservationWithType } =
    reservations.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})

  const singleReservations = Object.values(reservationsById).filter(
    (x) => !x.group
  )

  const reservationGroupsById: { [id: string]: GroupWithType } =
    reservations.reduce(
      (acc, { group }) =>
        group
          ? {
              ...acc,
              [group.id]: {
                __type: ProgramTypeKey.ReservationGroup,
                end: group.customerEnd,
                id: group.id,
                name: group.name,
                reservations: group.reservations
                  .map((x) => reservationsById[x.id])
                  .filter((x) => !!x),
                start: group.customerStart,
              },
            }
          : acc,
      {}
    )

  const reservationGroups = Object.values(reservationGroupsById)

  const byDate = [
    ...meals,
    ...singleReservations,
    ...reservationGroups,
    ...tasks,
  ].reduce((acc: ProgramByDate, val) => {
    const dateKey = val.start ? val.start.substring(0, 10) : null

    if (!dateKey) return acc

    acc[dateKey] = acc[dateKey] || []
    acc[dateKey].push(val)

    return acc
  }, {})

  return byDate
}
