import { gql, useMutation } from '@apollo/client'

import {
  CreateTaskMutation,
  CreateTaskMutationVariables,
} from '~generated-types'

import { task } from '../fragments'

export type { CreateTaskMutation, CreateTaskMutationVariables }

export const CREATE_TASK_MUTATION = gql`
  ${task}

  mutation CreateTask($input: CreateTaskInput) {
    createTask(create: $input) {
      ...Task
    }
  }
`

export function useCreateTaskMutation() {
  return useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CREATE_TASK_MUTATION
  )
}

export default useCreateTaskMutation
