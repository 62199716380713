import { gql, useMutation } from '@apollo/client'

import {
  UpdateTaskMutation,
  UpdateTaskMutationVariables,
} from '~generated-types'

import { task } from '../fragments'

export type { UpdateTaskMutation, UpdateTaskMutationVariables }

export const UPDATE_TASK_MUTATION = gql`
  ${task}

  mutation UpdateTask($id: ID!, $input: UpdateTaskInput) {
    updateTask(id: $id, update: $input) {
      ...Task
    }
  }
`

export function useUpdateTaskMutation() {
  return useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UPDATE_TASK_MUTATION
  )
}

export default useUpdateTaskMutation
