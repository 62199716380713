import React from 'react'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn } from '@/components/Layout'
import { FlexRow } from '@/components/Layout'
import { CheckboxSelector } from '@/components/ReservationsOffset/components'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'
import {
  SalesRoomReservation,
  SalesRoomTypeReservation,
} from '../SalesReservationList.types'
import {
  Reservation,
  SalesAccommodationGroup,
  SalesRoomTypeReservationWithRoomReservations,
} from '../SalesReservationList.types'
import { ReservationManagementControls } from './ReservationManagementControls'
import { ReservationRow } from './ReservationRow'
import { TargetHeader } from './TargetHeader'

type RowAdditionalProps = {
  isNested: boolean
  isLastNested: boolean
  isFulfilled: boolean
}

type Props = {
  group: SalesAccommodationGroup
  readOnly?: boolean
  salesId: string
  handleOpenManager: (targetId?: string) => void
  handleOpenRoomLayout: (targetId?: string) => void
  selectProcess: boolean
  targetIdsToMove: string[]
  setTargetIdsToMove: (ids: string[]) => void
}

export const ReservationListContent = ({
  group,
  readOnly,
  salesId,
  handleOpenManager,
  handleOpenRoomLayout,
  selectProcess,
  targetIdsToMove,
  setTargetIdsToMove,
}: Props) => {
  const theme = useTheme()

  const { saleType, handleAddTarget } = useReservationListContext()
  const { data } = useSalesDetailsContext()

  const onAddTarget = () =>
    handleAddTarget({
      accommodationGroupId: group.id,
      name: '',
      shareToSalesId: data?.id === salesId ? null : salesId,
    })

  const handleSelectTarget = (toSelect: boolean, targetId: string) =>
    setTargetIdsToMove(
      toSelect
        ? [...targetIdsToMove, targetId]
        : targetIdsToMove.filter((id) => id !== targetId)
    )

  const defaultTargetConsumption = group.targets
    .map(
      (target) => target.status.status.consumptionBreakdown.floatingConsumption
    )
    .reduce((a: number, b: number) => a + b, 0)

  const disableReservationManager =
    readOnly ||
    (saleType === 'EVENT'
      ? !group?.settings?.consumptionDurationType ||
        !group?.settings?.consumptionType
      : false)

  const disableRoomLayout =
    readOnly ||
    (!group.roomReservations.length && !group.roomTypeReservations.length)

  const renderReservation = (reservation: Reservation) => {
    const renderRoomReservation = (
      reservation: SalesRoomReservation,
      additionalProps?: RowAdditionalProps
    ) => (
      <ReservationRow
        reservation={reservation}
        targets={group.targets}
        readOnly={readOnly}
        {...additionalProps}
      />
    )

    if (reservation.__typename === 'RoomReservation') {
      return renderRoomReservation(reservation)
    }

    if (reservation.__typename === 'RoomTypeReservation') {
      return (
        <>
          <ReservationRow
            reservation={reservation}
            hasNested={!!reservation.roomReservations.length}
            targets={group.targets}
            readOnly={readOnly}
          />
          {reservation.roomReservations.map(
            (roomReservation: SalesRoomReservation, index: number) => (
              <React.Fragment key={roomReservation.id}>
                {renderRoomReservation(roomReservation, {
                  isFulfilled: reservation.fulfilledByRooms,
                  isLastNested:
                    index === reservation.roomReservations.length - 1,
                  isNested: true,
                })}
              </React.Fragment>
            )
          )}
        </>
      )
    }

    return null
  }

  const renderReservationList = (
    group: SalesAccommodationGroup,
    targetId?: string
  ) => {
    const topLevelRoomReservations = group.roomReservations
      .filter(({ __typename }) => __typename !== 'PendingRoomReservation')
      .filter(({ target }) =>
        targetId ? target?.id === targetId : target === null
      )
      .filter(
        (roomReservation) =>
          (roomReservation as SalesRoomReservation).type === 'INDEPENDENT'
      )

    const roomTypeReservations = group.roomTypeReservations
      .filter(({ __typename }) => __typename !== 'PendingRoomTypeReservation')
      .filter(({ target }) =>
        targetId ? target?.id === targetId : target === null
      )
      .map((roomTypeReservation) => ({
        ...(roomTypeReservation as SalesRoomTypeReservation),
        roomReservations: group.roomReservations.filter(
          (roomReservation) =>
            (roomReservation as SalesRoomReservation).roomTypeReservation
              ?.id === roomTypeReservation.id
        ),
      }))

    // @ts-ignore
    const reservations: (
      | SalesRoomTypeReservationWithRoomReservations
      | SalesRoomReservation
    )[] = [...roomTypeReservations, ...topLevelRoomReservations].sort(
      generateCompareFn('request.checkIn.date')
    )

    return !reservations.length ? (
      <EmptyPlaceholder>
        <T>Accommodation:SalesReservationList.empty</T>
      </EmptyPlaceholder>
    ) : (
      reservations.map((x) => (
        <React.Fragment key={x.id}>{renderReservation(x)}</React.Fragment>
      ))
    )
  }

  return (
    <Content>
      <TargetsHeader>
        <FontWeight semiBold style={{ textTransform: 'uppercase' }}>
          <T>Accommodation:TargetGroup.targets</T>
        </FontWeight>

        <InnocuousButton disabled={readOnly} compact onClick={onAddTarget}>
          <PrimaryColor>
            <T>Accommodation:TargetGroup.addTarget</T>
          </PrimaryColor>
        </InnocuousButton>
      </TargetsHeader>

      <FlexColumn>
        {[...group.targets]
          .sort(generateCompareFn('sortOrder'))
          .map((target) => (
            <TargetContainer key={target.id}>
              <FlexRow>
                {selectProcess && (
                  <>
                    <Spacer />
                    <CheckboxSelector
                      noMargin
                      isChecked={targetIdsToMove.includes(target.id)}
                      handleSelect={(toSelect) =>
                        handleSelectTarget(toSelect, target.id)
                      }
                    />
                  </>
                )}

                <TargetHeader
                  target={target}
                  readOnly={readOnly}
                  defaultConsumption={defaultTargetConsumption}
                  setManagerOpen={handleOpenManager}
                  isManagerDisabled={disableReservationManager}
                />
              </FlexRow>

              <HorizontalDivider />

              <TargetContentWrapper>
                {renderReservationList(group, target.id)}
              </TargetContentWrapper>

              <ReservationManagementControls
                targetId={target.id}
                isRoomLayoutDisabled={disableRoomLayout}
                setManagerOpen={handleOpenManager}
                setRoomLayoutOpen={handleOpenRoomLayout}
                isManagerDisabled={disableReservationManager}
                style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}
              />
            </TargetContainer>
          ))}
      </FlexColumn>
    </Content>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const TargetsHeader = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem;
  `}
`

const TargetContainer = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
    border: 1px solid ${theme.palette.smoke.main};
  `}

  border-radius: 6px;
  box-shadow: 0 0px 6px 1px rgba(0, 0, 0, 0.08);
`

const TargetContentWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter} 0;
  `}
`

const HorizontalDivider = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
  `}
  height: 1px;
  width: 100%;
`

const Spacer = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const EmptyPlaceholder = styled.div`
  ${({ theme }) => `
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
    margin-bottom: ${theme.spacing.gu(1)}rem;

    font-size: ${theme.typography.fontSizeBase2};
  `}

  line-height: 1.1;
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
