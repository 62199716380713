import React, {
  createContext,
  MouseEvent,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'

import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModal,
  InlineModalContent,
  InlineModalDescription,
  InlineModalFooter,
  InlineModalSection,
  InlineModalTitle,
} from '@/components/InlineModal'

import { DialogBackdrop } from './DialogBackdrop'

type ConfirmDialogOptions = {
  cancelLabel: ReactNode
  confirmLabel: ReactNode
  description: ReactNode
  title?: ReactNode
}

type WarnDialogOptions = {
  cancelLabel: ReactNode
  description: ReactNode
  title?: ReactNode
}

type ConfirmDialogType = {
  options: ConfirmDialogOptions
  type: 'CONFIRM'
}

type WarnDialogType = {
  options: WarnDialogOptions
  type: 'WARN'
}

type DialogServiceContextType = {
  confirm: (arg0: ConfirmDialogOptions) => Promise<void>
  warn: (arg0: WarnDialogOptions) => Promise<void>
}

const DialogServiceContext = createContext<DialogServiceContextType>({
  confirm: () => Promise.reject(),
  warn: () => Promise.reject(),
})

type DialogServiceProviderProps = {
  children: ReactNode
}

export const DialogServiceProvider = ({
  children,
}: DialogServiceProviderProps) => {
  const awaitingPromiseRef = useRef<{
    resolve: () => void
    reject: () => void
  } | null>(null)

  const [dialog, setDialog] = useState<
    ConfirmDialogType | WarnDialogType | null | undefined
  >(null)

  const contextValueRef = useRef<DialogServiceContextType>({
    confirm: (options: ConfirmDialogOptions) => {
      setDialog({ options, type: 'CONFIRM' })

      return new Promise<void>((resolve, reject) => {
        awaitingPromiseRef.current = { reject, resolve }
      })
    },
    warn: (options: WarnDialogOptions) => {
      setDialog({ options, type: 'WARN' })

      return new Promise<void>((resolve, reject) => {
        awaitingPromiseRef.current = { reject, resolve }
      })
    },
  })

  const handleCancel = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setDialog(null)
  }

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setDialog(null)
  }

  const renderDialog = (dialog: ConfirmDialogType | WarnDialogType) => {
    switch (dialog.type) {
      case 'CONFIRM':
        return (
          <>
            {dialog.options.title && (
              <InlineModalSection>
                <DialogTitle>{dialog.options.title}</DialogTitle>
              </InlineModalSection>
            )}

            <InlineModalContent>
              <InlineModalSection>
                <InlineModalDescription>
                  {dialog.options.description}
                </InlineModalDescription>
              </InlineModalSection>
            </InlineModalContent>

            <InlineModalFooter justifyContent="flex-end">
              <InnocuousButton onClick={handleCancel}>
                {dialog.options.cancelLabel}
              </InnocuousButton>
              <InnocuousButton onClick={handleConfirm}>
                {dialog.options.confirmLabel}
              </InnocuousButton>
            </InlineModalFooter>
          </>
        )
      case 'WARN':
        return (
          <>
            {dialog.options.title && (
              <InlineModalSection>
                <DialogTitle>{dialog.options.title}</DialogTitle>
              </InlineModalSection>
            )}

            <InlineModalContent>
              <InlineModalSection>
                <InlineModalDescription>
                  {dialog.options.description}
                </InlineModalDescription>
              </InlineModalSection>
            </InlineModalContent>

            <InlineModalFooter justifyContent="flex-end">
              <InnocuousButton onClick={handleCancel}>
                {dialog.options.cancelLabel}
              </InnocuousButton>
            </InlineModalFooter>
          </>
        )
      default:
        return null
    }
  }

  return (
    <DialogServiceContext.Provider value={contextValueRef.current}>
      {children}

      {!!dialog && (
        <DialogPortal>
          <DialogBackdrop>
            <StyledDialog
              onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
            >
              {renderDialog(dialog)}
            </StyledDialog>
          </DialogBackdrop>
        </DialogPortal>
      )}
    </DialogServiceContext.Provider>
  )
}

export const useDialogService = () => useContext(DialogServiceContext)

////////////

type DialogPortalProps = {
  children: ReactNode
}

const DialogPortal = ({ children }: DialogPortalProps) =>
  createPortal(children, document.getElementById('dialog-root') as any)

const StyledDialog = styled(InlineModal)`
  max-width: 480px;
`

const DialogTitle = styled(InlineModalTitle)`
  font-weight: 600;
`
