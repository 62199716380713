import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  UpdateOfferMutation,
  UpdateOfferMutationVariables,
} from '~generated-types'

const UPDATE_OFFER_MUTATION = gql`
  ${orderFragments.offer}

  mutation UpdateOffer($input: OrderUpdateOfferInput!) {
    orderUpdateOffer(input: $input) {
      offer {
        ...Offer
      }
    }
  }
`

export const useUpdateOfferMutation = () =>
  useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(
    UPDATE_OFFER_MUTATION
  )
