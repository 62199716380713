import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

import {
  SalesForPrintParticipantServiceBed,
  SalesPrintConfigKey as Key,
} from '../../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../../utils'
import { formatRoom, getFullName, getTargetName } from '../../../utils'
import { GuestDetails } from '../GuestDetails'
import { ParticipantsByTarget } from '../utils'
import { ParticipantWrapper, Placeholder, Section, Wrapper } from './common'

type Props = {
  data: ParticipantsByTarget
}

export const TargetRow = ({ data: { target, participants } }: Props) => {
  const { language } = useLanguageContext()
  const {
    salesPrintConfig: config,
    data: { groups },
  } = useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Participants}.${key}`)

  return (
    <Wrapper>
      <FlexRow>
        <Section style={{ fontWeight: 600, width: '8rem' }}>
          {!target ? (
            <Placeholder>
              <T>SalesDetails:PrintSale.noTarget</T>
            </Placeholder>
          ) : (
            getTargetName(target, language)
          )}
        </Section>

        <FlexColumn flex={1}>
          {participants.map((participant, idx) => {
            const {
              age,
              ageCategory,
              firstName,
              group,
              id,
              lastName,
              services,
            } = participant

            const isGroupsAvailable = !!groups?.values.length

            const [firstService, ...nextServices] = services.filter(
              (s) => s.__typename === 'ServiceParticipantBed'
            ) as SalesForPrintParticipantServiceBed[]

            const dates = firstService?.dates
            const room =
              firstService?.participantRoom?.roomReservation.request.room.number

            return (
              <FlexColumn key={id}>
                <ParticipantWrapper isFirst={idx === 0}>
                  {checkVisibility(Key.Name) && (
                    <Section style={{ fontWeight: 600, width: '12rem' }}>
                      {getFullName(firstName, lastName) || '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Age) && (
                    <Section flex={0.5}>
                      {age ?? ageCategory?.abbreviation ?? '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Dates) && (
                    <Section flex={1.5}>
                      {dates
                        ? formatDate(
                            dates.checkIn.date,
                            dates.checkOut.date,
                            'short'
                          )
                        : '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Room) && (
                    <Section flex={1.5}>
                      {room ? formatRoom(room) : '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Group) && isGroupsAvailable && (
                    <Section flex={1.5}>
                      <span style={{ whiteSpace: 'pre-wrap' }}>
                        {group || '–'}
                      </span>
                    </Section>
                  )}
                </ParticipantWrapper>

                {nextServices.map(({ dates, id, participantRoom }) => {
                  const room =
                    participantRoom?.roomReservation.request.room.number

                  return (
                    <FlexRow key={id} style={{ marginTop: '0.5rem' }}>
                      {checkVisibility(Key.Name) && (
                        <Section style={{ width: '12rem' }} />
                      )}

                      {checkVisibility(Key.Age) && <Section flex={0.5} />}

                      {checkVisibility(Key.Dates) && (
                        <Section flex={1.5}>
                          {dates
                            ? formatDate(
                                dates.checkIn.date,
                                dates.checkOut.date,
                                'short'
                              )
                            : '–'}
                        </Section>
                      )}

                      {checkVisibility(Key.Room) && (
                        <Section flex={1.5}>
                          {room ? formatRoom(room) : '–'}
                        </Section>
                      )}

                      {checkVisibility(Key.Group) && isGroupsAvailable && (
                        <Section flex={1.5} />
                      )}
                    </FlexRow>
                  )
                })}

                <GuestDetails data={participant} />
              </FlexColumn>
            )
          })}
        </FlexColumn>
      </FlexRow>
    </Wrapper>
  )
}
