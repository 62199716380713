import React from 'react'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import { withFilterSettings } from '@/components/ElasticFilterSearchList/enhancers'
import { FlexRow } from '@/components/Layout'
import { DataSearch, DateRange, MultiList } from '@/components/Reactivesearch'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { SearchListContext } from '@/modules/Sales'
import { FiltersSettings } from '@/modules/Sales/components/SalesSearchList/components/FiltersSettings'
import { useTheme } from '@/theme'

import { ChecksListFilter } from './ChecksListFilter'

export type FilterOption = {
  disabled: boolean
  selected: boolean
  value: string
}

type ListFiltersProps = {
  componentIds: Record<string, string>
  getDefaultQuery: () => Record<string, unknown>
  URLParams?: boolean
  filterNames: string[]
  searchContext: SearchListContext.INVOICE
  filters: FilterOption[]
  onFilterSettingsChange: () => void
}

const ListFilters = ({
  componentIds,
  getDefaultQuery,
  URLParams,
  filters,
  onFilterSettingsChange,
}: ListFiltersProps) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const renderFilter = (filter: string) => {
    switch (filter) {
      case 'bookkeepingTransferred':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={
              <T>InvoicesSearchList:ListHeader.filter.bookkeepingTransferred</T>
            }
            render={({ isCollapsed }) => (
              <DateRange
                componentId={'bookkeepingTransferred'}
                dataField="bookkeepingTransferredDate"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.bookkeepingTransferred',
                  language
                )}
                hideClearButton
                isCollapsed={isCollapsed}
                URLParams={!!URLParams}
              />
            )}
          />
        )
      case 'salesEndDate':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>InvoicesSearchList:ListHeader.filter.salesEndDate</T>}
            render={({ isCollapsed }) => (
              <DateRange
                componentId={componentIds.SALES_END_DATE}
                dataField="sales.searchDates.end"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.salesEndDate',
                  language
                )}
                hideClearButton
                isCollapsed={isCollapsed}
                URLParams={!!URLParams}
              />
            )}
          />
        )
      case 'saleState':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>InvoicesSearchList:ListHeader.filter.saleState</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.SALES_STATE}
                dataField="sales.state"
                defaultQuery={getDefaultQuery}
                defaultValue={['OPEN', 'CONFIRMED', 'CLOSED']}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.saleState',
                  language
                )}
                l10nPrefix="SalesDetails:Lifecycle.state"
                isCollapsed={isCollapsed}
                showCheckbox
                showMissing
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'salesStartDate':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>InvoicesSearchList:ListHeader.filter.salesStartDate</T>}
            render={({ isCollapsed }) => (
              <DateRange
                componentId={componentIds.SALES_START_DATE}
                dataField="sales.searchDates.start"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.salesStartDate',
                  language
                )}
                hideClearButton
                isCollapsed={isCollapsed}
                URLParams={!!URLParams}
              />
            )}
          />
        )
      case 'invoiceDate':
        return (
          <FilterSection
            canCollapse
            isCollapsedInitially
            label={<T>InvoicesSearchList:ListHeader.filter.invoiceDate</T>}
            render={({ isCollapsed }) => (
              <DateRange
                componentId={componentIds.INVOICE_DATE}
                dataField="invoiceDate"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.invoiceDate',
                  language
                )}
                hideClearButton
                isCollapsed={isCollapsed}
                URLParams={!!URLParams}
              />
            )}
          />
        )
      case 'type':
        return (
          <FilterSection
            canCollapse
            label={<T>InvoicesSearchList:ListHeader.filter.type</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.INVOICE_TYPE}
                dataField="type"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.type',
                  language
                )}
                l10nPrefix="Orders:Header.title"
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'paymentStatus':
        return (
          <FilterSection
            canCollapse
            label={<T>InvoicesSearchList:ListHeader.filter.paymentStatus</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.STATUS}
                dataField="paymentStatus"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.paymentStatus',
                  language
                )}
                l10nPrefix="Orders:PaymentStatus"
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'invoiceState':
        return (
          <FilterSection
            canCollapse
            label={<T>InvoicesSearchList:ListHeader.filter.invoiceState</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.STATE}
                dataField="state"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.invoiceState',
                  language
                )}
                l10nPrefix="Orders:InvoiceState"
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'paymentType':
        return (
          <FilterSection
            canCollapse
            label={<T>InvoicesSearchList:ListHeader.filter.paymentType</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.PAYMENT_TYPE}
                dataField="paymentType"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.paymentType',
                  language
                )}
                l10nPrefix="Orders:PaymentType"
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />
        )
      case 'checks':
        return (
          <FilterSection
            canCollapse
            label={<T>InvoicesSearchList:ListHeader.filter.checks</T>}
            render={({ isCollapsed }) => (
              <ChecksListFilter
                componentId={componentIds.CHECKS}
                getDefaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'InvoicesSearchList:ListHeader.filter.checks',
                  language
                )}
                l10nPrefix="InvoicesSearchList:ListHeader.checksOptions"
                isCollapsed={isCollapsed}
                showFilter
                URLParams
              />
            )}
          />
        )
      case 'saleType':
        return (
          <FilterSection
            canCollapse
            label={<T>SalesSearchList:ListHeader.filter.type</T>}
            render={({ isCollapsed }) => (
              <MultiList
                componentId={componentIds.SALE_TYPE}
                dataField="sales.facet.name"
                defaultQuery={getDefaultQuery}
                filterLabel={translate(
                  'SalesSearchList:ListHeader.type',
                  language
                )}
                isCollapsed={isCollapsed}
                showCheckbox
                showSearch={false}
                URLParams
              />
            )}
          />
        )
    }
  }

  return (
    <>
      <FlexRow justifyContent="space-between" alignItems="center">
        <FontWeight bold style={{ color: theme.palette.text.light }}>
          <T>InvoicesSearchList:ListControls.search</T>
        </FontWeight>

        <FiltersSettings
          filters={filters}
          nameSpace="InvoicesSearchList"
          onOptionClick={onFilterSettingsChange}
        />
      </FlexRow>
      <FilterSection
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH}
            dataField={['invoiceNumber.text', 'referenceNumber.text']}
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'InvoicesSearchList:ListControls.search',
              language
            )}
            placeholder={translate(
              `InvoicesSearchList:ListControls.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            showClear
            showIcon
            URLParams
          />
        )}
      />
      <FilterSection
        label={<T>SalesSearchList:ListControls.searchSales</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH_SALES}
            dataField={[
              'sales.customerName',
              'sales.name',
              'sales.number.text',
              'rootEvent.name',
              'rootEvent.number.text',
            ]}
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              `SalesSearchList:ListControls.search`,
              language
            )}
            placeholder={translate(
              `SalesSearchList:ListControls.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            showClear
            showIcon
            URLParams
          />
        )}
      />
      {filters
        .filter(({ selected }) => selected)
        .map((filter) => (
          <React.Fragment key={filter.value}>
            {renderFilter(filter.value)}
          </React.Fragment>
        ))}
    </>
  )
}

export default withFilterSettings(ListFilters)
