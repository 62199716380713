import { CSSProperties, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { Spacing, useTheme } from '@/theme'

import { PaymentAgreementsQuery } from '~generated-types'

import { useSalesDetailsContext } from '../../../'
import { PaymentAgreementsQuery as Query } from './PaymentAgreements.query'

type PaymentAgreementType = PaymentAgreementsQuery['salesPaymentAgreements'][0]

export const PaymentAgreement = () => {
  const { spacing } = useTheme()
  const {
    data: { id, paymentAgreement },
    saleReadOnly: readOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const { data, loading } = useQuery<PaymentAgreementsQuery>(Query, {
    fetchPolicy: 'cache-first',
    skip: !isEditMode,
  })

  const clearOption = {
    label: (
      <ClearSelectionLabel>
        <T>Reactivesearch:clearSelection</T>
      </ClearSelectionLabel>
    ),
    value: '',
  }

  const paymentAgreementOptions =
    data?.salesPaymentAgreements.map((agreement) => ({
      label: getPaymentAgreementLabel(agreement),
      searchValue: `${agreement.name} ${agreement.code}`,
      value: agreement.id,
    })) || []

  const options = paymentAgreement
    ? [...paymentAgreementOptions, clearOption]
    : paymentAgreementOptions

  const handleUpdatePaymentAgreement = (paymentAgreementId: string) => {
    if (paymentAgreementId === paymentAgreement?.id) {
      return
    }

    setProcessing(true)

    updateAttributes(id, { paymentAgreementId })
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        setEditMode(false)
      })
  }

  return (
    <Wrapper flex={1} noPadding>
      <Label>
        <T>SalesDetails:Settings.paymentAgreement.title</T>
      </Label>

      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={processing || loading}
            isDisabled={readOnly}
            menuIsOpen={isEditMode}
            name="payment-agreement-selector"
            noOptionsMessage={() => (
              <T>SalesDetails:Settings.paymentAgreement.empty</T>
            )}
            onChange={(option?: Option | null) =>
              option && handleUpdatePaymentAgreement(option.value)
            }
            onBlur={() => setEditMode(false)}
            options={options}
            placeholder="—"
            value={
              paymentAgreement
                ? {
                    label: getPaymentAgreementLabel(paymentAgreement),
                    value: paymentAgreement.id,
                  }
                : null
            }
          />
        ) : (
          <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
            {paymentAgreement
              ? getPaymentAgreementLabel(paymentAgreement)
              : '—'}
          </EditButton>
        )}
      </FlexRow>
    </Wrapper>
  )
}

////////

const getPaymentAgreementLabel = (paymentAgreement: PaymentAgreementType) =>
  `${paymentAgreement.code} – ${paymentAgreement.name}`

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const ClearSelectionLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
