import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T, useLanguageContext } from '@/modules/Language'
import { UserGroup } from '@/modules/Registry'
import {
  Reservation,
  resourceReservationHooks,
  ResourceReservationSales,
  ResourceReservationTask,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import { UseResourceReservationQuery } from '~generated-types'

import { Context, Section } from '../../types'
import { EditButton, IconButton, LoaderWrapper } from '../common'
import { TaskPurchase } from './TaskPurchase'

type Props = {
  context: Context
  readOnly: boolean
  refetchReservation: () => Promise<
    ApolloQueryResult<UseResourceReservationQuery>
  >
  reservationId: string
  sales: ResourceReservationSales
  setCurrentSection: (section: Section) => void
  task: ResourceReservationTask
  updateReservations: (reservations: Reservation[]) => void
}

export const Task = ({
  context,
  readOnly,
  refetchReservation,
  reservationId,
  sales,
  setCurrentSection,
  task: { assignee, dueDate, dueTime, id, isOpen, purchaseProducts },
  updateReservations,
}: Props) => {
  const { confirm } = useDialogService()
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const { removeTask } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isRemoving, setRemoving] = useState(false)

  const handleRemoveTask = () => {
    setRemoving(true)

    const onDelete = () =>
      removeTask(id, reservationId)
        .catch(() => undefined)
        .finally(() => setRemoving(false))

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>ReservationModal:removalConfirmation.task</T>,
    })
      .then(onDelete)
      .catch(() => setRemoving(false))
  }

  const toDate = (date: string, time: string) => new Date(`${date}T${time}`)

  return (
    <>
      <InlineModalLine />

      <InlineModalIconSection icon={['far', 'square-check']}>
        <FlexRow>
          <EditButton
            onClick={() =>
              setCurrentSection({ taskId: id, type: 'TASK_MANAGER' })
            }
          >
            <FlexColumn noPadding style={{ width: '100%' }}>
              <TaskDetailsWrapper justifyContent="space-between">
                {assignee && (
                  <AssigneeName>{(assignee as UserGroup).name}</AssigneeName>
                )}

                {dueDate && dueTime && (
                  <TaskDetails>
                    <FontAwesomeIcon
                      fixedWidth
                      color={palette.text.lighter}
                      icon={['far', 'calendar']}
                      size="sm"
                      style={{ marginRight: spacing.gutterSmall }}
                    />
                    {moment(toDate(dueDate, dueTime))
                      .locale(language)
                      .format('D.M.YY')}
                    <FontAwesomeIcon
                      fixedWidth
                      color={palette.text.lighter}
                      icon={['far', 'clock']}
                      size="sm"
                      style={{
                        marginLeft: `${spacing.gu(1)}rem`,
                        marginRight: spacing.gutterSmall,
                      }}
                    />
                    {moment(toDate(dueDate, dueTime)).format('HH:mm')}
                  </TaskDetails>
                )}

                <Spacer />

                <FontAwesomeIcon
                  fixedWidth
                  color={
                    isOpen ? palette.smoke.extraDark : palette.success.main
                  }
                  icon={isOpen ? ['far', 'hourglass'] : 'check'}
                  style={{ marginLeft: `${spacing.gu(1)}rem` }}
                  size="sm"
                />
              </TaskDetailsWrapper>
            </FlexColumn>
          </EditButton>

          {!readOnly && (
            <Tooltip
              content={<T>ReservationModal:action.addProduct</T>}
              delay={300}
              placement="left"
              trigger={(triggerProps) => (
                <IconButton
                  {...triggerProps}
                  style={{
                    justifyContent: 'space-between',
                    width: `${spacing.gu(5.5)}rem`,
                  }}
                  onClick={() =>
                    setCurrentSection({
                      taskId: id,
                      type: 'TASK_PURCHASE_SEARCH',
                    })
                  }
                >
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="plus"
                    size="xs"
                  />
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="cube"
                    size="sm"
                  />
                </IconButton>
              )}
            />
          )}

          {!readOnly &&
            (isRemoving ? (
              <LoaderWrapper>
                <ReactLoading
                  color={palette.smoke.dark}
                  height={18}
                  type="spin"
                  width={18}
                />
              </LoaderWrapper>
            ) : (
              <Tooltip
                content={<T>Tasks:TaskActions.removeTask</T>}
                delay={300}
                placement="left"
                trigger={(triggerProps) => (
                  <IconButton {...triggerProps} onClick={handleRemoveTask}>
                    <FontAwesomeIcon
                      color={palette.danger.main}
                      icon="trash"
                      size="sm"
                    />
                  </IconButton>
                )}
              />
            ))}
        </FlexRow>
      </InlineModalIconSection>

      {!!purchaseProducts.length &&
        [...purchaseProducts]
          .sort(
            (a, b) =>
              moment(a.auditLog.createdAt).valueOf() -
              moment(b.auditLog.createdAt).valueOf()
          )
          .map((purchase, index: number) => (
            <TaskPurchase
              context={context}
              isLast={purchaseProducts.length - 1 === index}
              key={purchase.id}
              purchase={purchase}
              readOnly={readOnly}
              refetchReservation={refetchReservation}
              reservationId={reservationId}
              sales={sales}
              taskId={id}
              updateReservations={updateReservations}
            />
          ))}
    </>
  )
}

////////////

const AssigneeName = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const TaskDetails = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const TaskDetailsWrapper = styled(FlexRow)`
  width: 100%;
`
