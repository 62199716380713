import { useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ActionDropdown, ActionDropdownItem } from '@/components/ActionDropdown'
import { IconButton } from '@/components/ExtraButtons'
import {
  InputCell,
  RadioButtonCell,
  SpreadsheetTD,
  SpreadsheetTR,
} from '@/components/Spreadsheet'
import { Tooltip } from '@/components/Tooltip'
import { T } from '@/modules/Language'
import { Customer, CustomerAddress } from '@/modules/Registry/Customer'
import { useTheme } from '@/theme'

type Props = {
  data: CustomerAddress
  focusedColumn: null | number
  isDefault: boolean
  isSelected: boolean
  onPressKey: (cellIdx: number, key: string) => void
  onRemove: () => Promise<string | undefined>
  onSelect?: (id: string) => void
  onSetAsDefault: () => Promise<Partial<Customer> | undefined>
  onUpdate: (
    attribute: string,
    value: string
  ) => Promise<CustomerAddress | undefined>
}

export const AddressRow = ({
  data: { id, label, postalAddress },
  focusedColumn,
  isDefault,
  isSelected,
  onPressKey,
  onRemove,
  onSelect,
  onSetAsDefault,
  onUpdate,
}: Props) => {
  const { palette } = useTheme()

  const menuRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (focusedColumn === 6) {
      menuRef && menuRef.current && menuRef.current.focus()
    }
  }, [focusedColumn])

  const getBaseProps = (columnIdx: number) => ({
    focus: focusedColumn === columnIdx,
    onPressKey: (key: string) => onPressKey(columnIdx, key),
    placeholder: '—',
  })

  const handlePropChangeFn = (property: string) => (value: string) =>
    onUpdate(property, value)

  const actions: ActionDropdownItem[] = isDefault
    ? [
        {
          icon: 'trash',
          label: <T>Customers:addresses.remove</T>,
          onClick: onRemove,
        },
      ]
    : [
        {
          icon: 'bookmark',
          label: <T>Customers:addresses.setAsDefault</T>,
          onClick: onSetAsDefault,
        },
        {
          icon: 'trash',
          label: <T>Customers:addresses.remove</T>,
          onClick: onRemove,
        },
      ]

  return (
    <SpreadsheetTR>
      {!!onSelect && (
        <RadioButtonCell checked={isSelected} onChange={onSelect} value={id} />
      )}
      <InputCell
        {...getBaseProps(0)}
        endAdornment={
          isDefault ? (
            <Tooltip
              content={<T>Customers:addresses.default</T>}
              delay={300}
              placement="top"
              trigger={(triggerProps) => (
                <span {...triggerProps}>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="bookmark"
                  />
                </span>
              )}
            />
          ) : null
        }
        onSubmit={handlePropChangeFn('label')}
        type="text"
        value={label}
      />
      <InputCell
        {...getBaseProps(1)}
        onSubmit={handlePropChangeFn('address1')}
        type="text"
        value={postalAddress?.address1 ?? ''}
      />
      <InputCell
        {...getBaseProps(2)}
        onSubmit={handlePropChangeFn('address2')}
        type="text"
        value={postalAddress?.address2 ?? ''}
      />
      <InputCell
        {...getBaseProps(3)}
        onSubmit={handlePropChangeFn('postcode')}
        type="text"
        value={postalAddress?.postcode ?? ''}
      />
      <InputCell
        {...getBaseProps(4)}
        onSubmit={handlePropChangeFn('city')}
        type="text"
        value={postalAddress?.city ?? ''}
      />
      <InputCell
        {...getBaseProps(5)}
        onSubmit={handlePropChangeFn('country')}
        type="text"
        value={postalAddress?.country ?? ''}
      />
      <SpreadsheetTD align="right">
        <ActionDropdown
          items={actions}
          renderToggle={({ toggleDropdown }) => (
            <IconButton
              icon="ellipsis-vertical"
              innerRef={menuRef}
              onClick={toggleDropdown}
              onKeyDown={({ key }) => onPressKey(6, key)}
              variant="borderless"
            />
          )}
        />
      </SpreadsheetTD>
    </SpreadsheetTR>
  )
}
