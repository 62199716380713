import { FC, ReactElement, useState } from 'react'

import { FilterSection } from '@/components/ElasticFilterSearchList'
import {
  DimensionSelector,
  ElasticBaseUrl,
  ReactiveBase,
  ReactiveList,
} from '@/components/Reactivesearch'
import { T } from '@/modules/Language'

import { DimensionTarget } from '~generated-types'

interface Props {
  onChangeMatchingSales: (salesIds: string[]) => void
}

export const DimensionFilter: FC<Props> = ({
  onChangeMatchingSales,
}): ReactElement => {
  const [currentMatch, setCurrentMatch] = useState<string[]>([])

  return (
    <ReactiveBase app="sales.sales" url={ElasticBaseUrl}>
      <FilterSection
        forceBottomSpacing
        label={<T>FrontDesk:DailyCustomerList.dimensions.title</T>}
        render={({ isCollapsed }) => (
          <>
            <DimensionSelector
              componentId="dimension"
              defaultQuery={() => ({})}
              dimensionTarget={DimensionTarget.FrontDeskSearch}
              // Hide counts as they wont be correct without a date in the query
              hideCounts
              hideEmpty={false}
              isCollapsed={isCollapsed}
              URLParams
            />
            <ReactiveList
              componentId="dimension_results"
              dataField={'id'}
              includeFields={['id', 'sales.id']}
              // prevents search without a search value
              onData={({ data }: AnyObject) => {
                const onlyUnique = (value: string, index: number, self: any) =>
                  self.indexOf(value) === index

                const salesIds: string[] = []
                const dataArr = (data as AnyObject[]) || []

                dataArr.forEach((x) => {
                  if (typeof x?.id === 'string') {
                    salesIds.push(x.id)
                  }
                })

                const sortedUniqueIds = salesIds.filter(onlyUnique).sort()

                if (
                  JSON.stringify(sortedUniqueIds) !==
                  JSON.stringify(currentMatch)
                ) {
                  setCurrentMatch(sortedUniqueIds)
                  onChangeMatchingSales(sortedUniqueIds)
                }
              }}
              onQueryChange={function (prevQuery: any, nextQuery: any) {
                if ('match_all' in nextQuery['query']) {
                  nextQuery['query'] = { match_none: {} }
                }
              }}
              pagination={false}
              react={{
                and: ['dimension'],
              }}
              render={() => null}
              renderError={() => null}
              renderNoResults={() => null}
              showLoader={false}
              showResultStats={false}
              size={10000}
            />
          </>
        )}
      />
    </ReactiveBase>
  )
}
