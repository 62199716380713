import { CSSProperties, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { dimensionQueries } from '@/modules/Dimensions'
import { T } from '@/modules/Language'
import { Spacing, useTheme } from '@/theme'

import type {
  SalesFacetsQuery as SalesFacets,
  SalesFacetsQueryVariables as SalesFacetsVariables,
} from '~generated-types'

import { useSalesDetailsContext } from '../../../'
import { SalesFacetsQuery } from './SalesFacets.query'
import { SetSalesFacetMutation } from './SetSalesFacet.mutation'

export const Facet = () => {
  const history = useHistory()
  const { spacing } = useTheme()

  const {
    data: { facet: currentFacet, id, type },
    refresh,
    saleReadOnly: readOnly,
  } = useSalesDetailsContext()

  const [facet, setFacet] = useState(currentFacet)
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState(false)

  const { data, loading } = useQuery<SalesFacets, SalesFacetsVariables>(
    SalesFacetsQuery,
    {
      fetchPolicy: 'cache-first',
      skip: !isEditMode,
      variables: { forCreate: false },
    }
  )

  const [handleSetFacet] = useMutation(SetSalesFacetMutation, {
    refetchQueries: [
      {
        query: dimensionQueries.GET_SALES_DIMENSIONS,
        variables: { salesId: id },
      },
    ],
  })

  const facets =
    data?.registry.salesFacets
      .filter(({ salesType }) => salesType === type)
      .map((facet) => ({
        label: facet.name,
        searchValue: `${facet.name} ${facet.abbreviation}`,
        value: facet.id,
      })) || []

  const handleSelectFacet = (option?: Option | null) => {
    setProcessing(true)

    option &&
      handleSetFacet({
        variables: {
          input: {
            facetId: option.value,
            salesId: id,
          },
        },
      })
        .then(({ data }) => data && setFacet(data.salesUpdateFacet.facet))
        .then(() => refresh())
        .then(() => {
          setProcessing(false)
          setEditMode(false)
        })
        .then(() => history.push(`/sales/details/${id}`))
  }

  return (
    <Wrapper flex={1} noPadding>
      <Label>
        <T>SalesDetails:Settings.facet.title</T>
      </Label>
      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={processing || loading}
            isDisabled={readOnly}
            isSearchable
            menuIsOpen={isEditMode}
            name="facet-selector"
            noOptionsMessage={() => <T>SalesDetails:Settings.facet.empty</T>}
            onBlur={() => setEditMode(false)}
            onChange={handleSelectFacet}
            options={facets}
            placeholder="—"
            value={{ label: facet.name, value: facet.id }}
          />
        ) : (
          <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
            {facet.name}
          </EditButton>
        )}
      </FlexRow>
    </Wrapper>
  )
}

/////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
