import { useState } from 'react'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { useTaskMutations } from '../../../hooks'
import { TargetTask } from '../TaskSearchList'

type Props = {
  refreshList: () => void
  setTargetTask: (task: TargetTask | null) => void
}

const ListControls = ({ refreshList, setTargetTask }: Props) => {
  const theme = useTheme()

  const [processing, setProcessing] = useState<boolean>(false)

  const { createTask } = useTaskMutations({
    onUpdateTasks: () => null,
  })

  const handleCreatetask = () => {
    setProcessing(true)
    createTask()
      .then((task) => {
        refreshList()
        setTargetTask(
          task ? { number: task.number, readOnly: task.sales.locked } : null
        )
      })
      .finally(() => setProcessing(false))
  }

  return (
    <FlexRow flex={1} style={{ marginBottom: theme.spacing.gutterSmall }}>
      <InnocuousButton compact disabled={processing} onClick={handleCreatetask}>
        <PrimaryColor>
          + <T>Tasks:ListControls.create</T>
        </PrimaryColor>
      </InnocuousButton>
    </FlexRow>
  )
}

export default ListControls
