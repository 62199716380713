import { FlexColumn } from '@/components/Layout'
import {
  ListItemSection as Wrapper,
  ListItemSectionIconValue as IconValue,
} from '@/components/List'
import { Tooltip } from '@/components/Tooltip'
import { ElasticCustomer } from '@/modules/Registry'

type Props = {
  data: ElasticCustomer
}

const ContactInfo = ({ data }: Props) => {
  switch (data.customerType) {
    case 'ORGANIZATION':
      return (
        <Wrapper>
          {data.contacts.length && (
            <FlexColumn noPadding>
              {data.contacts.map(
                ({ email, firstName, id, lastName, phone }) => (
                  <IconValue
                    icon="user"
                    key={id}
                    value={
                      <Tooltip
                        content={
                          <FlexColumn style={{ paddingBottom: 0 }}>
                            {email && <IconValue icon="at" value={email} />}
                            {phone && <IconValue icon="phone" value={phone} />}
                          </FlexColumn>
                        }
                        delay={300}
                        placement="right"
                        trigger={(triggerProps) => (
                          <span {...triggerProps}>
                            {lastName}, {firstName}
                          </span>
                        )}
                      />
                    }
                  />
                )
              )}
            </FlexColumn>
          )}
        </Wrapper>
      )
    case 'PERSON':
      return (
        <Wrapper>
          <FlexColumn noPadding>
            {data.person.email && (
              <IconValue icon="at" value={data.person.email} />
            )}
            {data.person.phone && (
              <IconValue icon="phone" value={data.person.phone} />
            )}
          </FlexColumn>
        </Wrapper>
      )
    default:
      console.warn(`Invalid customer type`)
      return <Wrapper>error</Wrapper>
  }
}

export default ContactInfo
