import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'

import {
  EventEnrollment,
  EventEnrollmentsMutations,
  EventEnrollmentsQueries,
} from '@/API/EventEnrollments'
import { FetchState, FetchStates } from '@/common/types'
import { generateCompareFn } from '@/utils/arrays'

import { SalesState } from '~generated-types'

export type Pagination = {
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  totalElements: number
  totalPages: number
}

type EnrollmentsContextType = {
  // Data
  activeStates: SalesState[]
  enrollments: EventEnrollment[]
  pageSize: number
  pagination: Pagination
  fetchState: FetchState
  inEnrollmentsContext: boolean

  // Methods
  goToPage: (
    page: number,
    pageSize: number,
    enrollmentStates?: SalesState[]
  ) => void
  handleSetActiveStates: (states: SalesState[]) => void
  addEnrollment: () => Promise<string>
  refresh: () => Promise<any>
  setPageSize: (pageSize: number) => void
}

const EnrollmentsContext = createContext<EnrollmentsContextType>({
  activeStates: [SalesState.Open, SalesState.Confirmed, SalesState.Closed],
  addEnrollment: Promise.reject,
  enrollments: [],
  fetchState: FetchStates.ERROR,
  goToPage: () => undefined,
  handleSetActiveStates: () => undefined,
  inEnrollmentsContext: false,
  pageSize: 5,
  pagination: {
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  },
  refresh: Promise.reject,
  setPageSize: () => undefined,
})

type ProviderProps = {
  children: ReactNode
  eventId: string
}

export const EnrollmentsContextProvider = ({
  children,
  eventId,
}: ProviderProps) => {
  const contextValueRef = useRef<EnrollmentsContextType | null | undefined>(
    null
  )

  const [fetchState, setFetchState] = useState<FetchState>(FetchStates.LOADING)
  const [enrollments, setEnrollments] = useState<EventEnrollment[]>([])
  const [pageSize, setPageSize] = useState<number>(20)
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    totalElements: 0,
    totalPages: 0,
  })
  const [activeStates, setActiveStates] = useState<SalesState[]>([
    SalesState.Open,
    SalesState.Confirmed,
    SalesState.Closed,
  ])

  const [createEnrollment] = useMutation(
    EventEnrollmentsMutations.CREATE_ENROLLMENT
  )

  const [loadEnrollments, { data, error, loading, refetch }] = useLazyQuery(
    EventEnrollmentsQueries.ENROLLMENTS,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    goToPage(0, pageSize)
  }, [])

  useEffect(() => {
    data?.sales?.enrollments &&
      setEnrollments(
        [...data.sales.enrollments.nodes]
          .sort(generateCompareFn('orderNumber'))
          .map((enrollment: EventEnrollment) => ({
            ...enrollment,
            eventId: data.sales.id,
          }))
      )

    if (data?.sales?.enrollments) {
      const { totalPages, totalElements, hasNextPage, hasPreviousPage } =
        data.sales.enrollments

      setPagination((pagination) => ({
        currentPage: pagination.currentPage,
        hasNextPage,
        hasPreviousPage,
        totalElements,
        totalPages,
      }))
    }

    data?.sales && setFetchState(FetchStates.IDLE)
    error && setFetchState(FetchStates.ERROR)
  }, [data, error, loading, pageSize])

  // @ts-ignore
  const refresh = () => refetch()

  const handleSetActiveStates = (states: SalesState[]) => {
    setActiveStates(states)
    goToPage(0, pageSize, states)
  }

  const goToPage = (
    page: number,
    pageSize: number,
    enrollmentStates?: SalesState[]
  ) => {
    loadEnrollments({
      variables: {
        id: eventId,
        input: {
          enrollmentStates: enrollmentStates || activeStates,
          page: {
            page,
            size: pageSize,
          },
        },
      },
    })
    setPagination((pagination) => ({
      ...pagination,
      currentPage: page,
    }))
  }

  const addEnrollment = () =>
    createEnrollment({
      variables: {
        enrollmentsInput: {
          enrollmentStates: activeStates,
          page: {
            page: 0,
            size: pageSize,
          },
        },
        input: {
          eventId,
          name: '',
          source: 'ADMIN',
        },
      },
    })
      .then(({ data }) => {
        data &&
          setEnrollments((current) => [...current, data.salesCreateEnrollment])

        const lastPage =
          (data?.salesCreateEnrollment.event.enrollments.totalPages || 1) - 1

        pagination.currentPage !== lastPage && goToPage(lastPage, pageSize)

        setPagination((pagination) => ({
          ...pagination,
          currentPage: lastPage,
          totalPages: data?.salesCreateEnrollment.event.enrollments.totalPages,
        }))

        return data.salesCreateEnrollment.id
      })
      .catch(() => undefined)

  contextValueRef.current = {
    activeStates,
    addEnrollment,
    enrollments,
    fetchState,
    goToPage,
    handleSetActiveStates,
    inEnrollmentsContext: true,
    pageSize,
    pagination,
    refresh,
    setPageSize,
  }

  return (
    <EnrollmentsContext.Provider value={contextValueRef.current}>
      {children}
    </EnrollmentsContext.Provider>
  )
}

export const useEnrollmentsContext = () => useContext(EnrollmentsContext)
