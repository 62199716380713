import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  TriggerConfirmationActionMutation,
  TriggerConfirmationActionMutationVariables,
} from '~generated-types'

const TRIGGER_CONFIRMATION_ACTION_MUTATION = gql`
  ${orderFragments.confirmation}

  mutation TriggerConfirmationAction($input: OrderConfirmationActionInput!) {
    orderConfirmationAction(input: $input) {
      confirmation {
        ...Confirmation
      }
    }
  }
`

export const useTriggerConfirmationActionMutation = () =>
  useMutation<
    TriggerConfirmationActionMutation,
    TriggerConfirmationActionMutationVariables
  >(TRIGGER_CONFIRMATION_ACTION_MUTATION)
