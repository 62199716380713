import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBasketContext } from '@/components/Basket'
import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModal,
  InlineModalContent,
  InlineModalSection,
} from '@/components/InlineModal'
import { List, ListContent } from '@/components/List'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import Header from './Header'
import { SaleCard } from './SaleCard'

type Props = {
  closeSelector: () => void
}

const LastUsedModal = ({ closeSelector }: Props) => {
  const { sales } = useBasketContext()
  const history = useHistory()
  const [showAll, setShowAll] = useState<boolean>(false)
  const theme = useTheme()

  const handleClick = (saleId: string) => {
    history.push(`/sales/details/${saleId}`)
    closeSelector()
  }

  return (
    <InlineModal
      style={{ minWidth: '300px', paddingBottom: `${theme.spacing.gu(1)}rem` }}
    >
      <InlineModalContent>
        <InlineModalSection>
          <List>
            <Header />
            <ListContent>
              {sales.slice(0, showAll ? 10 : 5).map((saleId) => (
                <SaleCard
                  id={saleId}
                  key={saleId}
                  onClick={() => handleClick(saleId)}
                />
              ))}
            </ListContent>
          </List>
        </InlineModalSection>
        <InlineModalSection style={{ justifyContent: 'center', marginTop: 0 }}>
          {!showAll && sales.length > 5 && (
            <InnocuousButton onClick={() => setShowAll(true)}>
              <PrimaryColor>
                <T>Sales:showAll</T>
              </PrimaryColor>
            </InnocuousButton>
          )}
        </InlineModalSection>
      </InlineModalContent>
    </InlineModal>
  )
}

export default LastUsedModal
