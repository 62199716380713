import { useMemo } from 'react'
import moment from 'moment'

import { FlexColumn } from '@/components/Layout'
import { Meal, MealRow } from '@/modules/Meals'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { SalesType, ScheduledMealStatus as Status } from '~generated-types'

import { MealsContent, MealsHeader } from './components'
import { useMealsContext } from './MealsState'
import { MealRowsByDate } from './types'

export const MealsList = () => {
  const { data } = useSalesDetailsContext()
  const { isEditMode, mealsByDate } = useMealsContext()

  const isEvent = data.type === SalesType.Event

  const mealRowsByDate = useMemo(
    () =>
      mealsByDate.map(({ date, meals }) => {
        const newMeals = meals
          .filter(({ status }) => !isEvent || status !== Status.ManuallyRemoved)
          .reduce((acc: MealRow[], meal, idx) => {
            let isNewMeal = true

            const newAcc = acc.map((accMeal) => {
              if (isSameMeals(accMeal, meal) && isEvent) {
                isNewMeal = false

                return {
                  ...accMeal,
                  ids: [...accMeal.ids, meal.id],
                  quantities: accMeal.quantities.map((q) => {
                    const mealQuantity = meal.quantities.find(
                      ({ ageCategory }) =>
                        ageCategory?.key === q.ageCategory?.key
                    )
                    return {
                      ...q,
                      quantity: q.quantity + (mealQuantity?.quantity || 0),
                    }
                  }),
                  statuses: [...new Set([...accMeal.statuses, meal.status])],
                }
              }
              return accMeal
            })

            if (isNewMeal) {
              const { id, status, ...restMeal } = meal

              return [
                ...acc,
                {
                  ...restMeal,
                  ids: [id],
                  rowId: generateRowId(meal, date, idx),
                  statuses: [status],
                },
              ]
            }

            return newAcc
          }, [])
          .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())

        return {
          date,
          meals: newMeals,
        }
      }),
    [mealsByDate, isEvent]
  )

  const firstRowIndex = mealRowsByDate.findIndex(
    (day) => !isRemovedDay(day) || (isRemovedDay(day) && isEditMode)
  )

  return (
    <>
      {mealRowsByDate.map(
        (day, idx) =>
          (!isRemovedDay(day) || (isRemovedDay(day) && isEditMode)) && (
            <FlexColumn key={day.date}>
              <MealsHeader date={day.date} isFirst={idx === firstRowIndex} />
              <MealsContent day={day} />
            </FlexColumn>
          )
      )}
    </>
  )
}

///////////

const generateRowId = (
  { duration, location, meal, start }: Meal,
  date: string,
  idx: number
) => `mealRow-${date}-${start}-${meal.id}-${duration}-${location?.id}-${idx}`

const isRemovedDay = (day: MealRowsByDate) =>
  !day.meals.filter((meal) => !meal.statuses.includes(Status.ManuallyRemoved))
    .length

const isSameMeals = (m1: MealRow, m2: Meal) =>
  m1.start === m2.start &&
  m1.duration === m2.duration &&
  m1.location.id === m2.location.id &&
  m1.meal.id === m2.meal.id
