import React, { useMemo } from 'react'

import { FilterSearchList } from '@/components/FilterSearchList'
import {
  ListHeader,
  ListHeaderSection,
  ListItem,
  ListItemSection,
} from '@/components/List'
import { T } from '@/modules/Language'
import { RoomType, roomTypeHooks } from '@/modules/Registry'
import { generateCompareFn } from '@/utils/arrays'

export const RoomTypeList = () => {
  const { error, loading, roomTypes } = roomTypeHooks.useRoomTypes()

  const sorted = useMemo(
    () => [...roomTypes].sort(generateCompareFn('name')),
    [roomTypes]
  )

  return (
    <FilterSearchList
      allData={sorted}
      columnCount={4}
      error={error}
      loading={loading}
      renderListItem={(x: RoomType) => (
        <ListItem align="center" key={x.id}>
          <ListItemSection>{x.id}</ListItemSection>
          <ListItemSection>{x.accommodationLevel.name}</ListItemSection>
          <ListItemSection>{x.name}</ListItemSection>
          <ListItemSection>{x.rooms.length}</ListItemSection>
        </ListItem>
      )}
      renderListHeader={() => (
        <ListHeader>
          <ListHeaderSection>
            <T>RoomType:field.id</T>
          </ListHeaderSection>
          <ListHeaderSection>
            <T>RoomType:field.accommodationLevel</T>
          </ListHeaderSection>
          <ListHeaderSection>
            <T>RoomType:field.name</T>
          </ListHeaderSection>
          <ListHeaderSection>
            <T>RoomType:field.rooms</T>
          </ListHeaderSection>
        </ListHeader>
      )}
      searchFilter={(searchValue) =>
        ({ name }) =>
          name.toLowerCase().includes(searchValue.toLowerCase().trim())}
    />
  )
}
