import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import { orderServices } from '@/modules/Order'
import {
  Action,
  ActionButton,
  SectionSeparator,
} from '@/modules/Order/components'
import { Offer } from '@/modules/Order/types'

import { DocumentFileStatus, OfferAction as OA } from '~generated-types'

type Props = {
  offer: Offer
  orderId: string
  openDocumentModal: () => void
}

export const ActionsSection = ({
  offer: {
    document: offerDocument,
    id,
    lifecycle: { validatedActions },
    type,
  },
  orderId,
  openDocumentModal,
}: Props) => {
  const { removeOffer, triggerOfferAction } = orderServices.offerService()

  const [processingTarget, setProcessingTarget] = useState<OA | null>(null)

  const actions = validatedActions.filter(
    (a) =>
      a.action === OA.Accept ||
      a.action === OA.MarkWaitForApproval ||
      a.action === OA.RejectOfferIterate ||
      a.action === OA.RollBackLatest
  )

  const deleteAction = validatedActions.find((a) => a.action === OA.Delete)

  const handleDeleteOffer = () => {
    setProcessingTarget(OA.Delete)
    removeOffer(id, orderId).then(
      (isRemoved) => !isRemoved && setProcessingTarget(null)
    )
  }

  const handleTriggerOfferAction = (action: OA) => {
    setProcessingTarget(action)
    triggerOfferAction(action, id, orderId).finally(() =>
      setProcessingTarget(null)
    )
  }

  return (
    <Wrapper>
      {!!actions.length && (
        <>
          {actions.map((a) => (
            <Gutter key={a.action} type={[0, 1.5, 0, 0]}>
              <Action
                isProcessing={processingTarget === a.action}
                onClick={() => handleTriggerOfferAction(a.action)}
                phase={type}
                validatedAction={a}
              />
            </Gutter>
          ))}

          <Separator light />
        </>
      )}

      {offerDocument && (
        <Gutter type={[0, 1.5, 0, 0]}>
          <ActionButton
            action="SHOW_DOCUMENT"
            label={
              <T>{`Orders:Documents.action.${
                offerDocument.fileStatus === DocumentFileStatus.Final
                  ? 'showDocument'
                  : 'showDocumentPreview'
              }`}</T>
            }
            onClick={openDocumentModal}
          />
        </Gutter>
      )}

      <Spacer />

      {deleteAction && (
        <Action
          isProcessing={processingTarget === deleteAction.action}
          onClick={handleDeleteOffer}
          phase={type}
          validatedAction={deleteAction}
        />
      )}
    </Wrapper>
  )
}

//////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Separator = styled(SectionSeparator)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`
