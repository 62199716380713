import { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { FlexColumn } from '@/components/Layout'
import { orderContexts, orderQueries } from '@/modules/Order'
import {
  Offer,
  OfferByIdPayload,
  OfferByIdVariables,
  OfferInfo,
  OrderType as Order,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales'

import { CollapseWrapper, ContentWrapper } from '../common'
import { Content, Header } from './components'

type Props = {
  offer: Offer | OfferInfo
  order: Order
}

export const OfferPhase = ({ offer, order }: Props) => {
  const { id } = offer

  const { selectedPhaseId, setSelectedPhaseId } = orderContexts.useOrderView()
  const { setOrder } = salesHooks.useSalesDetailsContext()

  const isFull = (offer as Offer).document !== undefined
  const isOpen = selectedPhaseId === id

  const { data, loading, refetch } = useQuery<
    OfferByIdPayload,
    OfferByIdVariables
  >(orderQueries.OFFER_BY_ID, {
    skip: !isOpen,
    variables: { id },
  })

  useEffect(() => {
    if (data) {
      setOrder({
        ...order,
        offers: order.offers.map((o) => (o.id === id ? data.offer : o)),
      })
    }
  }, [data])

  return (
    <FlexColumn>
      <Header
        isOpen={isOpen && isFull}
        isLoading={loading}
        offer={offer}
        openContent={() => setSelectedPhaseId(isOpen ? null : id)}
      />

      <CollapseWrapper isOpen={isOpen && isFull}>
        {isFull && (
          <ContentWrapper>
            <Content
              isVisible={isOpen}
              offer={offer as Offer}
              order={order}
              refetch={refetch}
            />
          </ContentWrapper>
        )}
      </CollapseWrapper>
    </FlexColumn>
  )
}
