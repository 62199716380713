import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  SetDocumentTemplateMutation,
  SetDocumentTemplateMutationVariables,
} from '~generated-types'

const SET_DOCUMENT_TEMPLATE_MUTATION = gql`
  ${orderFragments.document}

  mutation SetDocumentTemplate($input: DocumentSetTemplateInput!) {
    documentSetTemplate(input: $input) {
      ...Document
    }
  }
`

export const useSetDocumentTemplateMutation = () =>
  useMutation<
    SetDocumentTemplateMutation,
    SetDocumentTemplateMutationVariables
  >(SET_DOCUMENT_TEMPLATE_MUTATION)
