import moment, { HTML5_FMT, Moment } from 'moment'
import writeXlsxFile, { Schema } from 'write-excel-file'

import { formatCurrency } from '@/utils/currency'

import { OpenAdvancesQuery } from '~generated-types'

import { getCustomerName } from './getCustomerName'

interface DataRow {
  amountWithoutServiceFeeOrVat: number
  customer: string
  invoiceDate: string | null
  invoiceNumber: string | null
  salesEndMonth: string | null
  salesEndYear: string | null
  salesNumber: string | null
  sellerShort: string | null
}

interface Input {
  data: OpenAdvancesQuery['openAdvances']
  targetDate: Moment
  translateFn: (keys: string | Array<string>) => string
}

export const generateOpenAdvanceExcel = async ({
  data,
  targetDate,
  translateFn,
}: Input): Promise<void> => {
  const schema: Schema<DataRow> = [
    {
      column: translateFn('seller'),
      value: ({ sellerShort }) => sellerShort ?? '–',
      width: 15,
    },
    {
      column: translateFn('salesYear'),
      value: ({ salesEndYear }) =>
        salesEndYear ? moment(salesEndYear).format('YYYY') : '–',
      width: 15,
    },
    {
      column: translateFn('salesMonth'),
      value: ({ salesEndMonth }) =>
        salesEndMonth ? moment(salesEndMonth).format('M') : '–',
      width: 15,
    },
    {
      column: translateFn('sales'),
      value: ({ salesNumber }) => salesNumber ?? '–',
      width: 15,
    },
    {
      column: translateFn('invoice'),
      value: ({ invoiceNumber }) => invoiceNumber ?? '–',
      width: 15,
    },
    {
      column: translateFn('invoiceDate'),
      value: ({ invoiceDate }) =>
        invoiceDate ? moment(invoiceDate).format('DD.MM.YYYY') : '–',
      width: 15,
    },
    {
      column: translateFn('customer'),
      value: ({ customer }) => customer,
      width: 25,
    },
    {
      align: 'right',
      column: translateFn('openAdvance'),
      value: ({ amountWithoutServiceFeeOrVat }) =>
        formatCurrency(amountWithoutServiceFeeOrVat ?? 0),
      width: 15,
    },
  ]

  const rows = data.map((i) => ({
    amountWithoutServiceFeeOrVat: i.amountWithoutServiceFeeOrVat,
    customer: getCustomerName(i.customerName, i.customerNumber),
    invoiceDate: i.invoiceDate,
    invoiceNumber: i.invoiceNumber,
    salesEndMonth: i.salesEnd,
    salesEndYear: i.salesEnd,
    salesNumber: i.salesNumber,
    sellerShort: i.sellerShortName,
  }))

  await writeXlsxFile(rows, {
    fileName: `Avoimet-ennakot-${targetDate.format(HTML5_FMT.DATE)}.xlsx`,
    schema,
  })
}
