import { Moment } from 'moment'
import styled, { css } from 'styled-components'

import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { T, useLanguageContext } from '@/modules/Language'
import {
  HideFromPrintSpan,
  ListingTableSubheader,
  OnlyInPrintSpan,
  PrintLink,
} from '@/modules/Listing/common'
import { customerHooks } from '@/modules/Registry/Customer'
import { SalesTypeChip } from '@/modules/Sales'
import { useTheme } from '@/theme'

import { PrintSize } from '../../../types'
import { AgeGroupBreakdown, ParticipantsMode, RowSelector } from '../../common'
import { getParticipantsByRoom, getUniqueParticipants } from '../../utils'
import type {
  DeparturesParticipant,
  DeparturesRoomReservation,
  DeparturesSales,
} from '../useDeparturesForDate'
import { SalesTableRoomRow } from './SalesTableRoomRow'
import { SalesTableUnassignedRow } from './SalesTableUnassignedRow'

type Props = {
  data: DeparturesSales
  guestCounts: Record<string, number>
  headerOffset: number
  isSelected: boolean
  printSize: PrintSize
  selectionEnabled: boolean
  targetDate: Moment
  participantsMode: ParticipantsMode
  toggleSelection: () => void
}

export const SalesTableRow = ({
  data,
  guestCounts,
  headerOffset,
  isSelected,
  printSize,
  selectionEnabled,
  targetDate,
  participantsMode,
  toggleSelection,
}: Props) => {
  const { language } = useLanguageContext()
  const { typography, spacing } = useTheme()

  const roomNumberSorter = (
    a: DeparturesRoomReservation,
    b: DeparturesRoomReservation
  ) =>
    a.request.room.number.localeCompare(b.request.room.number, language, {
      numeric: true,
    })

  const customer = data.customer
    ? customerHooks.useCustomerDetails({ customer: data.customer })
    : null

  const participantsByRoom: Record<string, DeparturesParticipant[]> =
    getParticipantsByRoom(data)

  const hideFromPrint = selectionEnabled && !isSelected

  const guestsWithUnassignedRoom = getUniqueParticipants(
    participantsByRoom['unassigned'] || [],
    targetDate,
    participantsMode
  )

  return (
    <>
      <ListingTableSubheader
        colSpan={7}
        hideFromPrint={hideFromPrint}
        parts={[
          {
            content: (
              <FlexRow>
                {selectionEnabled && (
                  <RowSelector
                    isSelected={isSelected}
                    toggleSelection={toggleSelection}
                  />
                )}
                {customer ? (
                  <PrintLink
                    to={`/registry/customers/${customer.customerNumber}`}
                    style={{
                      fontSize: typography.fontSizeBase2,
                      wordBreak: 'break-word',
                    }}
                  >
                    {customer.displayName}
                  </PrintLink>
                ) : (
                  <em>
                    <T>Customers:unset</T>
                  </em>
                )}
              </FlexRow>
            ),
            size: 2,
          },
          {
            content: (
              <>
                <PrintLink
                  to={`/sales/details/${data.id}`}
                  style={{
                    fontSize: typography.fontSizeBase2,
                    wordBreak: 'break-word',
                  }}
                >
                  #{data.orderNumber} {data.name}
                </PrintLink>
                <SalesTypeChip
                  facet={data.facet}
                  format="short"
                  size="xs"
                  style={{ margin: `0 ${spacing.gutter}` }}
                  type={data.type}
                />

                <HideFromPrintSpan>
                  {data?.paymentAgreement && (
                    <Tooltip
                      content={data.paymentAgreement.name}
                      delay={300}
                      trigger={(triggerProps) => (
                        <span
                          {...triggerProps}
                          style={{ fontSize: typography.fontSizeBase2 }}
                        >
                          {data.paymentAgreement?.code}
                        </span>
                      )}
                    />
                  )}
                </HideFromPrintSpan>
                <OnlyInPrintSpan style={{ whiteSpace: `nowrap` }}>
                  <PaymentAgreementLabel>
                    {data.paymentAgreement?.name || ''}
                  </PaymentAgreementLabel>
                </OnlyInPrintSpan>
              </>
            ),
            size: 2,
          },
          {
            content: (
              <AgeGroupBreakdown
                counts={guestCounts}
                title={
                  <T
                    l10n={`Listings:ArrivalsAndDeparturesListing.departures.departures`}
                  />
                }
              />
            ),
            size: 3,
          },
        ]}
        printSize={printSize}
        stickyProps={headerOffset ? { top: `${headerOffset}px` } : undefined}
      />

      {[...Object.values(data.roomsById)].sort(roomNumberSorter).map((x) => {
        const participants = participantsByRoom[x.id] || []

        const guests = getUniqueParticipants(
          participants,
          targetDate,
          participantsMode
        )

        return (
          !!guests.length && (
            <SalesTableRoomRow
              key={`arrivals-sales-room-${x.id}`}
              guests={guests}
              hideFromPrint={hideFromPrint}
              room={x}
            />
          )
        )
      })}

      {!!guestsWithUnassignedRoom.length && (
        <SalesTableUnassignedRow
          key="arrivals-sales-room-unassigned"
          hideFromPrint={hideFromPrint}
          guests={guestsWithUnassignedRoom}
        />
      )}
    </>
  )
}

////////////

const PaymentAgreementLabel = styled.span`
  whitespace: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
