import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FetchState, FetchStates } from '@/common/types'
import { Button } from '@/components/Button'
import { FlexRow } from '@/components/Layout'
import {
  SpreadsheetTD as TD,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { FontWeight } from '@/components/Typography'
import { Attachment, attachmentHooks } from '@/modules/Attachments'
import { useTheme } from '@/theme'
import { formatBytes, getFileType } from '@/utils/files'
import { getDateInFormat } from '@/utils/time'

import { FileOwner } from '~generated-types'

type Props = {
  attachment: Attachment
  number: number
  ownerId: string
  readOnly?: boolean
  refresh: () => Promise<any>
  setFilePreviewOpen: (isFilePreviewOpen: boolean) => void
  setFileState: (fileState: FetchState) => void
  setFileUrl: (fileUrl: string) => void
}

export const AttachmentListItem = ({
  attachment: { created, fileName, size, info },
  number,
  ownerId,
  readOnly,
  refresh,
  setFilePreviewOpen,
  setFileState,
  setFileUrl,
}: Props) => {
  const theme = useTheme()

  const { downloadAttachment, getAttachmentUrl, removeAttachment } =
    attachmentHooks.useAttachment()

  const [isDownloading, setDownloading] = useState<boolean>(false)
  const [isRemoving, setRemoving] = useState<boolean>(false)

  const handleOpenPreview = () => {
    setFilePreviewOpen(true)
    setFileState(FetchStates.LOADING)
    getAttachmentUrl(fileName, info.folder, info.ownerId, info.ownerType)
      .then((res) => {
        setFileUrl(res)
        setFileState(FetchStates.IDLE)
      })
      .catch(() => setFileState(FetchStates.ERROR))
  }

  const handleDownloadAttachment = () => {
    setDownloading(true)
    downloadAttachment(fileName, info.folder, info.ownerId, info.ownerType)
      .catch((err) => console.error('Failed to download PDF', err))
      .finally(() => setDownloading(false))
  }

  const handleRemoveAttachment = () => {
    setRemoving(true)
    removeAttachment(fileName, ownerId, FileOwner.Sales)
      .then(() => refresh())
      .catch(() => setRemoving(false))
  }

  return (
    <TR>
      <TD>{number}.</TD>
      <TD>
        <FontWeight bold>{fileName}</FontWeight>
      </TD>
      <TD>{getDateInFormat(created, 'dd, D.M.YYYY')}</TD>
      <TD>{getFileType(fileName)}</TD>
      <TD>{formatBytes(size)}</TD>
      <TD align="right">
        <FlexRow>
          <IconButton onClick={handleOpenPreview}>
            <FontAwesomeIcon
              icon={['far', 'file-lines']}
              color={theme.palette.primary.main}
            />
          </IconButton>
          <IconButton
            disabled={isDownloading}
            onClick={handleDownloadAttachment}
          >
            <FontAwesomeIcon
              icon="download"
              color={theme.palette.primary.main}
            />
          </IconButton>
          <IconButton
            disabled={readOnly || isRemoving}
            onClick={handleRemoveAttachment}
          >
            <FontAwesomeIcon icon="trash" color={theme.palette.danger.main} />
          </IconButton>
        </FlexRow>
      </TD>
    </TR>
  )
}

const iconButtonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    width: ${theme.spacing.gutterBig};
  `}
`

const IconButton = styled(Button)`
  ${iconButtonCss}
`
