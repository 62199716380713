import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DataTableInputBase } from '@/components/DataTable'
import { InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

import { Title } from './common'

type Props = {
  notes: string
  setNotes: (notes: string) => void
}

export const NotesTextarea = ({ notes, setNotes }: Props) => {
  const [notesError, setNotesError] = useState<boolean>(false)

  const checkNotesError = () => setNotesError(!notes)

  return (
    <InlineModalSection style={{ marginTop: 0 }}>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.notes</T> *
        </Title>
        <Textarea
          state={notesError ? 'danger' : undefined}
          onBlur={checkNotesError}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setNotes(e.target.value)
          }}
          showBorder
          value={notes}
        />
      </FlexColumn>
    </InlineModalSection>
  )
}

////////

const Textarea = styled(DataTableInputBase).attrs(() => ({
  as: 'textarea',
}))`
  &&& {
    border-radius: 6px;
    min-height: 78px;
    line-height: 1.2;
    resize: vertical;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`
