import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { EventEnrollment } from '@/API/EventEnrollments'
import { HeaderSectionSeparator } from '@/components/ExpansionPanel'
import { FlexRow } from '@/components/Layout'
import { Tooltip } from '@/components/Tooltip'
import { FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { SaleStatus } from '@/modules/Sales/components/SalesDetails/components/Header/components/SaleStatus'
import { useTheme } from '@/theme'

import EnrollmentName from './EnrollmentName'

type StateCurrentFunc = (isExpanded: boolean) => void

type Props = {
  enrollment: EventEnrollment
  readOnly?: boolean
  isExpanded: boolean
  rename: (enrollmentId: string, newName: string | null) => Promise<any>
  setExpanded: (current: StateCurrentFunc | boolean) => void
  openSections: (sections: string[]) => void
}

export default function EnrollmentHeader({
  enrollment,
  readOnly,
  isExpanded,
  rename,
  setExpanded,
  openSections,
}: Props) {
  const { customer, estimatedDates, id, orders, participantConnection } =
    enrollment
  const { language } = useLanguageContext()
  const theme = useTheme()

  const totalPrice = useMemo(
    () =>
      orders
        .map((order) => order.paymentInfo.totalPrice.amountVatIncluded)
        .reduce((acc, val) => acc + val, 0),
    [orders]
  )

  const handleOpenSales = (id: string) => {
    const url = `/sales/details/${id}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  const participantsNames = participantConnection?.nodes
    ?.map(
      (p) =>
        `${p.firstName || ''}${
          p.lastName ? `${p.firstName ? ' ' : ''}${p.lastName}` : ''
        }`
    )
    .filter(Boolean)

  return (
    <HeaderWrapper style={{ marginRight: 0 }}>
      <Section width={`${theme.spacing.gu(25)}rem`}>
        <NameRow>
          {customer?.customer ? (
            <>
              <FontAwesomeIcon
                fixedWidth
                icon={
                  customer?.customer.__typename === 'CustomerOrganization'
                    ? ['far', 'building']
                    : 'user'
                }
                size="sm"
              />{' '}
              {customer?.customer.__typename === 'CustomerOrganization'
                ? `${customer.customer.organization.name}`
                : `${customer.customer.person.firstName}, ${customer.customer.person.lastName}`}
            </>
          ) : (
            <FontWeight light style={{ fontStyle: 'italic' }}>
              <T>SalesDetails:Customer.unset</T>
            </FontWeight>
          )}
        </NameRow>
      </Section>

      <HeaderSectionSeparator />

      <EnrollmentName
        enrollment={enrollment}
        readOnly={readOnly || enrollment.locked}
        rename={rename}
      />

      <SalesStatusWrapper>
        <SaleStatus salesId={enrollment.id} lifecycle={enrollment.lifecycle} />
      </SalesStatusWrapper>

      <HeaderButton
        width={`${theme.spacing.gu(68)}rem`}
        onClick={() => openSections(['participants'])}
      >
        {participantConnection?.totalElements.toString() ? (
          <>
            <Label>
              {translate(
                'SalesDetails:Enrollment.participantsTitle',
                language,
                {
                  count: participantConnection?.totalElements,
                }
              )}
            </Label>
            <Participants>
              <Count>{participantConnection?.totalElements}</Count>
              {participantsNames && (
                <FontWeight light>
                  {participantsNames.length > 0 && ' – '}
                  {participantsNames.join(', ')}
                </FontWeight>
              )}
            </Participants>
          </>
        ) : (
          <T>SalesDetails:Enrollment.participantsError</T>
        )}
      </HeaderButton>

      <HeaderSectionSeparator />

      <Section width="8rem">
        {!!estimatedDates && (
          <>
            <Label>
              {translate('SalesDetails:EstimatedDates.title', language)}
            </Label>
            <Count>{`${moment(estimatedDates.start).format('DD.MM')} – ${moment(
              estimatedDates.end
            ).format('DD.MM.YY')}`}</Count>
          </>
        )}
      </Section>

      <HeaderSectionSeparator />

      <PaymentAgreementWrapper>
        {enrollment.paymentAgreement && (
          <Tooltip
            content={enrollment.paymentAgreement.name}
            delay={300}
            trigger={(triggerProps) => (
              <span {...triggerProps}>{enrollment.paymentAgreement?.code}</span>
            )}
          />
        )}
      </PaymentAgreementWrapper>

      <HeaderSectionSeparator />

      <HeaderButton
        width={`${theme.spacing.gu(16)}rem`}
        onClick={() => openSections(['orders'])}
      >
        {totalPrice >= 0 ? (
          <>
            <Label>
              {translate('SalesDetails:Enrollment.totalPriceTitle', language)}
            </Label>
            <Count>{`${Math.round(totalPrice * 100) / 100} €`}</Count>
          </>
        ) : (
          <T>SalesDetails:Enrollment.totalPriceError</T>
        )}
      </HeaderButton>

      <HeaderSectionSeparator style={{ marginRight: theme.spacing.gutter }} />

      <HeaderButton onClick={() => handleOpenSales(id)}>
        <FontAwesomeIcon
          icon="up-right-from-square"
          color={theme.palette.smoke.extraDark}
        />
      </HeaderButton>

      {!isExpanded && (
        <HeaderButton onClick={() => setExpanded((current) => !current)}>
          <FontAwesomeIcon
            icon={'expand'}
            color={theme.palette.smoke.extraDark}
          />
        </HeaderButton>
      )}
    </HeaderWrapper>
  )
}

////////////

const HeaderWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 52px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}
`

const Count = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const Label = styled.span`
  font-variant-caps: all-small-caps;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Section = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
    margin-right: ${theme.spacing.gutter};
  `}

  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
`

const PaymentAgreementWrapper = styled(Section)`
  align-items: center;
  font-weight: 500;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
    font-size: ${theme.typography.fontSizeBase};
  `};
`

const HeaderButton = styled.div<{ width?: string }>`
  display: grid;
  cursor: pointer;
  transition: 0.2s;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gutter};
  `}

  width: ${({ width }) => (width ? width : 'auto')};
  & > div svg {
    display: none;
  }

  &:hover {
    span,
    svg {
      ${({ theme }) => css`
        color: ${theme.palette.primary.main};
      `}
    }
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}

    & > div svg {
      display: block;
    }
  }
`

const NameRow = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(25)}rem;
  `}
`

const Participants = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const SalesStatusWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > div {
    min-width: 200px;
    display: flex;
    justify-content: space-between;
  }
`
