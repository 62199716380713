import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { DropdownButton, DropdownOption } from '@/components/ExtraButtons'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { useParticipantsListContext } from '../../ParticipantsListState'

type Props = {
  readOnly?: boolean
}

export const CreateParticipantButton = ({ readOnly }: Props) => {
  const { spacing } = useTheme()
  const { handleAddParticipant } = useParticipantsListContext()

  const options: DropdownOption[] = [
    {
      label: (
        <>
          <Icon icon="sun" />
          <T>ParticipantsList:addDayVisitor</T>
        </>
      ),
      onClick: () => handleAddParticipant({ isDayVisitor: true }),
    },
    {
      label: (
        <>
          <Icon icon="bed" size="sm" />
          <T>ParticipantsList:addNightVisitor</T>
        </>
      ),
      onClick: () => handleAddParticipant({ isDayVisitor: false }),
    },
  ]

  return (
    <DropdownButton
      options={options}
      dropdownPlacement="bottom-start"
      renderCustomButton={({ onClick }) => (
        <InnocuousButton disabled={readOnly} noNudge onClick={onClick}>
          <PrimaryColor>
            <FontAwesomeIcon
              icon="plus"
              size="sm"
              style={{ marginRight: `${spacing.gu(1)}rem` }}
            />
            <T>ParticipantsList:add</T>
          </PrimaryColor>
        </InnocuousButton>
      )}
    />
  )
}

//////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 20px;
    margin-right: 10px;
  }
`
