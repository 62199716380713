import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  CreateAdvanceMutation,
  CreateAdvanceMutationVariables,
} from '~generated-types'

const CREATE_ADVANCE_MUTATION = gql`
  ${orderFragments.invoice}

  mutation CreateAdvance($input: OrderCreateAdvanceInput!) {
    orderCreateAdvance(input: $input) {
      invoice {
        ...Invoice
      }
    }
  }
`

export const useCreateAdvanceMutation = () =>
  useMutation<CreateAdvanceMutation, CreateAdvanceMutationVariables>(
    CREATE_ADVANCE_MUTATION
  )
