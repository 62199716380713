import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Dimensions } from '@/modules/Dimensions'
import { T } from '@/modules/Language'

import { Feature, SalesType } from '~generated-types'

import { useSalesDetailsContext } from '../../'
import { ContentSection, SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import { CommissionRate } from './CommissionRate'
import { Customer } from './Customer'
import { Estimation } from './Estimation'
import { EventSettings } from './EventSettings'
import { Facet } from './Facet'
import { Group } from './Group'
import { Language } from './Language'
import { Notes } from './Notes'
import { PaymentAgreement } from './PaymentAgreement'
import { SalesVST } from './SalesVST'

const SECTION: SectionFeatures = {
  icon: 'info',
  key: 'overview',
}

const OverviewComponent = () => {
  const {
    data: {
      type,
      webshopSettings,
      facet: { features, probabilityEstimate },
      vst,
    },
  } = useSalesDetailsContext()

  const showEventSettings = type === SalesType.Event

  const showCommissionRate = !!features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const showParticipantGroup = !!features.find(
    ({ feature }) => feature === Feature.Participants
  )

  const showVST =
    !!features.find(({ feature }) => feature === Feature.Vst) &&
    vst &&
    (vst.mainCode || vst.sideCode)

  return (
    <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
      <RowWrapper>
        <FlexColumn flex={8}>
          <CustomerWrapper>
            <Customer />
          </CustomerWrapper>
        </FlexColumn>

        <ContentSection flex={5} title={<T>SalesDetails:Settings.title</T>}>
          <SettingsWrapper>
            <BasicSettingsWrapper>
              <Facet />
              <VerticalDivider />
              <Language />

              {showParticipantGroup && (
                <>
                  <VerticalDivider />
                  <Group />
                </>
              )}
            </BasicSettingsWrapper>

            <HorizontalDivider />

            <SecondarySettingsWrapper noMargin={!probabilityEstimate}>
              <PaymentAgreement />

              {showCommissionRate ? (
                <>
                  <VerticalDivider />
                  <CommissionRate />
                </>
              ) : (
                <FlexRow flex={1} />
              )}
            </SecondarySettingsWrapper>

            {probabilityEstimate && (
              <>
                <HorizontalDivider />
                <Estimation />
              </>
            )}
          </SettingsWrapper>
        </ContentSection>
      </RowWrapper>

      <RowWrapper>
        <FlexColumn flex={8}>
          <DimensionsWrapper>
            <Dimensions />
          </DimensionsWrapper>

          {showVST && (
            <VstWrapper>
              <SalesVST />
            </VstWrapper>
          )}
        </FlexColumn>

        <NotesWrapper flex={5}>
          <Notes />
        </NotesWrapper>
      </RowWrapper>

      {showEventSettings && webshopSettings?.eventSettings && (
        <RowWrapper>
          <FlexColumn flex={8}>
            <EventSettings eventSettings={webshopSettings.eventSettings} />
          </FlexColumn>

          <NotesWrapper flex={5} />
        </RowWrapper>
      )}
    </SectionContainer>
  )
}

////////

const BasicSettingsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const CustomerWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(2)}rem;
  `}
`

const DimensionsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
  `}
`

const NotesWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(50)}rem;
  `}
`

const RowWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(4)}rem;
  `}
`

const SecondarySettingsWrapper = styled(FlexRow)<{ noMargin: boolean }>`
  ${({ noMargin, theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
    margin-bottom: ${theme.spacing.gu(noMargin ? 0 : 1)}rem;
  `}
`

const SettingsWrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem;
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const VerticalDivider = styled.div`
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const VstWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
    margin-top: ${theme.spacing.gu(4)}rem;
  `}
`

export const Overview = Object.freeze({
  component: OverviewComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
