import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from 'graphql-ws'

import { notify } from '@/components/NotificationService'
import { GRAPHQL_URL, WS_URL } from '@/config/env'

// generated by GraphQL Code Generator Fragment Matcher plugin
import generatedIntrospection from './graphql-codegen/fragment-matcher'

interface Props {
  handleNetworkError: (statusCode: number) => void
}

export const createApolloClient = ({ handleNetworkError }: Props) => {
  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      OrderLifecycle: {
        merge: true,
      },
      PaymentInfo: {
        merge: true,
      },
      PriceElements: {
        merge: true,
      },
      Registry: {
        merge: true,
      },
      RoomRequest: {
        merge: true,
      },
      RoomStatus: {
        merge: true,
      },
      RoomTypeRequest: {
        merge: true,
      },
      SalesLifecycle: {
        merge: true,
      },
      VatCode: {
        keyFields: (object) =>
          `${object.__typename}:${object.id}-${object.vatRate}`,
      },
    },
  })

  const httpLink = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        const errors = graphQLErrors.some((e) => e.extensions?.issues?.length)
          ? graphQLErrors.filter((e) => e.extensions?.issues?.length)
          : graphQLErrors

        errors.forEach(({ extensions, message, locations, path }) => {
          if (extensions?.issues?.length) {
            const { code, level } = extensions.issues[0]
            notify({ translationPath: `IssueCodes:${code}`, type: level })
          } else {
            notify({ translationPath: 'common:error.common', type: 'ERROR' })
          }

          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        })
      }

      if (!!networkError) {
        console.warn(`[Network error]: ${networkError}`)

        handleNetworkError(
          'statusCode' in networkError ? networkError.statusCode : -1
        )
      }
    }),
    // @ts-ignore
    new createUploadLink({
      credentials: 'include',
      uri: GRAPHQL_URL,
    }),
  ])

  const wsLink = new GraphQLWsLink(
    createClient({
      connectionParams: () => ({
        authToken: `Bearer ${sessionStorage.getItem('token')}`,
      }),
      lazy: true,
      url: WS_URL,
    })
  )

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  }))

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    authLink.concat(httpLink)
  )

  return new ApolloClient({
    cache: cache,
    link: link,
  })
}
