import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { useLanguageContext } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { generateCompareFn } from '@/utils/arrays'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

import {
  SalesForPrintAccommodationRoomTypeReservation,
  SalesPrintConfigKey as Key,
} from '../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../utils'
import { getTargetName } from '../../utils'
import { Features } from './Features'
import { Notes } from './Notes'
import { RoomReservation } from './RoomReservation'

type Props = {
  reservation: SalesForPrintAccommodationRoomTypeReservation
}

export const RoomTypeReservation = ({
  reservation: {
    request: {
      beds,
      checkIn,
      checkOut,
      extraBeds,
      features,
      info,
      rooms,
      roomType: { name },
    },
    roomReservations,
    target,
  },
}: Props) => {
  const { language } = useLanguageContext()
  const { salesPrintConfig: config } = useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Accommodation}.${key}`)

  return (
    <Wrapper withNested={!!roomReservations.length}>
      <Row alignItems="center">
        {checkVisibility(Key.Room) && (
          <Section style={{ width: '16rem' }}>
            <span style={{ fontWeight: 600 }}>
              {rooms > 1 ? `${rooms} × ${name}` : name}
            </span>
            <span style={{ fontSize: '0.9rem', whiteSpace: 'pre-wrap' }}>
              {' '}
              ({beds} + {extraBeds})
            </span>
          </Section>
        )}

        {checkVisibility(Key.Target) && (
          <Section flex={1}>
            {target ? getTargetName(target, language) : '–'}
          </Section>
        )}

        {checkVisibility(Key.Dates) && (
          <Section flex={1}>
            {formatDate(checkIn.date, checkOut.date, 'short')}
          </Section>
        )}

        {checkVisibility(Key.Features) && <Features features={features} />}
      </Row>

      {checkVisibility(Key.Notes) && !!info && <Notes info={info} />}

      {[...roomReservations]
        .sort(generateCompareFn('request.room.number'))
        .map((r) => (
          <RoomReservation isNested key={r.id} reservation={r} />
        ))}
    </Wrapper>
  )
}

////////////

const Row = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem 0;
  `}
`

const Section = styled(FlexRow)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ withNested: boolean }>`
  font-size: 1.1rem;
  margin-top: -1px;
  page-break-inside: avoid;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.text.light};
  `}

  ${({ withNested, theme }) =>
    !withNested &&
    css`
      border-bottom: 1px solid ${theme.palette.text.light};
    `}
`
