import { useState } from 'react'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { PrintOnlyTitle } from '@/modules/Listing/common'
import { ParticipantForPrint } from '@/modules/ParticipantsList'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { SalesDetailsQuery } from '~generated-types'

import {
  PageBreakCheckbox,
  ParticipantsGroupTable,
  ParticipantsTable,
  PrintButton,
  PrintMode,
  PrintSettings,
  RoomsTable,
} from './components'

type Customer = SalesDetailsQuery['sales']['customer']

type Props = {
  participants: ParticipantForPrint[]
}

export const PrintableParticipantsList = ({ participants }: Props) => {
  const {
    data: { customer, name: salesName, groups },
  } = useSalesDetailsContext()

  const groupsLabel = groups?.label || null

  const [pageBreak, setPageBreak] = useState<boolean>(false)
  const [printMode, setPrintMode] = useState<PrintMode>(PrintMode.Participants)

  return (
    <>
      <PrintOnlyTitle>{getTitle(customer, salesName)}</PrintOnlyTitle>

      <FlexRow alignItems="flex-end" hideFromPrint justifyContent="flex-end">
        {printMode === PrintMode.ParticipantGroup && (
          <PageBreakCheckbox
            pageBreak={pageBreak}
            setPageBreak={setPageBreak}
          />
        )}

        <PrintSettings
          printMode={printMode}
          setPrintMode={setPrintMode}
          groupsLabel={groupsLabel}
        />

        <PrintButton />
      </FlexRow>

      {printMode === PrintMode.Participants && (
        <ParticipantsTable participants={participants} />
      )}

      {printMode === PrintMode.Rooms && (
        <RoomsTable participants={participants} />
      )}

      {printMode === PrintMode.ParticipantGroup && (
        <ParticipantsGroupTable
          pageBreak={pageBreak}
          participants={participants}
        />
      )}
    </>
  )
}

///////

const getTitle = (customer: Customer, salesName: string | null) => {
  const customerName = getCustomerName(customer)

  return customerName || salesName ? (
    `${customerName}${customerName && salesName ? ' – ' : ''}${salesName}`
  ) : (
    <T>ParticipantsList:participants</T>
  )
}

const getCustomerName = (customerData: Customer) => {
  if (!customerData) {
    return ''
  }

  const { customer } = customerData

  return customer && customer.__typename === 'CustomerOrganization'
    ? customer.organization.name
    : parseName(customer?.person.firstName, customer?.person.lastName)
}

const parseName = (firstName?: string, lastName?: string): string =>
  `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`
