import { gql, useQuery } from '@apollo/client'
import moment, { HTML5_FMT } from 'moment'

import { OPEN_STATES } from '@/modules/Sales'
import { generateCompareFn } from '@/utils/arrays'

import { SalesType } from '~generated-types'
import {
  SalesForIsoWeekQuery as QueryData,
  SalesForIsoWeekQueryVariables as QueryVariables,
} from '~generated-types'

import { SalesForIsoWeekSales } from './types'

const getReferenceDate = (
  isoWeek: number,
  isoWeekYear: number,
  offset: number
) =>
  moment(`${isoWeekYear}W${isoWeek}`, 'YYYY[W]W')
    .startOf('isoWeek')
    .add(offset, 'days')
    .format(HTML5_FMT.DATE)

const GET_QUERY = (isoWeek: number, isoWeekYear: number) => gql`
  query SalesForIsoWeek {
    sales: salesForInterval(input: {
      end: "${getReferenceDate(isoWeek, isoWeekYear, 6)}"
      start: "${getReferenceDate(isoWeek, isoWeekYear, 0)}"
    }) {
      customer {
        customer {
          customerNumber
          id

          ... on CustomerOrganization {
            organization {
              businessId
              name
            }
          }

          ... on CustomerPerson {
            person {
              firstName
              lastName
            }
          }
        }
      }
      estimatedDates {
        end
        start
      }
      estimatedParticipants
      id
      lifecycle {
        state
      }
      name
      orderNumber
      reservationDates {
        end
        start
      }
      resourceReservationCount
      seller {
        id
        name
        shortName
      }
      type
      paymentAgreement {
        id
        name
        code
      }
      facet {
        id
        name
        abbreviation
        color
      }
    }
  }
`

interface UseSalesForIsoWeekProps {
  isoWeek: number
  isoWeekYear: number
  typeFilter?: SalesType[]
}

interface UseSalesForIsoWeekHook {
  error: boolean
  loading: boolean
  sales: SalesForIsoWeekSales[]
}

const useSalesForIsoWeek = ({
  isoWeek,
  isoWeekYear,
  typeFilter,
}: UseSalesForIsoWeekProps): UseSalesForIsoWeekHook => {
  const {
    data,
    error: apolloError,
    loading,
  } = useQuery<QueryData, QueryVariables>(GET_QUERY(isoWeek, isoWeekYear), {
    fetchPolicy: 'cache-and-network',
  })

  const sales = (
    data?.sales
      ? data.sales
          .filter(({ lifecycle }) => OPEN_STATES.includes(lifecycle.state))
          .filter(({ type }) => (typeFilter ? typeFilter.includes(type) : true))
      : []
  ).sort(generateCompareFn(['sales.name']))

  return {
    error: !!apolloError,
    loading,
    sales,
  }
}

export default useSalesForIsoWeek
