import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { generateCompareFn } from '@/utils/arrays'

import {
  SalesForPrintMeal as Meal,
  SalesForPrintResourceReservation as Reservation,
  SalesForPrintResourceReservationWithTasks as ReservationWithTasks,
  SalesForPrintTask as Task,
  SalesPrintConfigKey as Key,
} from '../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../utils'
import { Group, ProgramTypeKey } from './utils/getProgramByDateKey'
import { MealRow, ReservationGroupRow, ReservationRow, TaskRow } from './Rows'
import { SectionTitle } from './SectionTitle'
import { getGroupedMeals, getProgramByDateKey } from './utils'

type Props = {
  meals: Meal[]
  reservations: Reservation[]
  tasks: Task[]
}

export const Program = ({
  meals: inputMeals,
  reservations: inputReservations,
  tasks: inputTasks,
}: Props) => {
  const { salesPrintConfig: config } = useSalesDetailsContext()

  const checkVisibility = (path: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Program}.${path}`)

  const showGroups = checkVisibility(`${Key.Reservations}.${Key.Group}`)
  const showReservations = checkVisibility(Key.Reservations)
  const showTasks = checkVisibility(Key.Tasks)

  const meals = checkVisibility(Key.Meals) ? getGroupedMeals(inputMeals) : []
  const reservations = showReservations ? inputReservations : []
  const tasks = inputTasks.filter(({ dueDate }) => !!dueDate)
  const tasksWithNoDate = inputTasks.filter(({ dueDate }) => !dueDate)

  const programByDate = getProgramByDateKey({
    meals,
    reservations,
    showGroups,
    showReservations,
    showTasks,
    tasks,
  })

  return (
    <>
      <Title>
        <T>SalesDetails:PrintSale.label.PROGRAM</T>
      </Title>

      {!inputMeals.length && !inputReservations.length && !inputTasks.length ? (
        <Placeholder>
          – <T>SalesDetails:PrintSale.noProgram</T> –
        </Placeholder>
      ) : (
        <FlexColumn>
          {Object.keys(programByDate)
            .sort()
            .map((date) => (
              <Section key={date}>
                <SectionTitle date={date} />

                <tbody>
                  {programByDate[date]
                    .sort(generateCompareFn('start'))
                    .map((x) => {
                      const { __type, ...pureData } = x

                      switch (__type) {
                        case ProgramTypeKey.Meal:
                          return <MealRow key={x.id} data={pureData as Meal} />
                        case ProgramTypeKey.Reservation:
                          return (
                            <ReservationRow
                              key={x.id}
                              data={pureData as ReservationWithTasks}
                            />
                          )
                        case ProgramTypeKey.ReservationGroup:
                          return (
                            <ReservationGroupRow
                              key={x.id}
                              data={pureData as Group}
                            />
                          )
                        case ProgramTypeKey.Task:
                          const { start, ...pureTask } = pureData

                          return <TaskRow key={x.id} data={pureTask as Task} />
                        default:
                          return null
                      }
                    })}
                </tbody>
              </Section>
            ))}

          {!!tasksWithNoDate.length && (
            <Section>
              <SectionTitle />

              <tbody>
                {tasksWithNoDate.map((task) => (
                  <TaskRow key={task.id} data={task} />
                ))}
              </tbody>
            </Section>
          )}
        </FlexColumn>
      )}
    </>
  )
}

////////////

const Placeholder = styled.span`
  font-size: 1.25rem;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Section = styled.table`
  font-size: 1.1rem;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled.span`
  font-size: 1.375rem;
  font-weight: 600;
  page-break-after: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
    margin-top: ${theme.spacing.gu(5)}rem;
  `}
`
