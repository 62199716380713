import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  UpdateManualPaymentMutation,
  UpdateManualPaymentMutationVariables,
} from '~generated-types'

const UPDATE_MANUAL_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation UpdateManualPayment($input: PaymentUpdateInput!) {
    manualPaymentUpdate(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useUpdateManualPaymentMutation = () =>
  useMutation<
    UpdateManualPaymentMutation,
    UpdateManualPaymentMutationVariables
  >(UPDATE_MANUAL_PAYMENT_MUTATION)
