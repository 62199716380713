import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SalesState } from '~generated-types'

type Props = {
  activeStates: SalesState[]
  handleSetActiveStates: (states: SalesState[]) => void
}

export const StatesSelector = ({
  activeStates,
  handleSetActiveStates,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [isOpen, setOpen] = useState(false)

  const stateOptions = Object.values(SalesState).map((state) => ({
    label: <T>{`SalesDetails:Lifecycle.state.${state}`}</T>,
    selected: activeStates.includes(state),
    value: state,
  }))

  const handleSelectState = (value: SalesState, select: boolean) => {
    const updatedStates = select
      ? [...activeStates, value]
      : activeStates.filter((state) => state !== value)

    handleSetActiveStates(updatedStates)
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <InlineModal style={{ minWidth: `${theme.spacing.gu(24)}rem` }}>
          <InlineModalSection
            style={{
              marginTop: `${theme.spacing.gu(1)}rem`,
              padding: `0 0 ${theme.spacing.gu(1)}rem`,
            }}
          >
            {
              <FlexColumn noPadding flex={1}>
                {stateOptions.map((option) => (
                  <OptionWrapper
                    key={option.value}
                    onClick={() =>
                      handleSelectState(option.value, !option.selected)
                    }
                  >
                    <CheckIconWrapper>
                      {option.selected && (
                        <FontAwesomeIcon
                          icon="check"
                          size="sm"
                          color={theme.palette.primary.main}
                        />
                      )}
                    </CheckIconWrapper>
                    {option.label}
                  </OptionWrapper>
                ))}
              </FlexColumn>
            }
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <FlexRow ref={ref} onClick={() => setOpen(true)} alignItems="center">
          <StatesWrapper>
            <FontAwesomeIcon
              icon="filter"
              color={theme.palette.primary.main}
              size="sm"
            />

            {!!activeStates.length && (
              <States>
                {activeStates
                  .map((state) =>
                    translate(`SalesDetails:Lifecycle.state.${state}`, language)
                  )
                  .join(', ')}
              </States>
            )}
          </StatesWrapper>
        </FlexRow>
      )}
    />
  )
}

const OptionWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.primary.extraLight};
    `}
  }
`

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
`

const States = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`

const StatesWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
  font-weight: 500;
  height: 35px;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`
