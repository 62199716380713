import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Catalog } from '@/modules/Registry'

type Props = {
  catalog: Catalog
}

export const CatalogSection = ({ catalog }: Props) => (
  <Wrapper flex={1}>
    <Title>
      <T>Products:ProductManager.pricing.catalog</T>
    </Title>

    <Name alignItems="center">{catalog.name}</Name>
  </Wrapper>
)

/////////

const Name = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`
