import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { useLanguages } from '@/modules/Registry'
import { Spacing, useTheme } from '@/theme'

import { useSalesDetailsContext } from '../../../'

export const Language = () => {
  const { spacing } = useTheme()
  const {
    data: { id, language },
    saleReadOnly: readOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const { languages: fetchedLanguages, loading } = useLanguages({
    skip: !isEditMode,
  })

  const updateLanguage = (newLanguage: string) => {
    if (newLanguage === language) {
      return
    }

    setProcessing(true)

    updateAttributes(id, { language: newLanguage })
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        setEditMode(false)
      })
  }

  return (
    <Wrapper flex={0.75} noPadding>
      <Label>
        <T>SalesDetails:Settings.language.title</T>
      </Label>

      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={processing || loading}
            isDisabled={readOnly}
            menuIsOpen={isEditMode}
            name="language-selector"
            noOptionsMessage={() => <T>SalesDetails:Settings.language.empty</T>}
            onChange={(option?: Option | null) =>
              option && updateLanguage(option.value)
            }
            onBlur={() => setEditMode(false)}
            options={fetchedLanguages.map((l) => ({
              label: <T>{`common:language.${l}`}</T>,
              value: l,
            }))}
            placeholder="—"
            value={{
              label: <T>{`common:language.${language}`}</T>,
              value: language,
            }}
          />
        ) : (
          <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
            <T>{`common:language.${language}`}</T>
          </EditButton>
        )}
      </FlexRow>
    </Wrapper>
  )
}

////////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
