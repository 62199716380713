import {
  createContext,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import moment from 'moment'

import { ById } from '@/common/types'
import { ColumnDetails } from '@/components/Grid'
import { T } from '@/modules/Language'
import {
  CalendarResourceElastic,
  CalendarResourcesQueries,
} from '@/modules/Registry'
import { ResourceAvailability } from '@/modules/Registry'
import { useCalendarState } from '@/modules/Reservations'
import {
  Reservation,
  resourceReservationQueries,
} from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'

import {
  ResourceReservationsQuery,
  ResourceReservationsQueryVariables,
} from '~generated-types'

import {
  findTimeSteps,
  getColumnWidthByDensity,
  getGridGroupForCategorySelection,
  getGridGroupForResourceByWeek,
  getGridGroupForResources,
  groupReservationsByResource,
} from './utils'

const LAYOUT_VARIABLES = Object.freeze({
  COLUMN_HEADER_WIDTH: 230,
  COLUMN_WIDTH: 22,
  DEFAULT_COLUMN_NUMBER: 14, // from 8.00 to 22.00
  DEFAULT_SCROLL_HOUR: 8,
  ROW_HEIGHT: 36,
  TIME_STEP: 60,
})

export type CalendarDensity = 'S' | 'M' | 'L'

type CalendarVariables = {
  COLUMN_HEADER_WIDTH: number
  COLUMN_WIDTH: number
  DEFAULT_COLUMN_NUMBER: number
  DEFAULT_SCROLL_HOUR: number
  RESERVATION_TIME_STEP: number
  ROW_HEIGHT: number
  TIME_STEP: number
}

export type GridGroupSection = {
  id: string
  isPooled: boolean
  nestedResourcesIds?: string[]
  parentResourceId?: string
  resourceId: string
  nestedSections?: GridGroupSection[]
  targetDate: string
  title: ReactNode
}

type GridGroup = {
  emptyPlaceholder: ReactNode | null
  error: Error | null
  fetching: boolean
  id: string
  isFocused: boolean
  sections: GridGroupSection[]
  title: ReactNode | null | undefined
  titlePlaceholder: ReactNode
}

export type ResourceReservationIdsByResource = {
  [resourceId: string]: {
    [dateKey: string]: string[]
  }
}

type ContextType = {
  // Data
  calendarDensity: CalendarDensity
  calendarVariables: CalendarVariables
  calendarWrapperRef: RefObject<HTMLDivElement> | null
  columns: ColumnDetails[]
  error:
    | {
        [key: string]: any
      }
    | null
    | undefined
  fetching: boolean
  initialScrollRef: RefObject<HTMLDivElement> | null
  isReservationModalOpen: boolean
  gridGroups: (GridGroup | null)[]
  reservationsById: ById<Reservation>
  reservationIdsByResource: ResourceReservationIdsByResource
  resourceAvailabilities: ById<ResourceAvailability[]>
  scrolledCalendarWrapperRef: RefObject<HTMLDivElement> | null

  // Methods
  refetchAvailabilities: () => void
  setCalendarDensity: (density: CalendarDensity) => void
  setReservationsArr: (reservations: Reservation[]) => void
  updateCalendarWidthForModal: (isModalOpen: boolean) => void
}

const ReservationsGridContext = createContext<ContextType>({
  calendarDensity: 'S',
  calendarVariables: {
    COLUMN_HEADER_WIDTH: 292,
    COLUMN_WIDTH: 22,
    DEFAULT_COLUMN_NUMBER: 14,
    DEFAULT_SCROLL_HOUR: 8,
    RESERVATION_TIME_STEP: 15,
    ROW_HEIGHT: 36,
    TIME_STEP: 60,
  },
  calendarWrapperRef: null,
  columns: [],
  error: null,
  fetching: false,
  gridGroups: [],
  initialScrollRef: null,
  isReservationModalOpen: false,
  refetchAvailabilities: () => undefined,
  reservationIdsByResource: {},
  reservationsById: {},
  resourceAvailabilities: {},
  scrolledCalendarWrapperRef: null,
  setCalendarDensity: () => undefined,
  setReservationsArr: () => undefined,
  updateCalendarWidthForModal: () => undefined,
})

type ProviderProps = {
  children: ReactNode
}

export const ReservationsGridStateProvider = ({ children }: ProviderProps) => {
  const theme = useTheme()

  const {
    allResourcesById,
    error: resourcesError,
    fetching: fetchingResources,
    focusedResources,
    nonFocusedResources,
    reservationInterval: { end, start },
    selectedCategories,
    selectedResources,
    showFocusedResources,
    targetDate,
    targetResources,
    viewMode,
  } = useCalendarState()

  // Refs
  const calendarWrapperRef = useRef<HTMLDivElement | null>(null)
  const contextValueRef = useRef<ContextType | null | undefined>(null)
  const initialScrollRef = useRef<HTMLDivElement | null>(null)
  const scrolledCalendarWrapperRef = useRef<HTMLDivElement | null>(null)

  // States
  const [calendarDensity, setCalendarDensity] = useState<CalendarDensity>(
    // @ts-ignore
    localStorage.getItem('resourceCalendarDensity') ?? 'S'
  )
  const [calendarModalTicker, setCalendarModalTicker] = useState<number>(0)
  const [columnWidth, setColumnWidth] = useState<number>(
    LAYOUT_VARIABLES.COLUMN_WIDTH
  )
  const [error, setError] = useState<
    | {
        [key: string]: any
      }
    | null
    | undefined
  >(null)
  const [fetchingReservations, setFetchingReservations] =
    useState<boolean>(true)
  const [initialColumnWidth, setInitialColumnWidth] = useState<number>(
    LAYOUT_VARIABLES.COLUMN_WIDTH
  )
  const [isReservationModalOpen, setReservationModalOpen] =
    useState<boolean>(false)
  const [reservationIdsByResource, setReservationIdsByResource] =
    useState<ResourceReservationIdsByResource>({})
  const [reservationsById, setReservationsById] = useState<ById<Reservation>>(
    {}
  )
  const [reservationsArr, setReservationsArr] = useState<Reservation[]>([])
  const [scrollPosition, setScrollPosition] = useState<number | null>(null)

  const [loadResourceReservations, resourceReservations] = useLazyQuery<
    ResourceReservationsQuery,
    ResourceReservationsQueryVariables
  >(resourceReservationQueries.RESOURCE_RESERVATIONS, {
    fetchPolicy: 'no-cache',
  })

  // Each time column consists of 4 drag columns
  const RESERVATION_TIME_STEP = LAYOUT_VARIABLES.TIME_STEP / 4

  const targetResourcesIds = targetResources.map(({ id }) => id).sort()

  const pooledResourcesIds = targetResources
    .filter((r) => (r as CalendarResourceElastic).isPooled)
    .map(({ id }) => id)

  const { data: availabilityData, refetch: refetchAvailabilities } = useQuery(
    CalendarResourcesQueries.RESOURCE_AVAILABILITIES,
    {
      variables: {
        input: {
          end: end.format(moment.HTML5_FMT.DATETIME_LOCAL),
          resourceIds: pooledResourcesIds,
          slotSize: RESERVATION_TIME_STEP,
          start: start.format(moment.HTML5_FMT.DATETIME_LOCAL),
        },
      },
    }
  )

  const rawAvailabilities = availabilityData?.resourceAvailability || []
  const resourceAvailabilities =
    rawAvailabilities.reduce(
      (acc: any, { availability, resourceId }: any) => ({
        ...acc,
        [resourceId]: availability,
      }),
      {}
    ) || {}

  const setCurrentCalendarWidth = () =>
    new Promise<number>((resolve) => {
      const { COLUMN_HEADER_WIDTH, DEFAULT_COLUMN_NUMBER } = LAYOUT_VARIABLES

      if (calendarWrapperRef.current) {
        const calendarWidth = calendarWrapperRef.current.clientWidth
        const dynamicColumnWidth = Math.round(
          (calendarWidth - COLUMN_HEADER_WIDTH) / DEFAULT_COLUMN_NUMBER / 4
        )
        const currentColumnWidth = getColumnWidthByDensity(
          dynamicColumnWidth,
          calendarDensity
        )

        setInitialColumnWidth(dynamicColumnWidth)
        setColumnWidth(currentColumnWidth)
        resolve(currentColumnWidth)
      }
    })

  useEffect(() => {
    setCurrentCalendarWidth().then((width: number) => {
      if (initialScrollRef && initialScrollRef.current) {
        initialScrollRef.current.scrollLeft =
          LAYOUT_VARIABLES.DEFAULT_SCROLL_HOUR * width * 4
      }
    })
  }, [calendarWrapperRef, initialScrollRef])

  useEffect(() => {
    if (calendarModalTicker) {
      setCurrentCalendarWidth().then(() => {
        if (initialScrollRef.current) {
          const scrolledCalendarWidth =
            scrolledCalendarWrapperRef?.current?.clientWidth ?? 0

          const scrollPositionForModal = Math.round(
            ((scrollPosition ?? 0) * (scrolledCalendarWidth - 368)) /
              scrolledCalendarWidth
          )

          initialScrollRef.current.scrollLeft = isReservationModalOpen
            ? scrollPositionForModal
            : scrollPosition ?? 0

          if (!isReservationModalOpen) {
            setScrollPosition(null)
          }
        }
      })
    }
  }, [calendarModalTicker])

  useEffect(() => {
    const { error, data, loading } = resourceReservations

    setFetchingReservations(loading)
    error && setError(error)

    const reservations = data?.resourceReservations.reservations.reduce(
      (acc: Reservation[], r) => [...acc, ...r.reservations],
      []
    )

    if (reservations) {
      setReservationsArr(reservations)
    }
  }, [resourceReservations])

  useEffect(() => {
    const parseReservations = (data: Reservation[]) => {
      const allReservations = data.reduce(
        (acc: ById<Reservation>, val: Reservation) => ({
          ...acc,
          [val.id]: val,
        }),
        {}
      )

      setReservationsById(allReservations)
      setReservationIdsByResource(groupReservationsByResource(allReservations))
    }
    if (reservationsArr) {
      parseReservations(reservationsArr)
    }
  }, [reservationsArr])

  const resourcesIdsString = targetResourcesIds.length
    ? JSON.stringify(targetResourcesIds)
    : ''

  const endString = end.toISOString()
  const startString = start.toISOString()

  // Fetch all reservations for all resources when resources or dates change.
  // To achieve this, useEffect has a sorted & stringified id array of
  // resource ids and stringified dates as a dependency.
  useEffect(() => {
    resourcesIdsString &&
      loadResourceReservations({
        variables: {
          input: {
            end: end.format('YYYY-MM-DDTHH:mm:ss'),
            resourceIds: targetResourcesIds,
            start: start.format('YYYY-MM-DDTHH:mm:ss'),
          },
        },
      })
  }, [endString, resourcesIdsString, startString])

  const columns = findTimeSteps(RESERVATION_TIME_STEP, 'minutes').map(
    (x, index) => ({
      data: x,
      id: x.start,
      index,
    })
  )

  const selectedResourcesGroup = selectedResources.length
    ? getGridGroupForResources({
        emptyPlaceholder: null,
        error: resourcesError,
        fetching: fetchingResources,
        groupId: 'SELECTED',
        isFocused: false,
        resources: selectedResources,
        targetDate: targetDate.format('YYYY-MM-DD'),
        title: <T>ResourceReservationsCalendar:ResourceSelector.title</T>,
        viewMode,
      })
    : null

  const gridGroups: (GridGroup | null)[] =
    viewMode === 'WEEK'
      ? nonFocusedResources
          .map((resource) =>
            getGridGroupForResourceByWeek({ resource, targetDate, viewMode })
          )
          .flat()
      : [
          showFocusedResources
            ? getGridGroupForResources({
                emptyPlaceholder: (
                  <T>ResourceReservationsCalendar:focusedResources.empty</T>
                ),
                error: resourcesError,
                fetching: fetchingResources,
                groupId: 'FOCUSED',
                isFocused: true,
                resources: focusedResources,
                targetDate: targetDate.format('YYYY-MM-DD'),
                title: (
                  <T>ResourceReservationsCalendar:focusedResources.header</T>
                ),
                viewMode,
              })
            : null,
          selectedResourcesGroup,
          ...selectedCategories.map((x, idx) =>
            x.active
              ? getGridGroupForCategorySelection({
                  allResourcesById,
                  categorySelection: x,
                  idx,
                  targetDate: targetDate.format('YYYY-MM-DD'),
                  viewMode,
                })
              : null
          ),
        ].filter(Boolean)

  const setDensity = (density: CalendarDensity) => {
    setCalendarDensity(density)
    setColumnWidth(getColumnWidthByDensity(initialColumnWidth, density))
    localStorage.setItem('resourceCalendarDensity', density)
  }

  const updateCalendarWidthForModal = (isModalOpen: boolean) => {
    if (calendarWrapperRef && calendarWrapperRef.current) {
      calendarWrapperRef.current.style.width = isModalOpen
        ? // 500px + 14px (modal width + offset)
          `calc(100% - 500px - ${theme.spacing.guPx(2) + 6}px)`
        : '100%'

      if (isModalOpen) {
        setScrollPosition(initialScrollRef?.current?.scrollLeft ?? null)
      }

      setReservationModalOpen(isModalOpen)
      setCalendarModalTicker(calendarModalTicker + 1)
    }
  }

  contextValueRef.current = {
    calendarDensity,
    calendarVariables: {
      ...Object.freeze(LAYOUT_VARIABLES),
      COLUMN_WIDTH: columnWidth,
      RESERVATION_TIME_STEP,
    },
    calendarWrapperRef,
    columns,
    error: resourcesError || error,
    fetching: fetchingResources || fetchingReservations,
    gridGroups,
    initialScrollRef,
    isReservationModalOpen,
    refetchAvailabilities,
    reservationIdsByResource,
    reservationsById,
    resourceAvailabilities,
    scrolledCalendarWrapperRef,
    setCalendarDensity: setDensity,
    setReservationsArr,
    updateCalendarWidthForModal,
  }

  return (
    <ReservationsGridContext.Provider value={contextValueRef.current}>
      {children}
    </ReservationsGridContext.Provider>
  )
}

export const useReservationsGridState = () =>
  useContext(ReservationsGridContext)
