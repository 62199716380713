import { CSSProperties, useState } from 'react'
import { OptionProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import {
  SalesDimension,
  useSetDimensionLabelsMutation,
} from '@/modules/Dimensions'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales'
import { Palette, Spacing, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { Placeholder } from './common'
import { ValueReadOnly } from './ValueReadOnly'
import { ValueWrapper } from './ValueWrapper'

type Props = {
  dimension: SalesDimension
  dimensions: SalesDimension[]
  salesId: string
  disabled?: boolean
  readOnly?: boolean
}

export const Dimension = ({
  dimension: { dimension, selectedLabel, validatedOptions },
  dimensions,
  salesId,
  disabled,
  readOnly,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { refresh } = salesHooks.useSalesDetailsContext()

  const [isEditing, setEditing] = useState(false)

  const [setLabels, { loading }] = useSetDimensionLabelsMutation()

  const onSetLabel = (
    option: Option | null | undefined,
    dimensionKey: string
  ) => {
    const selected = dimensions.map((d) => ({
      ...d,
      selectedLabel:
        d.dimension.id === dimensionKey
          ? option
            ? { ...d.selectedLabel, id: option.value, name: option.label }
            : null
          : d.selectedLabel,
    }))
    const update = selected.map((d) => ({
      dimensionId: d.dimension.id,
      labelId: d.selectedLabel ? d.selectedLabel.id : null,
    }))

    setLabels({ variables: { input: { salesId, update } } })
      .then(() => {
        refresh()
        setEditing(false)
      })
      .catch(() => undefined)
  }

  const renderContent = () => {
    // Empty
    if (!validatedOptions?.length) {
      return (
        <div style={{ height: '30px', marginBottom: `${spacing.gu(2)}rem` }}>
          <Placeholder>
            <T>SalesDetails:dimensions.error.emptyLabels</T>
          </Placeholder>
        </div>
      )
    }

    const validLabels = validatedOptions.filter(
      (l) => l.validationCategory === 'VALID'
    )
    const invalidLabels = validatedOptions.filter(
      (l) => l.validationCategory === 'INVALID'
    )

    const validOptions = validLabels
      .map((l) => ({
        label: l.label.name,
        searchValue: l.label.name || '',
        value: l.label.id,
      }))
      .sort(generateCompareFn('label'))

    const invalidOptions = invalidLabels
      .map((l) => ({
        label: l.label.name,
        searchValue: l.label.name || '',
        value: l.label.id,
      }))
      .sort(generateCompareFn('label'))

    const groupedOptions = [
      {
        label: <T>SalesDetails:dimensions.status.valid</T>,
        options: validOptions,
      },
      {
        label: <T>SalesDetails:dimensions.status.invalid</T>,
        options: invalidOptions,
      },
    ]

    const isInvalid =
      !!invalidOptions.find((o: Option) => o.value === selectedLabel?.id) ||
      (dimension.required && !selectedLabel?.id)

    return readOnly ? (
      <ValueReadOnly
        label={selectedLabel?.name || ''}
        placeholder={readOnly ? dimension.name || '–' : '–'}
        isInvalid={isInvalid}
        disabled={disabled}
      />
    ) : (
      <FlexRow alignItems="center">
        {isEditing ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(isInvalid, palette, spacing)}
            formatGroupLabel={GroupLabel}
            isClearable
            isCompact
            isLoading={loading}
            isSearchable
            menuIsOpen={isEditing}
            name="dimension-selector"
            noOptionsMessage={() => (
              <T>SalesDetails:dimensions.error.emptyLabels</T>
            )}
            onBlur={() => setEditing(false)}
            onChange={(v: Option | null | undefined) =>
              onSetLabel(v, dimension.id)
            }
            options={groupedOptions}
            placeholder="–"
            value={
              selectedLabel && {
                label: selectedLabel.name,
                value: selectedLabel.id,
              }
            }
          />
        ) : (
          <ValueWrapper
            label={selectedLabel?.name || ''}
            isInvalid={isInvalid}
            setEditing={setEditing}
          />
        )}
      </FlexRow>
    )
  }

  return readOnly ? (
    <FlexColumn style={{ padding: `${spacing.gu(0.5)}rem` }}>
      {renderContent()}
    </FlexColumn>
  ) : (
    <Wrapper flex={1}>
      <DimensionName>{dimension.name}</DimensionName>

      {renderContent()}
    </Wrapper>
  )
}

//////

const getExtraStyles = (
  isInvalid: boolean,
  palette: Palette,
  spacing: Spacing
) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    '&:hover': {
      borderColor: isInvalid ? palette.danger.main : styles.borderColor,
    },
    borderColor: isInvalid ? palette.danger.main : styles.borderColor,
    boxShadow: isInvalid
      ? `0 0 0 1px ${palette.danger.main}`
      : styles.boxShadow,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 501,
  }),
  option: (
    styles: CSSProperties,
    { isSelected }: OptionProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    color: isInvalid && !isSelected ? palette.text.light : styles.color,
    cursor: 'pointer',
  }),
})

const GroupLabel = (group: { [key: string]: any }) => (
  <GroupLabelWrapper>
    <span>{group.label}</span>
    <GroupBadge>{group.options.length}</GroupBadge>
  </GroupLabelWrapper>
)

const GroupLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem 0;
  `}
`

const GroupBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    color: ${theme.palette.text.light};
    width: ${theme.spacing.gu(2.5)}rem;
    height: ${theme.spacing.gu(2.5)}rem;
    border-radius: ${theme.spacing.gu(1.25)}rem;
  `}
`

const DimensionName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.smoke.dark};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  max-width: 50%;
  min-width: 50%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
