import styled, { css } from 'styled-components/macro'

import { StatePropType } from '@/common/types'
import type { PropsWithTheme } from '@/theme'

type Props = {
  disabled?: boolean
  state?: StatePropType
}

const findColor = ({ disabled, state, theme }: PropsWithTheme<Props>): string =>
  disabled
    ? theme.palette.text.lighter
    : theme.palette.findUtilityColor(state) || theme.palette.text.light

const Label = styled.label`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
    font-family: ${theme.typography.fontFamily};
  `}

  font-weight: 600;
  color: ${(props): string => findColor(props)};
  letter-spacing: '.03rem';

  cursor: ${(props: Props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

export default Label
