import { gql, useMutation } from '@apollo/client'

import type {
  OpenTaskMutation,
  OpenTaskMutationVariables,
} from '~generated-types'

import { task } from '../fragments'

export type { OpenTaskMutation, OpenTaskMutationVariables }

export const OPEN_TASK_MUTATION = gql`
  ${task}

  mutation OpenTask($id: ID!) {
    openTask(id: $id) {
      ...Task
    }
  }
`

export function useOpenTaskMutation() {
  return useMutation<OpenTaskMutation, OpenTaskMutationVariables>(
    OPEN_TASK_MUTATION
  )
}

export default useOpenTaskMutation
