import styled from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { orderServices } from '@/modules/Order'
import { InvoiceConfig as InvoiceConfigType } from '@/modules/Order/types'

import { Visibility } from '~generated-types'

import { Section, SubTitle } from './common'

type Props = {
  config: InvoiceConfigType
  orderId: string
  ownerId: string
  readOnly: boolean
  refreshPdf: () => Promise<void>
}

export const InvoiceConfig = ({
  config: { packetProducts, participantInfo },
  orderId,
  ownerId,
  readOnly,
  refreshPdf,
}: Props) => {
  const { updateInvoice } = orderServices.invoiceService()

  const updatePacketProductsVisibility = () => {
    const input = {
      config: {
        packetProducts:
          packetProducts === Visibility.Shown
            ? Visibility.Hidden
            : Visibility.Shown,
      },
      id: ownerId,
    }

    return updateInvoice(input, orderId).then(refreshPdf)
  }

  const updateParticipantInfoVisibility = () => {
    const input = {
      config: {
        participantInfo:
          participantInfo === Visibility.Shown
            ? Visibility.Hidden
            : Visibility.Shown,
      },
      id: ownerId,
    }

    return updateInvoice(input, orderId).then(refreshPdf)
  }

  return (
    <Section>
      <SubTitle>
        <T>Orders:Documents.config.title</T>
      </SubTitle>

      <CheckboxWrapper>
        <CheckboxInput
          checked={packetProducts === Visibility.Shown}
          disabled={readOnly}
          noMargin
          onChange={updatePacketProductsVisibility}
        >
          <CheckboxLabel>
            <T>Orders:Documents.config.showPacketProducts</T>
          </CheckboxLabel>
        </CheckboxInput>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <CheckboxInput
          checked={participantInfo === Visibility.Shown}
          disabled={readOnly}
          noMargin
          onChange={updateParticipantInfoVisibility}
        >
          <CheckboxLabel>
            <T>Orders:Documents.config.showParticipantsDetails</T>
          </CheckboxLabel>
        </CheckboxInput>
      </CheckboxWrapper>
    </Section>
  )
}

/////

const CheckboxLabel = styled(FlexRow)`
  ${({ theme }) => `
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  label {
    ${({ theme }) => `
      color: ${theme.palette.text.main};
      height: ${theme.spacing.guPx(4) + 4}px;
    `}

    &:hover {
      ${({ theme }) => `
        color: ${theme.palette.primary.dark};
      `}
    }
  }
`
