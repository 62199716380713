import moment from 'moment'

import { T } from '@/modules/Language'
import { orderServices } from '@/modules/Order'
import {
  DateDurationSelector,
  Section,
  Subsection,
} from '@/modules/Order/components'
import { Offer } from '@/modules/Order/types'

import { OfferAction as OA } from '~generated-types'

type Props = {
  offer: Offer
  orderId: string
}

export const DetailsSection = ({
  offer: {
    auditLog: { createdAt },
    id,
    lifecycle: { validatedActions },
    validTo,
    validToDuration,
    validToDurationOptions,
  },
  orderId,
}: Props) => {
  const { updateOffer } = orderServices.offerService()

  const readOnly = !validatedActions.find((a) => a.action === OA.Update)?.valid

  return (
    <Section flex={1}>
      <DateDurationSelector
        date={validTo}
        duration={validToDuration}
        durationOptions={validToDurationOptions}
        readOnly={readOnly}
        type="VALID_TO"
        setDate={(validTo, validToDuration) =>
          updateOffer(id, orderId, validTo, validToDuration)
        }
      />

      <Subsection title={<T>Orders:Details.created</T>}>
        {moment(createdAt).format('dd, D.M.YYYY')}
      </Subsection>
    </Section>
  )
}
