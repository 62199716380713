import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
} from '~generated-types'

const CREATE_INVOICE_MUTATION = gql`
  ${orderFragments.invoice}

  mutation CreateInvoice($input: OrderCreateInvoiceInput!) {
    orderCreateInvoice(input: $input) {
      invoice {
        ...Invoice
      }
    }
  }
`

export const useCreateInvoiceMutation = () =>
  useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(
    CREATE_INVOICE_MUTATION
  )
