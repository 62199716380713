import { ReactNode, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalHeader,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { RadioButtonTree } from '@/components/TreeViews'
import {
  SalesDimension,
  useDimensionHierarchy,
  useSetDimensionLabelsMutation,
} from '@/modules/Dimensions'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'

import { transformHierarchy } from './utils'

type Props = {
  dimension: SalesDimension
  dimensions: SalesDimension[]
  onClose: () => void
  salesId: string
}

export const DimensionHierarchyModal = ({
  dimension,
  dimensions,
  onClose,
  salesId,
}: Props) => {
  const { palette } = useTheme()

  const { refresh } = salesHooks.useSalesDetailsContext()

  const [selectedDimensions, setSelectedDimensions] =
    useState<SalesDimension[]>(dimensions)

  const { error, hierarchy, loading } = useDimensionHierarchy({
    dimensionId: dimension.dimension.id,
  })

  const [setLabels, { loading: processing }] = useSetDimensionLabelsMutation()

  const onSave = () => {
    const update = selectedDimensions.map((d) => ({
      dimensionId: d.dimension.id,
      labelId: d.selectedLabel ? d.selectedLabel.id : null,
    }))

    setLabels({ variables: { input: { salesId, update } } })
      .then(() => {
        refresh()
        onClose()
      })
      .catch(() => undefined)
  }

  const renderContent = () => {
    // Fetching
    if (loading) {
      return (
        <Placeholder>
          <ReactLoading
            color={palette.smoke.main}
            height={24}
            type="spin"
            width={24}
          />
        </Placeholder>
      )
    }

    // Error
    if (error) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.errorLabels</T>
        </Placeholder>
      )
    }

    // Empty
    if (!hierarchy) {
      return (
        <Placeholder>
          <T>SalesDetails:dimensions.error.emptyLabels</T>
        </Placeholder>
      )
    }

    // Idle
    if (hierarchy) {
      return (
        <RadioButtonTreeWrapper>
          <RadioButtonTree
            items={transformHierarchy(hierarchy, dimensions)}
            selectedItems={selectedDimensions}
            setSelectedItems={setSelectedDimensions}
          />
        </RadioButtonTreeWrapper>
      )
    }
  }

  const canSave = !!hierarchy || !loading || !processing

  return (
    <InlineModal style={{ maxHeight: 500, minWidth: 450 }}>
      <InlineModalHeader onClose={onClose} title={hierarchy?.name} />

      <InlineModalContent>{renderContent()}</InlineModalContent>

      <InlineModalFooter justifyContent="center">
        <InnocuousButton disabled={!canSave} onClick={() => onSave()}>
          <PrimaryColor>
            {loading ? (
              <ReactLoading
                color={palette.primary.light}
                height={24}
                type="spin"
                width={24}
              />
            ) : (
              <T>common:action.save</T>
            )}
          </PrimaryColor>
        </InnocuousButton>
      </InlineModalFooter>
    </InlineModal>
  )
}

//////

type PlaceholderProps = {
  children: ReactNode
}

const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <FlexRow justifyContent="center">
      <PlaceholderLabel>{children}</PlaceholderLabel>
    </FlexRow>
  </Gutter>
)

const PlaceholderLabel = styled.div`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const RadioButtonTreeWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterBig};
  `}
`
