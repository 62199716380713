import { Ref, useState } from 'react'
import moment from 'moment'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import { SalesType } from '~generated-types'

import { NewMealModal } from './NewMealModal'

export const NewMealButton = () => {
  const { spacing } = useTheme()
  const { data, saleReadOnly } = useSalesDetailsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      placement="left"
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      modal={
        <NewMealModal
          defaultDate={moment().format('YYYY-MM-DD')}
          onClose={() => setModalOpen(false)}
        />
      }
      referenceElement={({ ref }) => (
        <InnocuousButton
          compact
          innerRef={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setModalOpen(true)}
          disabled={saleReadOnly || data.type === SalesType.Event}
          style={{ marginLeft: spacing.gutter }}
        >
          <PrimaryColor>
            + <T>SalesDetails:meals.action.addNew</T>
          </PrimaryColor>
        </InnocuousButton>
      )}
    />
  )
}
