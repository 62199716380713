import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  SetOrderCustomerMutation,
  SetOrderCustomerMutationVariables,
} from '~generated-types'

const SET_ORDER_CUSTOMER_MUTATION = gql`
  ${orderFragments.order}

  mutation SetOrderCustomer($input: OrderSetCustomerInput!) {
    orderSetCustomer(input: $input) {
      order {
        ...Order
      }
    }
  }
`

export const useSetOrderCustomerMutation = () =>
  useMutation<SetOrderCustomerMutation, SetOrderCustomerMutationVariables>(
    SET_ORDER_CUSTOMER_MUTATION
  )
