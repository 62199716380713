import { useEffect, useState } from 'react'
import {
  ApolloQueryResult,
  useApolloClient,
  useSubscription,
} from '@apollo/client'

import { FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { orderContexts, orderSubscriptions } from '@/modules/Order'
import {
  Customer,
  Document,
  DocumentEditor,
  OrderItems,
  Payments,
  SectionSeparator,
} from '@/modules/Order/components'
import {
  Document as DocumentType,
  Invoice,
  InvoiceByIdPayload,
  OrderType as Order,
  PaymentSubscription,
  PaymentSubscriptionVariables,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'

import { InvoiceAction as IA, PaymentState as PS } from '~generated-types'

import { updateDocument, updatePayment } from '../../utils'
import { generateCostingExcel } from './Sections/ActionsSection/Excel'
import { ActionsSection, DetailsSection } from './Sections'

type Props = {
  invoice: Invoice
  isVisible: boolean
  openPaymentsModal: () => void
  order: Order
  refetch: () => Promise<ApolloQueryResult<InvoiceByIdPayload>>
}

export const Content = ({
  invoice,
  isVisible,
  openPaymentsModal,
  order: { customer: orderCustomer, id: orderId, sales, seller },
  refetch,
}: Props) => {
  const {
    config,
    customer,
    document: invoiceDocument,
    freeText,
    id,
    lifecycle: { validatedActions },
    paymentInfo: {
      payableAmount,
      totalPrice: { amountVatIncluded: totalAmount },
    },
    payments,
    type,
  } = invoice

  const client = useApolloClient()
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const { setOrdersById } = salesHooks.useSalesDetailsContext()
  const { setObservablePayment } = orderContexts.useObservablePayment()

  const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false)
  const [isSubscriptionPendingCompleted, setSubscriptionPendingCompleted] =
    useState<boolean>(false)

  // This useEffect is needed to wait for a response from a invoice subscription within 1 second after creating / removing a payment
  useEffect(() => {
    const timer = setTimeout(() => setSubscriptionPendingCompleted(true), 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [JSON.stringify(payments)])

  // This useEffect is needed to manually refetch the invoice data if the invoice subscription didn't respond
  useEffect(() => {
    if (isSubscriptionPendingCompleted) {
      const paidPaymentsAmount = payments.reduce(
        (totalAmount: number, { amount, state }) => {
          if (state === PS.Paid || state === PS.PaidPending) {
            totalAmount += amount
          }

          return totalAmount
        },
        0
      )

      if (totalAmount !== paidPaymentsAmount + payableAmount) {
        refetch()
      }

      setSubscriptionPendingCompleted(false)
    }
  }, [isSubscriptionPendingCompleted])

  // This useEffect is needed in order to show "IN_PROGRESS" payment in the payment modal when:
  // - User refreshed the page and opened the payment modal again
  // - Another user opened the modal payment window in the same invoice
  useEffect(() => {
    const paymentInProgress =
      payments.find(({ state }) => state === PS.InProgress) ?? null

    setObservablePayment((prevPayment) => prevPayment ?? paymentInProgress)
  }, [JSON.stringify(payments)])

  useSubscription<PaymentSubscription, PaymentSubscriptionVariables>(
    orderSubscriptions.PAYMENT,
    {
      onData({ data: { data } }) {
        if (data) {
          updatePayment(data, setOrdersById, setObservablePayment)
        }
      },
      skip: !isVisible,
      variables: { input: { orderId } },
    }
  )

  const readOnly = !validatedActions.find((a) => a.action === IA.Update)?.valid

  const paidPayments = payments.filter(
    ({ state }) => state === PS.Paid || state === PS.PaidPending
  )

  const onUpdateDocument = (document: DocumentType) =>
    updateDocument(document, orderId, id, type, setOrdersById)

  const loadCosting = () =>
    generateCostingExcel({
      client,
      invoiceId: id,
      palette,
      translateFn: (keys) => translate(`Orders:${keys}`, language),
    })

  return (
    <>
      <ActionsSection
        invoice={invoice}
        openDocumentModal={() => setDocumentModalOpen(true)}
        openPaymentsModal={openPaymentsModal}
        orderId={orderId}
        loadCosting={loadCosting}
      />

      <SectionSeparator />

      <FlexRow>
        <DetailsSection
          invoice={invoice}
          orderId={orderId}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Customer
          customer={customer}
          orderCustomer={orderCustomer}
          orderId={orderId}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Document
          document={invoiceDocument}
          freeText={freeText}
          isVisible={isVisible}
          onUpdateDocument={onUpdateDocument}
          orderId={orderId}
          ownerId={id}
          ownerType={type}
          readOnly={readOnly}
          refetch={refetch}
        />
      </FlexRow>

      {!!paidPayments.length && (
        <>
          <SectionSeparator />

          <Payments
            invoiceId={id}
            openPaymentsModal={openPaymentsModal}
            orderId={orderId}
            payments={paidPayments}
          />
        </>
      )}

      <SectionSeparator />

      <OrderItems
        invoice={invoice}
        readOnly={readOnly}
        refetch={refetch}
        sales={sales}
        sellerId={seller?.id}
      />

      {isDocumentModalOpen && invoiceDocument && (
        <DocumentEditor
          config={config}
          document={invoiceDocument}
          freeText={freeText}
          onClose={() => setDocumentModalOpen(false)}
          onUpdateDocument={onUpdateDocument}
          orderId={orderId}
          ownerId={id}
          readOnly={readOnly}
        />
      )}
    </>
  )
}
