import { useQuery } from '@apollo/client'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import BASKET_QUERY from '@/components/Basket/Basket.query'
import { Chip } from '@/components/Chip'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ListItem as Wrapper,
  ListItemSection as Section,
  ListItemSectionIconValue as IconValue,
} from '@/components/List'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SaleLastUsedQuery, SaleLastUsedQueryVariables } from '~generated-types'

type Props = {
  id: string
  onClick: () => void
}

export const SaleCard = ({ id, onClick }: Props) => {
  const theme = useTheme()

  const DATE_FORMAT = 'dd DD.MM.YY'

  const { data } = useQuery<SaleLastUsedQuery, SaleLastUsedQueryVariables>(
    BASKET_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    }
  )

  const sale = data?.sales

  const customer = sale?.customer?.customer

  const customerName = !customer
    ? ''
    : customer.__typename === 'CustomerOrganization'
    ? customer.organization.name
    : customer.__typename === 'CustomerPerson'
    ? `${customer.person.firstName} ${customer.person.lastName}`
    : ''
  const customerNumber = customer?.customerNumber || null

  return (
    <Wrapper onClick={onClick}>
      {!!sale && (
        <>
          <Section
            style={{ padding: 0, paddingLeft: `${theme.spacing.gu(1)}rem` }}
            align="center"
          >
            <Chip
              variant="outlined"
              size="sm"
              color={getColorForState(sale.lifecycle.state)}
            >
              <T>{`SalesDetails:Lifecycle.state.${sale.lifecycle.state}`}</T>
            </Chip>
          </Section>

          <Section align="center">
            <FlexRow alignItems="center" justifyContent="space-between">
              <FlexColumn
                alignItems="flex-start"
                noPadding
                style={{ marginRight: theme.spacing.gutter }}
              >
                {sale.name ? (
                  <Title>{sale.name}</Title>
                ) : (
                  <TitlePlaceholder>
                    <T l10n={`SalesSearchList:ListItem.unnamed.${sale.type}`} />
                  </TitlePlaceholder>
                )}
                <small>#{sale.orderNumber}</small>
              </FlexColumn>
              <Chip
                size="sm"
                variant="outlined"
                customColor={{
                  fill: sale.facet.color,
                  stroke: sale.facet.color,
                }}
                style={{
                  justifyContent: 'center',
                  width: `${theme.spacing.gu(7)}rem`,
                }}
              >
                {sale.facet.abbreviation}
              </Chip>
            </FlexRow>
          </Section>

          <Section>
            {customerName && customerNumber ? (
              <FlexColumn noPadding>
                <span>{customerName}</span>
                <small>#{customerNumber}</small>
              </FlexColumn>
            ) : null}
          </Section>

          <Section>
            {sale.estimatedDates ? (
              <>
                <IconValue
                  icon="right-to-bracket"
                  value={moment(sale.estimatedDates.start).format(DATE_FORMAT)}
                />
                <IconValue
                  icon="right-from-bracket"
                  value={moment(sale.estimatedDates.end).format(DATE_FORMAT)}
                />
              </>
            ) : null}
          </Section>
        </>
      )}
    </Wrapper>
  )
}

const getColorForState = (state: string) => {
  switch (state) {
    case 'CANCELLED':
    case 'CLOSED':
      return 'danger'
    case 'DRAFT':
    case 'TEMPORAL':
      return 'default'
    default:
      return 'primary'
  }
}

const Title = styled.a`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const TitlePlaceholder = styled.a`
  &&& {
    font-style: italic;
    font-weight: 400;

    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }
`
