import { useRoomReservationDelete } from '@/modules/Accommodation/SalesReservationManager/mutations'
import { useRoomReservationSetNeeds } from '@/modules/Accommodation/SalesReservationManager/mutations'
import {
  useSalesParticipantAddRoom,
  useSalesParticipantCreate,
  useSalesParticipantDelete,
  useSalesParticipantDeleteRoom,
  useSalesParticipantMassCreate,
  useSalesParticipantMoveToSales,
  useSalesParticipantSetAccommodation,
  useSalesParticipantSetRoomFriends,
  useSalesParticipantSetVisitStatus,
  useSalesParticipantUpdate,
  useServiceDelete,
  useServiceSetDates,
  useSetCheckIn,
  useSetCheckOut,
  useTargetParticipantRoom,
  useVSTSetAttributes,
  useVSTSetDates,
  useVSTSetDatesDefault,
} from '@/modules/ParticipantsList/mutations'
// Mutations & queries
import {
  useAddPurchaseFromCatalogProductMutation,
  useAddPurchaseFromSalesProductMutation,
  useAddPurchasesFromSalesProductMutation,
  useDeletePurchaseMutation,
  useDeletePurchasesMutation,
} from '@/modules/Products/mutations'
import { salesHooks } from '@/modules/Sales'
import { useEnrollmentsContext } from '@/modules/Sales/components/SalesDetails/routes/Enrollments/EnrollmentsContext'

import {
  ParticipantVstSetAttributesInput,
  ParticipantVstSetDatesDefaultInput,
  ParticipantVstSetDatesInput,
  PurchaseProductAddFromCatalogProductInput,
  PurchaseProductAddFromSalesProductInput,
  SalesParticipantCreateInput,
  SalesParticipantSetAccommodationInput,
  SalesParticipantSetRoomFriendsInput,
  SalesParticipantSetVisitStatusInput,
} from '~generated-types'

import ParticipantsListQueries from '../ParticipantsList.queries'
import {
  AccommodationRoomReservationSetNeedsInput,
  AddRoomInput,
  SetDatesInput,
  TargetRoomInput,
  UpdateParticipantInput,
} from '../ParticipantsList.types'

export const useSaleParticipants = () => {
  const { refetchAccommodation } = salesHooks.useCompactViewContext()
  const { inEnrollmentsContext, refresh: refetchEnrollments } =
    useEnrollmentsContext()

  const [setVSTAttributes] = useVSTSetAttributes()
  const [setVSTDates] = useVSTSetDates()
  const [setVSTDatesDefault] = useVSTSetDatesDefault()

  const [createParticipant] = useSalesParticipantCreate()
  const [createParticipants] = useSalesParticipantMassCreate()
  const [moveParticipant] = useSalesParticipantMoveToSales()
  const [updateParticipant] = useSalesParticipantUpdate()
  const [setRoomFriends] = useSalesParticipantSetRoomFriends()
  const [removeParticipant] = useSalesParticipantDelete()

  const [addCatalogProduct] = useAddPurchaseFromCatalogProductMutation()
  const [addSalesProduct] = useAddPurchaseFromSalesProductMutation()
  const [addSalesProducts] = useAddPurchasesFromSalesProductMutation()
  const [removePurchase] = useDeletePurchaseMutation()
  const [removePurchases] = useDeletePurchasesMutation()

  const [createRoomReservation] = useSalesParticipantAddRoom()
  const [removeRoomReservation] = useSalesParticipantDeleteRoom()

  const [setCheckIn] = useSetCheckIn()
  const [setCheckOut] = useSetCheckOut()

  const [onSetRoomNeeds] = useRoomReservationSetNeeds()

  const [setServiceTarget] = useSalesParticipantSetAccommodation()
  const [serviceSetDates] = useServiceSetDates()
  const [targetRoom] = useTargetParticipantRoom()
  const [deleteService] = useServiceDelete()
  const [setVisitStatus] = useSalesParticipantSetVisitStatus()

  const [removeRoom] = useRoomReservationDelete()

  const handleAddParticipant = (input: SalesParticipantCreateInput) =>
    createParticipant({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.salesParticipantCreate
      })
      .catch(() => undefined)

  const handleAddParticipants = (input: SalesParticipantCreateInput[]) =>
    createParticipants({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.salesParticipantMassCreate
      })
      .catch(() => undefined)

  const handleRemoveParticipant = (id: string) =>
    removeParticipant({
      variables: {
        input: {
          id,
        },
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        refetchAccommodation && refetchAccommodation()
        return data?.salesParticipantDelete
      })
      .catch(() => undefined)

  const handleMoveParticipant = (
    id: string,
    targetSalesId: string,
    salesId: string
  ) =>
    moveParticipant({
      refetchQueries: [
        {
          query: ParticipantsListQueries.SALE_AND_ENROLLMENTS_BASE,
          variables: {
            id: salesId,
          },
        },
      ],
      variables: {
        input: {
          id,
          targetSalesId,
        },
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.salesParticipantMoveToSales
      })
      .catch(() => undefined)

  const handleChangeParticipantData = (input: UpdateParticipantInput) =>
    updateParticipant({
      variables: {
        input,
      },
    })
      .then(({ data }) => data?.salesParticipantUpdate)
      .catch(() => undefined)

  const handleAddRoom = (input: AddRoomInput) =>
    createRoomReservation({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        refetchAccommodation && refetchAccommodation()
        return data?.salesParticipantAddRoom
      })
      .catch(() => undefined)

  const handleRemoveRoom = (participantRoomReservationId: string) =>
    removeRoomReservation({
      variables: {
        input: {
          participantRoomReservationId,
        },
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        refetchAccommodation && refetchAccommodation()
        return data?.salesParticipantDeleteRoom
      })
      .catch(() => undefined)

  const handleAddCatalogProduct = (
    input: PurchaseProductAddFromCatalogProductInput
  ) =>
    addCatalogProduct({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.purchaseProductAddFromCatalogProduct
      })
      .catch(() => undefined)

  const handleAddSalesProduct = (
    input: PurchaseProductAddFromSalesProductInput
  ) =>
    addSalesProduct({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.purchaseProductAddFromSalesProduct
      })
      .catch(() => undefined)

  const handleAddSalesProducts = (
    input: PurchaseProductAddFromSalesProductInput[]
  ) =>
    addSalesProducts({
      variables: {
        input,
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.purchaseProductMassAdd
      })
      .catch(() => undefined)

  const handleSetCheckIn = (
    roomReservationId: string,
    timestamp: string | null | undefined
  ) =>
    setCheckIn({
      variables: {
        input: {
          participantRoomReservationIds: [roomReservationId],
          timestamp,
        },
      },
    })
      .then(({ data }) => data?.salesParticipantCheckIn)
      .catch(() => undefined)

  const handleSetCheckOut = (
    roomReservationId: string,
    timestamp: string | null | undefined
  ) =>
    setCheckOut({
      variables: {
        input: {
          participantRoomReservationIds: [roomReservationId],
          timestamp,
        },
      },
    })
      .then(({ data }) => data?.salesParticipantCheckOut)
      .catch(() => undefined)

  const handleSetRoomNeeds = (
    input: AccommodationRoomReservationSetNeedsInput
  ) =>
    onSetRoomNeeds({
      variables: {
        input,
      },
    })
      .then(({ data }) => data?.accommodationRoomReservationSetNeeds)
      .catch(() => undefined)

  const handleSetServiceTarget = (
    input: SalesParticipantSetAccommodationInput
  ) =>
    setServiceTarget({
      variables: {
        input,
      },
    })
      .then(({ data }) => data?.salesParticipantSetAccommodation)
      .catch(() => undefined)

  const handleServiceSetDates = (input: SetDatesInput) =>
    serviceSetDates({
      variables: {
        input,
      },
    })
      .then(({ data }) => data?.serviceSetDates)
      .catch(() => undefined)

  const handleTargetRoom = (input: TargetRoomInput) =>
    targetRoom({
      variables: {
        input,
      },
    })
      .then(({ data }) => data?.accommodationRoomTarget)
      .catch(() => undefined)

  const handleDeleteService = (id: string) =>
    deleteService({
      variables: {
        id,
      },
    })
      .then(({ data }) => data?.serviceDelete)
      .catch(() => undefined)

  const handleDeleteRoom = (id: string) =>
    removeRoom({
      variables: {
        input: {
          id,
        },
      },
    })
      .then(({ data }) => data?.accommodationRoomReservationDelete)
      .catch(() => undefined)

  const handleSetVisitStatus = (input: SalesParticipantSetVisitStatusInput) =>
    setVisitStatus({
      variables: {
        input,
      },
    })
      .then(({ data }) => data?.salesParticipantSetVisitStatus)
      .catch(() => undefined)

  const handleRemovePurchase = (id: string) =>
    removePurchase({
      variables: { id },
    })
      .then(({ data }) => data?.purchaseProductDelete)
      .catch(() => undefined)

  const handleRemovePurchases = (purchaseIds: string[]) =>
    removePurchases({
      variables: {
        input: {
          purchaseIds,
        },
      },
    })
      .then(({ data }) => {
        inEnrollmentsContext && refetchEnrollments()
        return data?.purchaseProductMassDelete
      })
      .catch(() => undefined)

  const handleSetRoomFriends = (input: SalesParticipantSetRoomFriendsInput) =>
    setRoomFriends({
      variables: { input },
    })
      .then(({ data }) => data?.salesParticipantSetRoomFriends)
      .catch(() => undefined)

  const handleSetVSTAttributes = (input: ParticipantVstSetAttributesInput) =>
    setVSTAttributes({
      variables: { input },
    })
      .then(({ data }) => data?.participantVstSetAttributes)
      .catch(() => undefined)

  const handleSetVSTDates = (input: ParticipantVstSetDatesInput) =>
    setVSTDates({
      variables: { input },
    })
      .then(({ data }) => data?.participantVstSetDates)
      .catch(() => undefined)

  const handleSetVSTDatesDefault = (
    input: ParticipantVstSetDatesDefaultInput
  ) =>
    setVSTDatesDefault({
      variables: { input },
    })
      .then(({ data }) => data?.participantVstSetDatesDefault)
      .catch(() => undefined)

  return {
    handleAddCatalogProduct,
    handleAddParticipant,
    handleAddParticipants,
    handleAddRoom,
    handleAddSalesProduct,
    handleAddSalesProducts,
    handleChangeParticipantData,
    handleDeleteRoom,
    handleDeleteService,
    handleMoveParticipant,
    handleRemoveParticipant,
    handleRemovePurchase,
    handleRemovePurchases,
    handleRemoveRoom,
    handleServiceSetDates,
    handleSetCheckIn,
    handleSetCheckOut,
    handleSetRoomFriends,
    handleSetRoomNeeds,
    handleSetServiceTarget,
    handleSetVisitStatus,
    handleSetVSTAttributes,
    handleSetVSTDates,
    handleSetVSTDatesDefault,
    handleTargetRoom,
  }
}
