import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components/macro'

import { StatePropType } from '@/common/types'
import { LightColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Label, RadioInput, Textarea } from '@/components/FormControls'
import {
  InlineModal,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
  InlineModalSection,
} from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import {
  useAcceptSaleMutation,
  useCancelSaleMutation,
  useCloseSaleMutation,
  useConfirmSaleMutation,
  useDeleteSaleMutation,
  useOpenSaleMutation,
  useReOpenSaleMutation,
  useSaleCancelProcessMutation,
} from '@/modules/Sales/mutations'
import { Palette, useTheme } from '@/theme'

import {
  SalesAction,
  SalesValidatedActionsQuery,
  SalesValidatedActionsQueryVariables,
} from '~generated-types'

import ValidatedActions from './ValidatedActions.query'

type Props = {
  salesId: string
  specifier?: string | null
  onClose: () => void
}

const SaleStateModal = ({ salesId, specifier, onClose }: Props) => {
  const { palette, spacing } = useTheme()

  const { confirm } = useDialogService()
  const history = useHistory()

  const { data: validatedActionsData, loading } = useQuery<
    SalesValidatedActionsQuery,
    SalesValidatedActionsQueryVariables
  >(ValidatedActions, {
    variables: {
      id: salesId,
    },
  })

  const validatedActions =
    validatedActionsData?.sales?.lifecycle.validatedActions

  const [selectedAction, selectAction] = useState<SalesAction | null>(null)
  const [cancelSpecifier, setSpecifier] = useState<string | undefined>(
    undefined
  )

  const [handleAccept] = useAcceptSaleMutation()
  const [handleCancelSale] = useCancelSaleMutation()
  const [handleCancelProcessSale] = useSaleCancelProcessMutation()
  const [handleCloseSale] = useCloseSaleMutation()
  const [handleOpenSale] = useOpenSaleMutation()
  const [handleReOpenSale] = useReOpenSaleMutation()
  const [handleConfirmSale] = useConfirmSaleMutation()
  const [handleDeleteSale] = useDeleteSaleMutation()

  const variables = Object.freeze({
    variables: { input: { salesId, specifier: cancelSpecifier } },
  })

  const actionHandlers: {
    [action in SalesAction]: () => Promise<any>
  } = {
    ACCEPT: () => handleAccept(variables),
    CANCEL: () => handleCancelSale(variables),
    CANCEL_PROCESS: () => handleCancelProcessSale(variables),
    CLOSE: () => handleCloseSale(variables),
    CONFIRM: () => handleConfirmSale(variables),
    DELETE: () => handleDeleteSale(variables),
    OPEN: () => handleOpenSale(variables),
    RE_OPEN: () => handleReOpenSale(variables),
  }

  const updateWithConfirmation = () => {
    selectedAction === 'DELETE' || selectedAction === 'CANCEL'
      ? confirm({
          cancelLabel: <T>common:action.cancel</T>,
          confirmLabel: <T>common:action.continue</T>,
          description: (
            <FlexColumn noPadding>
              <DescriptionLabel normal>
                <T>SalesDetails:Lifecycle.common.areYouSure</T>
              </DescriptionLabel>
              <DescriptionLabel>
                <T>{`SalesDetails:Lifecycle.description.${selectedAction}`}</T>
              </DescriptionLabel>
            </FlexColumn>
          ),
          title: (
            <T>{`SalesDetails:Lifecycle.action.full.${selectedAction}`}</T>
          ),
        })
          .then(actionHandlers[selectedAction])
          .then((res) => {
            onClose()

            const openSalesList = () => {
              history.replace('/search/sales')
            }

            res.data?.salesDelete?.deleted && openSalesList()
          })
          .catch(() => null)
      : selectedAction && actionHandlers[selectedAction]().then(onClose)
  }

  const handleSelectAction = (action: SalesAction) => {
    action !== 'CANCEL' && setSpecifier(undefined)
    selectAction(action)
  }

  return (
    <InlineModal style={{ width: '400px' }}>
      <InlineModalHeader
        title={
          <ShiftLeft>
            <T>SalesDetails:Lifecycle.common.saleState</T>
          </ShiftLeft>
        }
        onClose={onClose}
      />
      {selectedAction === 'CANCEL' && (
        <>
          <InlineModalLine style={{ margin: 0 }} />
          <TextareaWrapper>
            <Label>
              <T>SalesDetails:Lifecycle.cancelationSpecifier</T>:
            </Label>
            <Textarea
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setSpecifier(e.target.value)
              }
              autoFocus
              value={cancelSpecifier || ''}
            />
          </TextareaWrapper>
        </>
      )}
      {specifier && (
        <>
          <InlineModalLine style={{ margin: 0 }} />
          <SpecifierWrapper>
            <SpecifierIconWrapper>
              <FontAwesomeIcon
                fixedWidth
                size="sm"
                icon="info"
                color={palette.white}
              />
            </SpecifierIconWrapper>
            <FontWeight style={{ lineHeight: 'initial' }}>
              {specifier}
            </FontWeight>
          </SpecifierWrapper>
        </>
      )}
      <InlineModalLine style={{ margin: 0 }} />
      <InlineModalSection>
        <FlexColumn
          noPadding
          style={{ marginBottom: loading ? spacing.gutter : 0 }}
        >
          {loading ? (
            <ReactLoading
              type="spin"
              color={palette.smoke.main}
              height={24}
              width={24}
            />
          ) : (
            validatedActions &&
            validatedActions.map(({ action, valid, issues }) => (
              <OptionWrapper key={action} alignItems="center">
                <RadioInput
                  key={`input-${action}`}
                  noMargin
                  disabled={!valid}
                  state={getColorForAction(action, palette).color}
                  checked={action === selectedAction}
                  onChange={() => handleSelectAction(action)}
                  style={{
                    minWidth: `${spacing.gu(20)}rem`,
                    paddingRight: spacing.gutter,
                  }}
                >
                  <OptionLabel
                    disabled={!valid}
                    color={getColorForAction(action, palette).code}
                  >
                    <T>{`SalesDetails:Lifecycle.action.${action}`}</T>
                  </OptionLabel>
                </RadioInput>
                <FlexColumn alignItems="flex-start">
                  {valid ? (
                    <FontWeight light>
                      <T>{`SalesDetails:Lifecycle.description.${action}`}</T>
                    </FontWeight>
                  ) : (
                    issues.map(({ code, key, level }) => (
                      <FontWeight
                        key={key}
                        style={{ padding: `${spacing.gutterSmall} 0` }}
                      >
                        <InfoIconWrapper>
                          {level === 'INFO' ? (
                            <FontAwesomeIcon
                              icon="circle-info"
                              color={palette.primary.main}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon="triangle-exclamation"
                              color={
                                level === 'ERROR'
                                  ? palette.danger.main
                                  : palette.warning.dark
                              }
                            />
                          )}
                        </InfoIconWrapper>
                        <T>{`IssueCodes:${code}`}</T>
                      </FontWeight>
                    ))
                  )}
                </FlexColumn>
              </OptionWrapper>
            ))
          )}
        </FlexColumn>
      </InlineModalSection>
      <InlineModalFooter justifyContent="flex-end">
        <InnocuousButton
          disabled={selectedAction === null}
          onClick={updateWithConfirmation}
        >
          <span
            style={{ color: getColorForAction(selectedAction, palette).code }}
          >
            {selectedAction ? (
              <T>{`SalesDetails:Lifecycle.action.full.${selectedAction}`}</T>
            ) : (
              <T>SalesDetails:Lifecycle.common.changeState</T>
            )}
          </span>
        </InnocuousButton>
        <InnocuousButton onClick={onClose}>
          <LightColor>
            <T>common:action.cancel</T>
          </LightColor>
        </InnocuousButton>
      </InlineModalFooter>
    </InlineModal>
  )
}

export default SaleStateModal

const getColorForAction = (
  action: SalesAction | null,
  palette: Palette
): {
  code: string
  color: StatePropType | undefined
} => {
  switch (action) {
    case 'CANCEL':
    case 'CANCEL_PROCESS':
    case 'DELETE':
      return { code: palette.danger.main, color: 'danger' }
    case 'CLOSE':
      return { code: palette.success.main, color: 'success' }
    default:
      return { code: palette.primary.main, color: undefined }
  }
}

const OptionWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const OptionLabel = styled.span<{ color: string; disabled: boolean }>`
  font-weight: 600;
  color: ${({ color, theme }) => color || theme.palette.primary.main};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`

const DescriptionLabel = styled(FontWeight)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const InfoIconWrapper = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const TextareaWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gu(3)}rem;
  `}

  & span {
    width: 100%;

    ${({ theme }) => css`
      margin-top: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const SpecifierWrapper = styled(FlexRow)`
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem;
  `}
`

const SpecifierIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 20px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.primary.main};
  `}
`
