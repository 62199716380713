import { Ref, useState } from 'react'
import { useMutation } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontColor } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'

import { useSalesDetailsContext } from '../../..'
import { GroupManager } from './GroupManager'
import { SetSalesGroupMutation } from './SetSalesGroup.mutation'

export type GroupType = {
  label: string
  values: string[]
}

export const Group = () => {
  const { language } = useLanguageContext()
  const {
    data: { groups: salesGroups, id },
    saleReadOnly: readOnly,
  } = useSalesDetailsContext()

  const [handleSetGroup] = useMutation(SetSalesGroupMutation)

  const [groups, setGroups] = useState<GroupType>({
    label: salesGroups?.label || '',
    values: salesGroups?.values || [],
  })
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const onSetGroup = (groupField: Partial<GroupType>) =>
    handleSetGroup({
      variables: {
        input: {
          groups: { ...groups, ...groupField },
          id,
        },
      },
    })
      .then(() => setGroups({ ...groups, ...groupField }))
      .catch(() => undefined)

  return (
    <Wrapper flex={1.5} noPadding>
      <Label>
        <T>SalesDetails:Settings.group.title</T>
      </Label>
      <FlexRow>
        <ModalContainer
          isOpen={isModalOpen}
          modal={
            <GroupManager
              group={groups}
              onClose={() => setModalOpen(false)}
              setGroup={onSetGroup}
            />
          }
          onClose={() => setModalOpen(false)}
          referenceElement={({ ref }) => (
            <EditButton
              disabled={readOnly}
              innerRef={ref as Ref<HTMLButtonElement>}
              onClick={() => setModalOpen(true)}
            >
              <div>
                {groups.label || (
                  <T>SalesDetails:Settings.group.labelPlaceholder</T>
                )}
                <FontColor lighter>
                  {' | '}
                  {translate('SalesDetails:Settings.group.items', language, {
                    count: groups.values.length,
                  })}
                </FontColor>
              </div>
            </EditButton>
          )}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />
      </FlexRow>
    </Wrapper>
  )
}

/////

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`
