import { useEffect, useMemo, useRef, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Order, orderServices } from '@/modules/Order'
import { OrderType } from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import { useIsVisible } from '../../utils'

const SECTION: SectionFeatures = {
  icon: 'euro-sign',
  key: 'orders',
}

const OrdersComponent = () => {
  const theme = useTheme()

  const previewRef = useRef<HTMLDivElement | null>(null)

  const { createOrder } = orderServices.orderService()

  const {
    data: { id: salesId },
    ordersById,
    ordersError,
    ordersLoading,
    scrollToComponent,
    saleReadOnly,
    setScrollToComponent,
  } = salesHooks.useSalesDetailsContext()

  const [processing, setProcessing] = useState<boolean>(false)

  const orderList = useMemo(
    () =>
      [...Object.values(ordersById)].sort(
        generateCompareFn('orderNumber', true)
      ),
    [ordersById]
  )

  const isPreviewVisible = useIsVisible(previewRef)

  const handleCreateOrder = () => {
    setProcessing(true)

    createOrder(salesId).finally(() => setProcessing(false))
  }

  useEffect(() => {
    !isPreviewVisible &&
      scrollToComponent === 'order-preview' &&
      previewRef.current &&
      previewRef.current.scrollIntoView({
        behavior: 'smooth',
      })

    setScrollToComponent(null)
  }, [scrollToComponent])

  const renderCreateOrderButton = () => (
    <InnocuousButton
      compact
      disabled={saleReadOnly || processing}
      onClick={handleCreateOrder}
    >
      <PrimaryColor>
        + <T>Orders:Action.ORDER.CREATE</T>
      </PrimaryColor>
    </InnocuousButton>
  )

  return (
    <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
      {ordersLoading ? (
        <ReactLoading
          type="spin"
          height={28}
          width={28}
          color={theme.palette.smoke.main}
        />
      ) : ordersError ? (
        <Gutter type={[1, 3]}>
          <Placeholder>
            <T>Orders:OrdersForOwner.error</T>
          </Placeholder>
        </Gutter>
      ) : orderList?.length ? (
        <>
          <FlexRow
            style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}
            justifyContent="flex-end"
          >
            {renderCreateOrderButton()}
          </FlexRow>

          {orderList.map((order: OrderType, idx: number) => (
            <div key={order.id} ref={previewRef}>
              {!!idx && <Spacer />}
              <Order order={order} />
            </div>
          ))}
        </>
      ) : (
        <FlexRow justifyContent="space-between">
          <Gutter type={[1, 3]}>
            <Placeholder>
              <T>Orders:OrdersForOwner.empty</T>
            </Placeholder>
          </Gutter>

          {renderCreateOrderButton()}
        </FlexRow>
      )}
    </SectionContainer>
  )
}

export const Orders = Object.freeze({
  component: OrdersComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})

///////

const Placeholder = styled.span`
  font-style: italic;
  font-weight: 300;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Spacer = styled.span`
  display: block;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
  `}
`
