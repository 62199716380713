import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { useSalesDetailsContext } from '../../../'
import { HeaderValue } from './HeaderValue'

export const AccommodationSummary = () => {
  const theme = useTheme()

  const {
    data: {
      accommodation: { accommodationGroups },
    },
  } = useSalesDetailsContext()

  const { beds, extraBeds, rooms } =
    getAccommodationSummary(accommodationGroups)

  return (
    <Wrapper alignItems="flex-start" flex={1}>
      <ValueWrapper>
        <FontAwesomeIcon
          fixedWidth
          size="sm"
          color={theme.palette.text.light}
          icon="door-open"
          style={{ marginRight: theme.spacing.gutter }}
        />
        <HeaderValue>
          {rooms} <T>enums:quantityUnit.abbrev.PIECE</T>
        </HeaderValue>
      </ValueWrapper>

      <ValueWrapper>
        <FontAwesomeIcon
          fixedWidth
          size="sm"
          color={theme.palette.text.light}
          icon="bed"
          style={{ marginRight: theme.spacing.gutter }}
        />
        <HeaderValue>
          {beds} + {extraBeds} <T>enums:quantityUnit.abbrev.PIECE</T>
        </HeaderValue>
      </ValueWrapper>
    </Wrapper>
  )
}

////////////

const getAccommodationSummary = (accommodationGroups: any) =>
  accommodationGroups.reduce(
    (acc: any, group: any) => ({
      beds: acc.beds + group.status.reservedDetailed.beds,
      extraBeds: acc.extraBeds + group.status.reservedDetailed.extraBeds,
      rooms: acc.rooms + group.status.reservedDetailed.rooms,
    }),
    {
      beds: 0,
      extraBeds: 0,
      rooms: 0,
    }
  )

const ValueWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} 0;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`
