// Common mutations
// Invoice mutations
import { useAcceptInvoiceMutation } from './acceptInvoice'
// Payment mutations
import { useAddCashPaymentMutation } from './addCashPayment'
import { useAddCreditCardPaymentMutation } from './addCreditCardPayment'
import { useAddGiftCardPaymentMutation } from './addGiftCardPayment'
import { useAddOtherPaymentMutation } from './addOtherPayment'
import { useAddReferencePaymentMutation } from './addReferencePayment'
import { useAddVoucherPaymentMutation } from './addVoucherPayments'
import { useCancelExternalPaymentMutation } from './cancelExternalPayment'
import { useCreateAdvanceMutation } from './createAdvance'
// Confirmation mutations
import { useCreateConfirmationMutation } from './createConfirmation'
import { useCreateInvoiceMutation } from './createInvoice'
// Offer mutations
import { useCreateOfferMutation } from './createOffer'
// Order mutations
import { useCreateOrderMutation } from './createOrder'
import { useCreateRefundMutation } from './createRefund'
import { useRefreshPaymentMutation } from './refreshPayment'
import { useRemoveConfirmationMutation } from './removeConfirmation'
import { useRemoveInvoiceMutation } from './removeInvoice'
import { useRemoveManualPaymentMutation } from './removeManualPayment'
import { useRemoveOfferMutation } from './removeOffer'
import { useRemoveOrderMutation } from './removeOrder'
import { useSetOrderCustomerMutation } from './setCustomer'
import { useSetDocumentTemplateMutation } from './setDocumentTemplate'
import { useSetPaymentTypeMutation } from './setPaymentType'
import { useTriggerConfirmationActionMutation } from './triggerConfirmationAction'
import { useTriggerOfferActionMutation } from './triggerOfferAction'
import { useUpdateDocumentMutation } from './updateDocument'
import { useUpdateDocumentUserAttributeMutation } from './updateDocumentUserAttribute'
import { useUpdateInvoiceMutation } from './updateInvoice'
import { useUpdateManualPaymentMutation } from './updateManualPayment'
import { useUpdateOfferMutation } from './updateOffer'
import { useUpdateOrderMutation } from './updateOrder'

export default Object.freeze({
  useAcceptInvoiceMutation,
  useAddCashPaymentMutation,
  useAddCreditCardPaymentMutation,
  useAddGiftCardPaymentMutation,
  useAddOtherPaymentMutation,
  useAddReferencePaymentMutation,
  useAddVoucherPaymentMutation,
  useCancelExternalPaymentMutation,
  useCreateAdvanceMutation,
  useCreateConfirmationMutation,
  useCreateInvoiceMutation,
  useCreateOfferMutation,
  useCreateOrderMutation,
  useCreateRefundMutation,
  useRefreshPaymentMutation,
  useRemoveConfirmationMutation,
  useRemoveInvoiceMutation,
  useRemoveManualPaymentMutation,
  useRemoveOfferMutation,
  useRemoveOrderMutation,
  useSetDocumentTemplateMutation,
  useSetOrderCustomerMutation,
  useSetPaymentTypeMutation,
  useTriggerConfirmationActionMutation,
  useTriggerOfferActionMutation,
  useUpdateDocumentMutation,
  useUpdateDocumentUserAttributeMutation,
  useUpdateInvoiceMutation,
  useUpdateManualPaymentMutation,
  useUpdateOfferMutation,
  useUpdateOrderMutation,
})
