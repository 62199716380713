import { useRef, useState } from 'react'

import { InnocuousButton } from '@/components/ExtraButtons'
import { FormSectionTitle } from '@/components/FormWrappers'
import {
  ButtonCell,
  Spreadsheet,
  SpreadsheetContentPlaceholder,
  SpreadsheetTBody,
  SpreadsheetTH,
  SpreadsheetTR,
} from '@/components/Spreadsheet'
import { T } from '@/modules/Language'
import {
  customerHooks,
  OrganizationCustomer,
  OrganizationCustomerContact,
} from '@/modules/Registry/Customer'
import { generateCompareFn } from '@/utils/arrays'

import { ContactRow } from './ContactRow'

type Props = {
  customer: OrganizationCustomer
  onSelect?: (id: string) => void
  selected: string | null | undefined
}

export const Contacts = ({
  customer: { contacts, customerNumber, defaultContactId, id },
  onSelect,
  selected,
}: Props) => {
  const {
    createOrganizationContact,
    removeOrganizationContact,
    setDefaultOrganizationContact,
    updateOrganizationContact,
  } = customerHooks.useCustomer({ customerNumber })

  const [adding, setAdding] = useState<boolean>(false)
  const [focusTarget, setFocusTarget] = useState<
    null | 'ADD_BUTTON' | [number, number]
  >(null)

  const buttonEl = useRef<HTMLButtonElement>(null)

  const onPressCellKey = (rowIdx: number, columnIdx: number, key: string) => {
    if (key === 'ArrowDown' || key === 'Enter') {
      setFocusTarget([rowIdx + 1, columnIdx])
    }

    if (key === 'ArrowUp') {
      setFocusTarget([rowIdx - 1, columnIdx])
    }
  }

  const handleAdd = () => {
    if (!adding) {
      setAdding(true)
      createOrganizationContact(id, customerNumber, {
        firstName: '',
        lastName: '',
      }).finally(() => setAdding(false))
    }
  }

  const handleRemove = (contactId: string) =>
    removeOrganizationContact(id, contactId)

  const handleSetAsDefault = (contactId: string) =>
    setDefaultOrganizationContact(id, contactId)

  const handleUpdate = (
    {
      id: contactId,
      email,
      firstName,
      lastName,
      notes,
      phone,
    }: OrganizationCustomerContact,
    attribute: string,
    value: string
  ) =>
    updateOrganizationContact(
      id,
      contactId,
      // eslint-disable-next-line sort-keys
      { email, firstName, lastName, notes, phone, [attribute]: value }
    )

  const headerCells = [
    <SpreadsheetTH key="contact-list-lastName">
      <T>Customers:contact.lastName</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="contact-list-firstName">
      <T>Customers:contact.firstName</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="contact-list-email">
      <T>Customers:contact.email</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="contact-list-phone">
      <T>Customers:contact.phone</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="contact-list-notes">
      <T>Customers:contact.notes</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="contact-list-actions" forcedWidth={60} />,
  ]

  return (
    <>
      <FormSectionTitle>
        <T>Customers:contacts.title</T>
      </FormSectionTitle>

      <Spreadsheet
        headerCells={
          !!onSelect
            ? [
                <SpreadsheetTH key="contact-list-selector" forcedWidth={20} />,
                ...headerCells,
              ]
            : headerCells
        }
      >
        {contacts.length ? (
          <SpreadsheetTBody>
            {[...contacts]
              .sort(generateCompareFn(['lastName', 'firstName', 'id']))
              .map((i, rowIdx) => (
                <ContactRow
                  data={i}
                  focusedColumn={
                    Array.isArray(focusTarget) && focusTarget[0] === rowIdx
                      ? focusTarget[1]
                      : null
                  }
                  isDefault={defaultContactId === i.id}
                  isSelected={selected === i.id}
                  key={i.id}
                  onPressKey={(columnIdx, key) =>
                    onPressCellKey(rowIdx, columnIdx, key)
                  }
                  onRemove={() => handleRemove(i.id)}
                  onSelect={onSelect ? () => onSelect(i.id) : undefined}
                  onSetAsDefault={() => handleSetAsDefault(i.id)}
                  onUpdate={(attribute: string, value: string) =>
                    handleUpdate(i, attribute, value)
                  }
                />
              ))}
            <SpreadsheetTR>
              <ButtonCell
                busy={adding}
                colSpan={
                  !!onSelect ? headerCells.length + 1 : headerCells.length
                }
                disabled={adding}
                innerRef={buttonEl}
                onClick={handleAdd}
                onPressKey={(key) => {
                  key === 'ArrowUp' && setFocusTarget([contacts.length - 1, 0])
                }}
              >
                + <T>Customers:contacts.add</T>
              </ButtonCell>
            </SpreadsheetTR>
          </SpreadsheetTBody>
        ) : (
          <SpreadsheetContentPlaceholder
            colSpan={!!onSelect ? headerCells.length + 1 : headerCells.length}
            content={<T>Customers:contacts.empty</T>}
            helper={
              <InnocuousButton onClick={handleAdd}>
                + <T>Customers:contacts.add</T>
              </InnocuousButton>
            }
            size="small"
          />
        )}
      </Spreadsheet>
    </>
  )
}
