import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'

import { FlexRow } from '@/components/Layout'
import {
  Customer,
  Document,
  DocumentEditor,
  SectionSeparator,
} from '@/modules/Order/components'
import {
  Document as DocumentType,
  Offer,
  OfferByIdPayload,
  OrderType as Order,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales'

import { OfferAction as OA } from '~generated-types'

import { updateDocument } from '../../utils'
import { ActionsSection, DetailsSection } from './Sections'

type Props = {
  isVisible: boolean
  offer: Offer
  order: Order
  refetch: () => Promise<ApolloQueryResult<OfferByIdPayload>>
}

export const Content = ({ isVisible, offer, order, refetch }: Props) => {
  const {
    customer,
    document: offerDocument,
    id,
    lifecycle: { validatedActions },
    type,
  } = offer

  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false)

  const readOnly = !validatedActions.find((a) => a.action === OA.Update)?.valid

  const onUpdateDocument = (document: DocumentType) =>
    updateDocument(document, order.id, id, type, setOrdersById)

  return (
    <>
      <ActionsSection
        offer={offer}
        orderId={order.id}
        openDocumentModal={() => setDocumentModalOpen(true)}
      />

      <SectionSeparator />

      <FlexRow>
        <DetailsSection offer={offer} orderId={order.id} />

        <SectionSeparator />

        <Customer
          customer={customer}
          orderCustomer={order.customer}
          orderId={order.id}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Document
          document={offerDocument}
          isVisible={isVisible}
          onUpdateDocument={onUpdateDocument}
          orderId={order.id}
          ownerId={id}
          ownerType={type}
          readOnly={readOnly}
          refetch={refetch}
        />
      </FlexRow>

      {isDocumentModalOpen && offerDocument && (
        <DocumentEditor
          document={offerDocument}
          onClose={() => setDocumentModalOpen(false)}
          onUpdateDocument={onUpdateDocument}
          orderId={order.id}
          ownerId={id}
          readOnly={readOnly}
        />
      )}
    </>
  )
}
