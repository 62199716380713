import { FlexColumn } from '@/components/Layout'
import { ListItemSection as Wrapper } from '@/components/List'
import { CustomerTypeBadge } from '@/modules/Registry/Customer'

type Props = {
  type: 'ORGANIZATION' | 'PERSON'
}

const TypeInfo = ({ type }: Props) => (
  <Wrapper>
    <FlexColumn alignItems="flex-start" noPadding>
      <CustomerTypeBadge type={type} />
    </FlexColumn>
  </Wrapper>
)

export default TypeInfo
