import { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { Input } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { orderServices } from '@/modules/Order'

import { Section, SubTitle } from './common'

type Props = {
  freeText: string | null
  orderId: string
  ownerId: string
  readOnly: boolean
  refreshPdf: () => Promise<void>
}

export const FreeTextInput = ({
  freeText,
  orderId,
  ownerId,
  readOnly,
  refreshPdf,
}: Props) => {
  const { updateInvoice } = orderServices.invoiceService()

  const [freeTextValue, setFreeTextValue] = useState<string>('')

  useEffect(() => {
    setFreeTextValue(freeText || '')
  }, [freeText])

  const handleSetFreeText = () => {
    if (freeText !== freeTextValue) {
      const input = { freeText: freeTextValue || null, id: ownerId }

      updateInvoice(input, orderId).then(refreshPdf)
    }
  }

  return (
    <Section>
      <SubTitle>
        <T>Orders:Documents.freeText</T>
      </SubTitle>

      <Textarea
        disabled={readOnly}
        onBlur={handleSetFreeText}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setFreeTextValue(e.target.value)
        }
        value={freeTextValue}
      />
    </Section>
  )
}

///////

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))`
  &&& {
    line-height: 1.2;
    resize: vertical;

    min-height: 90px;
    padding: 10px;
    width: 100%;

    ${({ theme }) => `
      margin-top: ${theme.spacing.gu(0.5)}rem;
      border-color: ${theme.palette.smoke.main};
    `}
    }
  }
`
