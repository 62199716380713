import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ValidationIndicator } from '@/components/ValidationIndicator'
import { UserGroup } from '@/modules/Registry'
import { SalesResourceReservationTask } from '@/modules/Reservations/ResourceReservation'
import { useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'

import { Name, Price, Quantity, Separator, TimeInterval } from './common'

type Props = {
  isLast: boolean
  reservationStartDate: string
  task: SalesResourceReservationTask
  isMovingProcess?: boolean
}

export const ReservationTask = ({
  isLast,
  reservationStartDate,
  task,
  isMovingProcess,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { assignee, description, dueDate, dueTime, isOpen } = task

  const purchases = [...task.purchaseProducts].sort(
    (a, b) =>
      moment(a.auditLog.createdAt).valueOf() -
      moment(b.auditLog.createdAt).valueOf()
  )

  return (
    <TaskRow
      isLast={isLast}
      className="task-row"
      isMovingProcess={isMovingProcess}
    >
      <FlexColumn noPadding>
        <FlexRow alignItems="center" style={{ height: '16px' }}>
          <Name customMargin={`0 ${spacing.gu(1)}rem`} width={18}>
            {(assignee as UserGroup)?.name}
          </Name>

          <FontAwesomeIcon
            color={isOpen ? palette.smoke.extraDark : palette.success.main}
            fixedWidth
            icon={isOpen ? ['far', 'hourglass'] : 'check'}
            size="sm"
            style={{ marginRight: spacing.gutter, width: spacing.gutter }}
          />

          <Separator />

          <TimeInterval>
            {dueDate &&
              getTaskDateLabel(dueDate, dueTime, reservationStartDate)}
          </TimeInterval>

          <Separator />

          <Name compactWidth={34} width={46}>
            {description}
          </Name>

          <Separator transparent />

          <Separator />

          {purchases[0] ? (
            <>
              <Name compactWidth={18} width={26}>
                {purchases[0].status.issues.map(({ code, key, level }) => (
                  <ValidationIndicatorWrapper key={`${key}-${code}`}>
                    <ValidationIndicator
                      code={code}
                      level={level}
                      style={{ margin: `0 ${spacing.gutterSmall}` }}
                    />
                  </ValidationIndicatorWrapper>
                ))}
                {purchases[0].product.name}
              </Name>

              <Quantity>
                {/* <>
                {purchases[0].quantity} <T>enums:quantityUnit.abbrev.PIECE</T>
              </> */}
              </Quantity>

              <Price>
                {formatCurrency(purchases[0].totalPrice.amountVatIncluded)} €
              </Price>
            </>
          ) : (
            <>
              <Name compactWidth={18} width={26} />

              <Quantity />

              <Price />
            </>
          )}
        </FlexRow>

        {purchases
          .slice(1)
          .map(({ id, product: { name }, status, totalPrice }) => (
            <FlexRow alignItems="center" key={id} style={{ height: '19px' }}>
              <Name width={20} />

              <Separator />

              <TimeInterval />

              <Separator />

              <Name compactWidth={34} width={46} />

              <Separator transparent />

              <Separator />

              <FlexRow
                alignItems="center"
                style={{ paddingTop: `${spacing.guPx(1) - 3}px` }}
              >
                <Name compactWidth={18} width={26}>
                  {status.issues.map(({ code, key, level }) => (
                    <ValidationIndicatorWrapper key={`${key}-${code}`}>
                      <ValidationIndicator
                        code={code}
                        level={level}
                        style={{ margin: `0 ${spacing.gutterSmall}` }}
                      />
                    </ValidationIndicatorWrapper>
                  ))}
                  {name}
                </Name>

                <Quantity>
                  {/* {quantity} <T>enums:quantityUnit.abbrev.PIECE</T> */}
                </Quantity>

                <Price>{formatCurrency(totalPrice.amountVatIncluded)} €</Price>
              </FlexRow>
            </FlexRow>
          ))}
      </FlexColumn>
    </TaskRow>
  )
}

////////

const getTaskTime = (time: string) => time.slice(0, -3)
const getTaskDate = (date: string) => moment(date).format('D.M.YYYY')
const getTaskDateLabel = (
  dueDate: string,
  dueTime: string | null,
  reservationStartDate: string
) => {
  if (moment(dueDate).isSame(reservationStartDate, 'day')) {
    return dueTime && getTaskTime(dueTime)
  }

  return dueTime
    ? `${getTaskDate(dueDate)}, ${getTaskTime(dueTime)}`
    : getTaskDate(dueDate)
}

const TaskRow = styled(FlexRow)<{ isLast: boolean; isMovingProcess?: boolean }>`
  ${({ theme, isMovingProcess }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem;
    margin-left: ${isMovingProcess
      ? `calc(${theme.spacing.gu(6)}rem + 28px)`
      : `calc(${theme.spacing.gu(4)}rem + 10px)`};
  `}

  border-bottom-right-radius: ${({ isLast }) => (isLast ? 6 : 0)}px;
  border-bottom-left-radius: ${({ isLast }) => (isLast ? 6 : 0)}px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
