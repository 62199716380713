import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { formatCurrency } from '@/utils/currency'

import {
  SalesForPrintParticipant,
  SalesForPrintPurchase,
  SalesPrintConfigKey as Key,
} from '../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../utils'

type Props = {
  data: SalesForPrintParticipant
}

export const GuestDetails = ({
  data: {
    allergies,
    allergyDescription,
    accommodationRequest,
    additionalInfo,
    diets,
    drugAllergyDescription,
    services,
  },
}: Props) => {
  const { salesPrintConfig: config, salesPrintShowPrice: showPrice } =
    useSalesDetailsContext()

  const purchases = services
    .map(({ purchaseProduct }) => purchaseProduct)
    .filter(Boolean) as SalesForPrintPurchase[]

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Participants}.${key}`)

  const withDetails =
    (checkVisibility(Key.Product) && !!purchases.length) ||
    (checkVisibility(Key.Request) && !!accommodationRequest) ||
    (checkVisibility(Key.Notes) && !!additionalInfo) ||
    (checkVisibility(Key.Allegries) &&
      (!!allergies.length ||
        !!allergyDescription ||
        !!diets.length ||
        !!drugAllergyDescription))

  return withDetails ? (
    <Wrapper noPadding>
      {checkVisibility(Key.Product) && !!purchases.length && (
        <>
          <Section>
            <Title justifyContent="flex-end">
              <T>SalesDetails:PrintSale.label.PRODUCT</T>
            </Title>

            <FlexRow flex={1}>
              <FlexRow flex={3}>{purchases[0].product.name}</FlexRow>
              {showPrice && (
                <Price flex={1} justifyContent="flex-end">
                  {formatCurrency(purchases[0].totalPrice.amountVatIncluded)} €
                </Price>
              )}
            </FlexRow>
          </Section>

          {purchases.slice(1).map(({ id, product, totalPrice }) => (
            <Section key={id}>
              <Title />

              <FlexRow flex={1}>
                <FlexRow flex={3}>{product.name}</FlexRow>
                {showPrice && (
                  <Price flex={1} justifyContent="flex-end">
                    {formatCurrency(totalPrice.amountVatIncluded)} €
                  </Price>
                )}
              </FlexRow>
            </Section>
          ))}
        </>
      )}

      {checkVisibility(Key.Request) && accommodationRequest && (
        <Section>
          <Title justifyContent="flex-end">
            <T>SalesDetails:PrintSale.label.REQUEST</T>
          </Title>

          <FlexRow flex={1} style={{ whiteSpace: 'pre-wrap' }}>
            {accommodationRequest}
          </FlexRow>
        </Section>
      )}

      {checkVisibility(Key.Notes) && additionalInfo && (
        <Section>
          <Title justifyContent="flex-end">
            <T>SalesDetails:PrintSale.label.NOTES</T>
          </Title>

          <FlexRow flex={1} style={{ whiteSpace: 'pre-wrap' }}>
            {additionalInfo}
          </FlexRow>
        </Section>
      )}

      {checkVisibility(Key.Allegries) && (
        <>
          {!!allergies.length && (
            <Section>
              <Title justifyContent="flex-end">
                <T>SalesDetails:PrintSale.label.ALLERGIES</T>
              </Title>

              <FlexRow flex={1}>{allergies.join(', ')}</FlexRow>
            </Section>
          )}

          {allergyDescription && (
            <Section>
              <Title justifyContent="flex-end">
                <T>SalesDetails:PrintSale.label.ALLERGY_DESCRIPTION</T>
              </Title>

              <FlexRow flex={1} style={{ whiteSpace: 'pre-wrap' }}>
                {allergyDescription}
              </FlexRow>
            </Section>
          )}

          {!!diets.length && (
            <Section>
              <Title justifyContent="flex-end">
                <T>SalesDetails:PrintSale.label.DIETS</T>
              </Title>

              <FlexRow flex={1}>{diets.join(', ')}</FlexRow>
            </Section>
          )}

          {drugAllergyDescription && (
            <Section>
              <Title justifyContent="flex-end">
                <T>SalesDetails:PrintSale.label.DRUG_ALLERGY_DESCRIPTION</T>
              </Title>

              <FlexRow flex={1} style={{ whiteSpace: 'pre-wrap' }}>
                {drugAllergyDescription}
              </FlexRow>
            </Section>
          )}
        </>
      )}
    </Wrapper>
  ) : null
}

//////

const Section = styled(FlexRow)`
  font-size: 1rem;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Price = styled(FlexRow)`
  font-weight: 500;
`

const Title = styled(FlexRow)`
  font-weight: 500;

  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1.5)}rem;
    width: ${theme.spacing.gu(24)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    border-top: 1px dashed ${theme.palette.smoke.dark};
    padding-top: ${theme.spacing.gu(1)}rem;
    margin-top: ${theme.spacing.gu(1.5)}rem;
  `}
`
