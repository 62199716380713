import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import { Gutter } from '@/components/Layout'
import { NotFound } from '@/components/NotFound'
import { customerHooks } from '@/modules/Registry'

import { BackToListButton } from '../BackToListButton'
import {
  Addresses,
  BasicDetails,
  Contacts,
  CustomerOrders,
  Header,
} from './components'

type Props = {
  customerNumber?: string
  onNavigateBack?: () => void
  onSelectCustomer?: (
    addressId: string | null | undefined,
    contactId: string | null | undefined
  ) => Promise<void>
}

export const EditCustomer = ({
  customerNumber,
  onNavigateBack,
  onSelectCustomer,
}: Props) => {
  const { customer, error, loading } = customerHooks.useCustomer({
    customerNumber,
  })

  const [selectedAddress, setSelectedAddress] = useState<
    string | null | undefined
  >(customer?.defaultAddressId)
  const [selectedContact, setSelectedContact] = useState<
    string | null | undefined
  >(
    customer?.__typename === 'CustomerOrganization'
      ? customer.defaultContactId
      : null
  )

  if (loading) {
    return (
      <PlaceholderFrame>
        <FullScreenLoader />
      </PlaceholderFrame>
    )
  }

  if (error || !customer) {
    return (
      <PlaceholderFrame>
        <NotFound />
      </PlaceholderFrame>
    )
  }

  const isSelectable = !!onSelectCustomer

  return (
    <Gutter type={[2, 0, 3]}>
      <Wrapper>
        {onNavigateBack && <BackToListButton onClick={onNavigateBack} />}

        <Header
          data={customer}
          onSelect={
            isSelectable
              ? () =>
                  onSelectCustomer(
                    selectedAddress !== 'DEFAULT' ? selectedAddress : null,
                    selectedContact
                  )
              : undefined
          }
        />

        <BasicDetails data={customer} />

        <SectionSeparator />

        <Addresses
          customer={customer}
          onSelect={
            isSelectable
              ? (id: string | null | undefined) => setSelectedAddress(id)
              : undefined
          }
          selected={selectedAddress}
        />

        {customer.__typename === 'CustomerOrganization' && (
          <>
            <SectionSeparator />

            <Contacts
              customer={customer}
              onSelect={
                isSelectable
                  ? (id: string | null | undefined) => setSelectedContact(id)
                  : undefined
              }
              selected={selectedContact}
            />
          </>
        )}

        <SectionSeparator />

        <CustomerOrders customerId={customer.id} />
      </Wrapper>
    </Gutter>
  )
}

////////////

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
`

const PlaceholderFrame = ({ children }: { children: ReactNode }) => (
  <Gutter style={{ height: 572 }} type={[0, 5, 3]}>
    {children}
  </Gutter>
)

const SectionSeparator = styled.div`
  flex: 0 0 2px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(3)}rem 0 ${theme.spacing.gutterBig};
  `}
`
