import { useState } from 'react'
import { DataSearch as LibraryDataSearch } from '@appbaseio/reactivesearch'
import type { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { createGlobalStyle, css } from 'styled-components/macro'

import { useTheme } from '@/theme'

import { inputCss } from './styles'

const wrapperClassName = 'rs-data-search-wrapper'
const inputClassNameBase = 'rs-data-search-input'
const inputClassNameClearable = 'rs-data-search-input-clearable'
const inputClassNameIcon = 'rs-data-search-input-with-icon'
const listClassName = 'rs-data-search-list'

type Props = DataSearchProps & { isMobile?: boolean }

export const DataSearch = ({
  autosuggest = true,
  dataField,
  defaultQuery,
  fieldWeights,
  fuzziness,
  isMobile,
  queryFormat,
  showClear,
  showIcon = true,
  ...props
}: Props) => {
  const theme = useTheme()

  // If a defaultQuery is defined and autosuggest is enabled, we must
  // do some special handling. By default, setting a defaultQuery will
  // cause the DataSearch component to only use that query and it will
  // in turn make the autosuggestions inusable.
  //
  // To fix this, we need to manage the defaultQuery manually and to
  // do so, we need to make the component to function as a controlled
  // input. It is a hacky fix but necessary.
  const [inputValue, setInputValue] = useState<string>('')

  const onChange = (value: any) => setInputValue(value)

  const onKeyDown = (e: any, triggerQuery: (...args: any[]) => void) => {
    if (e.key === 'Enter') {
      triggerQuery()
    }
  }

  const manualProps =
    autosuggest && !!defaultQuery
      ? {
          defaultQuery: getAugmentedDefaultQueryFn(
            inputValue,
            defaultQuery,
            // @ts-ignore
            Array.isArray(dataField) ? dataField : [dataField],
            fieldWeights ?? [],
            fuzziness ?? 0,
            queryFormat
          ),
          onChange,
          onKeyDown,
          // React to external clears from <SelectedFilters />
          onValueChange: (value: any) => {
            if (!value) {
              setInputValue('')
            }
          },
          value: inputValue,
        }
      : {}

  return (
    <>
      <DataSearchStyles isMobile={isMobile} />
      <LibraryDataSearch
        {...props}
        {...manualProps}
        autosuggest={autosuggest}
        className={wrapperClassName}
        clearIcon={
          <FontAwesomeIcon
            color={theme.palette.text.light}
            icon="xmark"
            style={{ position: 'relative', top: isMobile ? 1 : -2 }}
          />
        }
        dataField={dataField}
        fieldWeights={fieldWeights}
        icon={
          <FontAwesomeIcon
            color={theme.palette.primary.main}
            icon="magnifying-glass"
            style={{ position: 'relative', top: isMobile ? 0 : -3 }}
          />
        }
        innerClass={{
          input: classNames(inputClassNameBase, {
            [inputClassNameClearable]: !!showClear,
            [inputClassNameIcon]: !!showIcon,
          }),
          list: listClassName,
        }}
        queryFormat={queryFormat}
        showClear={showClear}
        showIcon={showIcon}
      />
    </>
  )
}

////////////

const DataSearchStyles = createGlobalStyle<{ isMobile?: boolean }>`
  .${inputClassNameBase} {
    ${inputCss}

    &:not([type='checkbox']):not([type='radio']) {
      ${({ isMobile }) =>
        isMobile &&
        css`
          height: 40px;
        `}
    }
  }

  .${inputClassNameClearable} {
    padding-right: 26px !important;
  }

  .${inputClassNameIcon} {
    padding-left: 32px !important;
  }

  .${listClassName} {
    z-index: 1000!important;
  }
`

const getAugmentedDefaultQueryFn = (
  value: string,
  defaultQuery: () => {
    [key: string]: any
  },
  dataFields: string[],
  fieldWeights: number[],
  fuzziness: number,
  queryFormat: ('and' | 'or') | null | undefined
) => {
  const fields = dataFields.map(
    (field, idx) =>
      `${field}${fieldWeights[idx] ? `^${fieldWeights[idx]}` : ''}`
  )
  const operator = queryFormat ?? 'or'
  const multiMatchBase = {
    fields,
    operator,
    query: value,
  }
  const defaultQueryContent = defaultQuery().query ?? {}

  return () => ({
    query: {
      bool: {
        must: [
          { ...defaultQueryContent },
          {
            bool: {
              minimum_should_match: '1',
              should: [
                {
                  multi_match:
                    operator === 'or'
                      ? { ...multiMatchBase, fuzziness, type: 'best_fields' }
                      : { ...multiMatchBase, type: 'cross_fields' },
                },
                {
                  multi_match: { ...multiMatchBase, type: 'phrase_prefix' },
                },
              ],
            },
          },
        ],
      },
    },
  })
}
