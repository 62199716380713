import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  RefreshPaymentMutation,
  RefreshPaymentMutationVariables,
} from '~generated-types'

const REFRESH_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation RefreshPayment($id: ID!) {
    paymentRefresh(id: $id) {
      ...Payment
    }
  }
`

export const useRefreshPaymentMutation = () =>
  useMutation<RefreshPaymentMutation, RefreshPaymentMutationVariables>(
    REFRESH_PAYMENT_MUTATION
  )
