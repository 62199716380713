import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import {
  CustomerAddressEditable,
  CustomerBasicDetails,
  CustomerContactEditable,
  CustomerManagerRenderProps,
  CustomerTarget,
} from '@/modules/Registry/Customer'
import { useTheme } from '@/theme'

type Props = CustomerManagerRenderProps & {
  readOnly?: boolean
}

const SalesCustomerPicker = ({
  readOnly,
  customer,
  error,
  loading,
  openDetails,
  openRegistry,
  selectedAddressId,
  selectedContactId,
  setAddress,
  setContact,
}: Props) => {
  const theme = useTheme()

  if (loading) {
    return (
      <Placeholder>
        <ReactLoading
          color={theme.palette.smoke.main}
          height={24}
          type="spin"
          width={24}
        />
      </Placeholder>
    )
  }

  if (error) {
    return (
      <Placeholder>
        <T>SalesDetails:Customer.error</T>
      </Placeholder>
    )
  }

  if (!customer) {
    return (
      <>
        <Placeholder>
          <T>SalesDetails:Customer.unset</T>
        </Placeholder>

        <Spacer />

        <InnocuousButton disabled={readOnly} compact onClick={openRegistry}>
          <PrimaryColor>
            <T>SalesDetails:Customer.selectCustomer</T>
          </PrimaryColor>
        </InnocuousButton>
      </>
    )
  }

  const asTarget: CustomerTarget = {
    customerNumber: customer.customerNumber,
    id: customer.id,
  }

  const toggleEdit = () => openDetails(asTarget)

  return (
    <FlexRow alignItems="stretch" flex={1} style={{ width: '100%' }}>
      <FlexColumn
        alignItems="flex-start"
        flex={1}
        style={{ marginRight: theme.spacing.gutter }}
      >
        <CustomerBasicDetails customer={customer} />

        <div style={{ height: `${theme.spacing.gu(1)}rem` }} />

        <Spacer />

        <FlexRow>
          <InnocuousButton
            disabled={readOnly}
            compact
            onClick={() => toggleEdit()}
          >
            <PrimaryColor>
              <FontAwesomeIcon icon="magnifying-glass" />
            </PrimaryColor>
          </InnocuousButton>
          <InnocuousButton
            disabled={readOnly}
            compact
            onClick={() => openRegistry()}
          >
            <PrimaryColor>
              <FontAwesomeIcon icon="retweet" />
            </PrimaryColor>
          </InnocuousButton>
        </FlexRow>
      </FlexColumn>

      <Separator />

      <FlexColumn alignItems="stretch" flex={1}>
        <Label>
          {customer.__typename === 'CustomerOrganization' ? (
            <T>Customers:contact.title</T>
          ) : (
            <T>Customers:contactDetails</T>
          )}
        </Label>
        <CustomerContactEditable
          readOnly={readOnly}
          customer={customer}
          editInRegistry={() => openDetails(asTarget)}
          selectedContactId={selectedContactId}
          setContact={(target) => setContact(target)}
        />
      </FlexColumn>

      <Separator />

      <FlexColumn alignItems="stretch" flex={1}>
        <Label>
          <T>Customers:address.title</T>
        </Label>
        <CustomerAddressEditable
          readOnly={readOnly}
          customer={customer}
          editInRegistry={() => openDetails(asTarget)}
          selectedAddressId={selectedAddressId}
          setAddress={(target) => setAddress(target)}
        />
      </FlexColumn>
    </FlexRow>
  )
}

export default SalesCustomerPicker

////////////

const Placeholder = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin: ${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled.div`
  flex: 0 0 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gutter};
  `}
`

const Spacer = styled.div`
  flex: 1;
`
