import { Moment } from 'moment'
import styled from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  Option,
  ThemedMultiSelect,
  ThemedSelect,
} from '@/components/ThemedSelect'
import { H3 } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ListingLayout } from '@/modules/Listing/common'
import type { CalendarResourceElastic } from '@/modules/Registry'
import {
  ResourceCalendarDateRangeSidebar,
  ResourceCalendarDateRangeSidebarProps,
} from '@/modules/Reservations'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import { PrintSize } from '../types'
import {
  ShowReservationGroups,
  SortOption,
  TaskDetailsOption,
} from './CalendarResourceListingContainer'
import ReservationListingForDate from './ReservationListingForDate'

type Props = ResourceCalendarDateRangeSidebarProps & {
  clearActivePreset: () => void
  dateRange: Moment[]
  resourceIds: string[]
  setShowAllTasks: (arg: boolean) => void
  setShowExtendedTasks: (arg: boolean) => void
  setShowReservationGroups: (arg: ShowReservationGroups) => void
  setSortBy: (arg0: SortOption) => any
  setTaskTarget: (target: string[]) => void
  setViewAssigneeSelection: (target: string[]) => void
  showAllTasks: boolean
  showExtendedTasks: boolean
  showReservationGroups: ShowReservationGroups
  sortBy: SortOption
  taskTargetResources: CalendarResourceElastic[]
  viewAssigneeSelection: string[]
}

export const CalendarResourceListing = ({
  clearActivePreset,
  dateRange,
  resourceIds,
  setShowAllTasks,
  setShowExtendedTasks,
  setShowReservationGroups,
  setSortBy,
  setViewAssigneeSelection,
  showAllTasks,
  showExtendedTasks,
  showReservationGroups,
  sortBy,
  taskTargetResources,
  viewAssigneeSelection,
  ...sidebarProps
}: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const getOption = (
    key: SortOption | ShowReservationGroups | TaskDetailsOption,
    translationKey: string
  ) => ({
    label: translate(
      `Listings:CalendarResourceListing.${translationKey}.${key}`,
      language
    ),
    value: key,
  })

  const assigneeOptions = sidebarProps.taskTargetOptions
    .map(({ label, id }) => ({
      label,
      value: id,
    }))
    .sort(generateCompareFn('label'))

  const renderContent = (printSize: PrintSize) => (
    <FlexColumn alignItems="stretch" flex={1}>
      {dateRange.map((date) => (
        <ReservationListingForDate
          key={date.toISOString()}
          allResources={sidebarProps.allResources}
          date={date}
          printSize={printSize}
          resourceIds={resourceIds}
          selectedCategories={sidebarProps.selectedCategories}
          selectedResources={sidebarProps.selectedResources}
          showAllTasks={showAllTasks}
          showExtendedTasks={showExtendedTasks}
          showReservationGroups={showReservationGroups}
          sortBy={sortBy}
          taskTarget={sidebarProps.taskTargetSelection}
          taskTargetResources={taskTargetResources}
          viewAssigneeSelection={viewAssigneeSelection}
        />
      ))}
    </FlexColumn>
  )

  const renderHeaderControls = () => (
    <ControlsWrapper alignItems="flex-start">
      <FlexRow>
        <SelectWrapper>
          <SelectLabel>
            <T>Listings:CalendarResourceListing.showReservationGroups.title</T>
          </SelectLabel>

          <ThemedSelect
            isCompact
            name="reservation-group-selector"
            placeholder=""
            onChange={(option?: Option | null) =>
              option &&
              setShowReservationGroups(
                (option.value as ShowReservationGroups) ?? 'hidden'
              )
            }
            options={[
              getOption('hidden', 'showReservationGroups'),
              getOption('show_once', 'showReservationGroups'),
              getOption('repeat', 'showReservationGroups'),
            ]}
            value={getOption(showReservationGroups, 'showReservationGroups')}
          />
        </SelectWrapper>

        <SelectWrapper>
          <SelectLabel>
            <T>Listings:CalendarResourceListing.sort.title</T>
          </SelectLabel>

          <ThemedSelect
            isCompact
            name="sort-order-selector"
            placeholder=""
            onChange={(option?: Option | null) =>
              option && setSortBy(option.value as SortOption)
            }
            options={[
              getOption('time', 'sort'),
              getOption('resource', 'sort'),
              getOption('main_resource', 'sort'),
            ]}
            value={getOption(sortBy, 'sort')}
          />
        </SelectWrapper>

        <SelectWrapper>
          <SelectLabel>
            <T>Listings:CalendarResourceListing.taskDetails.title</T>
          </SelectLabel>

          <ThemedSelect
            isCompact
            name="task-details-selector"
            placeholder=""
            onChange={(target: Option | null | undefined) => {
              setShowExtendedTasks(target?.value === 'extended')
            }}
            options={[
              getOption('minimal', 'taskDetails'),
              getOption('extended', 'taskDetails'),
            ]}
            value={getOption(
              showExtendedTasks ? 'extended' : 'minimal',
              'taskDetails'
            )}
          />
        </SelectWrapper>
      </FlexRow>
      <BottomSelectorsWrapper alignItems="flex-end">
        <SelectWrapper>
          <SelectLabel>
            <T>ResourceReservationsCalendar:TaskTargetSelector.title</T>
          </SelectLabel>

          <ThemedMultiSelect
            isCompact
            noOptionsPlaceholder={() => (
              <T>ResourceReservationsCalendar:TaskTargetSelector.empty</T>
            )}
            modalWidth={`${spacing.gu(25)}rem`}
            options={assigneeOptions}
            placeholder={
              <T>ResourceReservationsCalendar:TaskTargetSelector.placeholder</T>
            }
            selectedValues={viewAssigneeSelection}
            setSelectedValues={setViewAssigneeSelection}
            withSelectAll
          />
        </SelectWrapper>

        <div>
          <CheckboxInput
            checked={showAllTasks}
            onChange={() => setShowAllTasks(!showAllTasks)}
          >
            <T>ResourceReservationsCalendar:TaskTargetCheckbox.title</T>
          </CheckboxInput>
        </div>
      </BottomSelectorsWrapper>
    </ControlsWrapper>
  )

  const renderSidebar = () => (
    <ResourceCalendarDateRangeSidebar
      {...sidebarProps}
      clearSelectionsLockedOverlay={() => clearActivePreset()}
      selectionsLockedOverlay={
        sidebarProps.activePreset ? (
          <>
            <strong>
              <T>Listings:CalendarResourceListing.preset.selection</T>:
            </strong>
            <H3 style={{ fontVariantCaps: 'all-small-caps', marginTop: 0 }}>
              {sidebarProps.activePreset.label}
            </H3>
          </>
        ) : null
      }
    />
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderHeaderControls={renderHeaderControls}
      renderSidebar={renderSidebar}
      title={<T>Listings:CalendarResourceListing.pageTitle</T>}
      type="VERTICAL"
    />
  )
}

export default CalendarResourceListing

////////////

const SelectLabel = styled(Label)`
  white-space: nowrap;
`

const BottomSelectorsWrapper = styled(FlexRow)`
  margin-top: ${({ theme }) => theme.spacing.gu(2)}rem;
`

const ControlsWrapper = styled(FlexColumn)`
  @media print {
    display: none;
  }
`

const SelectWrapper = styled.div`
  display: grid;
  margin-right: ${({ theme }) => theme.spacing.gu(2)}rem;
  min-width: ${({ theme }) => theme.spacing.gu(25)}rem;
`
