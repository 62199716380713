import { useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'

import { FlexRow } from '@/components/Layout'
import {
  Customer,
  Document,
  DocumentEditor,
  SectionSeparator,
} from '@/modules/Order/components'
import {
  Confirmation,
  ConfirmationByIdPayload,
  Document as DocumentType,
  OrderType as Order,
} from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales'

import { OrderConfirmationAction as CA } from '~generated-types'

import { updateDocument } from '../../utils'
import { ActionsSection, DetailsSection } from './Sections'

type Props = {
  confirmation: Confirmation
  isVisible: boolean
  order: Order
  refetch: () => Promise<ApolloQueryResult<ConfirmationByIdPayload>>
}

export const Content = ({ confirmation, isVisible, order, refetch }: Props) => {
  const {
    auditLog: { createdAt },
    customer,
    document: confirmationDocument,
    id,
    lifecycle: { validatedActions },
    type,
  } = confirmation

  const { setOrdersById } = salesHooks.useSalesDetailsContext()

  const [isDocumentModalOpen, setDocumentModalOpen] = useState<boolean>(false)

  const readOnly = !validatedActions.find((o) => o.action === CA.Accept)?.valid

  const onUpdateDocument = (document: DocumentType) =>
    updateDocument(document, order.id, id, type, setOrdersById)

  return (
    <>
      <ActionsSection
        confirmation={confirmation}
        openDocumentModal={() => setDocumentModalOpen(true)}
        orderId={order.id}
      />

      <SectionSeparator />

      <FlexRow>
        <DetailsSection createdAt={createdAt} />

        <SectionSeparator />

        <Customer
          customer={customer}
          orderCustomer={order.customer}
          orderId={order.id}
          readOnly={readOnly}
        />

        <SectionSeparator />

        <Document
          document={confirmationDocument}
          isVisible={isVisible}
          onUpdateDocument={onUpdateDocument}
          orderId={order.id}
          ownerId={id}
          ownerType={type}
          readOnly={readOnly}
          refetch={refetch}
        />
      </FlexRow>

      {isDocumentModalOpen && confirmationDocument && (
        <DocumentEditor
          document={confirmationDocument}
          onClose={() => setDocumentModalOpen(false)}
          onUpdateDocument={onUpdateDocument}
          orderId={order.id}
          ownerId={id}
          readOnly={readOnly}
        />
      )}
    </>
  )
}
