import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { CatalogProductPicker } from '@/modules/Products'
import { useSalesProductsCompact } from '@/modules/Products/hooks'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

import {
  PurchaseProductAddFromCatalogProductInput,
  PurchaseProductAddFromSalesProductInput,
} from '~generated-types'

import { NewServiceButton } from './NewServiceButton'

type Props = {
  allowAccommodation: boolean
  creatingNew: 'BED' | 'PRODUCT' | null
  handleAddCatalogProduct: (
    input: PurchaseProductAddFromCatalogProductInput
  ) => Promise<any>
  handleAddSalesProduct: (
    input: PurchaseProductAddFromSalesProductInput
  ) => Promise<any>
  isActive: boolean
  isVisible: boolean
  participantId: string
  participantSalesId: string
  setCreatingNew: (creatingNew: 'BED' | 'PRODUCT' | null) => void
}

export const NewServiceModal = ({
  allowAccommodation,
  creatingNew,
  handleAddCatalogProduct,
  handleAddSalesProduct,
  isActive,
  isVisible,
  participantId,
  participantSalesId,
  setCreatingNew,
}: Props) => {
  const { palette } = useTheme()
  const {
    data: { id: salesId, seller },
  } = useSalesDetailsContext()

  const [isBottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isProductSelection, setProductSelection] = useState<boolean>(
    !allowAccommodation
  )

  const { products, error, loading } = useSalesProductsCompact({
    salesId,
    skip: !isModalOpen || !isProductSelection,
  })

  const addCatalogProduct = (catalogProductId: string) => {
    setCreatingNew(null)
    setProductSelection(!allowAccommodation)

    return handleAddCatalogProduct({
      add: { link: { participantId, salesId: participantSalesId } },
      catalogProductId,
    }).then(() => setBottomSheetOpen(false))
  }

  const addSalesProduct = (salesProductId: string) => {
    setModalOpen(false)
    setCreatingNew(null)
    setProductSelection(!allowAccommodation)

    return handleAddSalesProduct({
      add: { link: { participantId, salesId: participantSalesId } },
      salesProductId,
    })
  }

  const addAccommodationService = () => {
    setModalOpen(false)
    setCreatingNew('BED')
  }

  const openBottomSheet = () => {
    setModalOpen(false)
    setBottomSheetOpen(true)
  }

  return (
    <>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal>
            {isProductSelection ? (
              <>
                <ModalSection alignItems="center">
                  {loading ? (
                    <LoaderWrapper>
                      <ReactLoading
                        color={palette.smoke.main}
                        height={24}
                        type="spin"
                        width={24}
                      />
                    </LoaderWrapper>
                  ) : error ? (
                    <Placeholder>
                      – <T>common:error.common</T> –
                    </Placeholder>
                  ) : products.length > 0 ? (
                    [...products]
                      .sort(generateCompareFn('name'))
                      .map((product) => (
                        <Option
                          key={product.id}
                          onClick={() => addSalesProduct(product.id)}
                        >
                          {product.name}
                        </Option>
                      ))
                  ) : (
                    <T>ParticipantsList:ParticipantProducts.noProducts</T>
                  )}
                </ModalSection>

                {!loading && (
                  <OptionWrapper>
                    <Option onClick={openBottomSheet}>
                      <T>Products:SalesProductList.selectFromCatalog</T>
                    </Option>
                  </OptionWrapper>
                )}
              </>
            ) : (
              <ServiceOptionsWrapper>
                <Option onClick={addAccommodationService}>
                  <Icon fixedWidth icon="bed" size="sm" />
                  <T>ParticipantsList:Services.accommodation</T>
                </Option>
                <Option onClick={() => setProductSelection(true)}>
                  <Icon fixedWidth icon="euro-sign" size="sm" />
                  <T>ParticipantsList:Services.basic</T>
                </Option>
              </ServiceOptionsWrapper>
            )}
          </InlineModal>
        }
        onClose={() => {
          setModalOpen(false)
          setProductSelection(!allowAccommodation)
        }}
        placement="bottom-start"
        referenceElement={({ ref }) => (
          <NewServiceButton
            innerRef={ref}
            isActive={isActive}
            isVisible={isVisible}
            onClick={() =>
              creatingNew ? setCreatingNew(null) : setModalOpen(true)
            }
          />
        )}
      />

      {isBottomSheetOpen && (
        <CatalogProductPicker
          onItemSelect={addCatalogProduct}
          onClose={() => setBottomSheetOpen(false)}
          sellerId={seller?.id}
        />
      )}
    </>
  )
}

///////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const ModalSection = styled(FlexColumn)`
  min-width: 320px;
  max-height: 250px;
  overflow: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `};
`

const ServiceOptionsWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const OptionWrapper = styled.div`
  text-align: end;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    border-top: 1px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Option = styled.div`
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const Placeholder = styled(FontWeight)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    padding-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
