import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { UserGroup } from '@/modules/Registry'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { formatCurrency } from '@/utils/currency'

import {
  SalesForPrintTask,
  SalesPrintConfigKey as Key,
} from '../../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../../utils'

type Props = {
  isNested?: boolean
  data: SalesForPrintTask
  reservationStart?: string
}

export const TaskRow = ({ isNested, data, reservationStart }: Props) => {
  const {
    assignee,
    description,
    dueDate,
    dueTime,
    name,
    purchaseProducts: products,
  } = data

  const { salesPrintConfig: config, salesPrintShowPrice: showPrice } =
    useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(
      config,
      `${Key.Program}.${Key.Tasks}.${key}`
    )

  const showAssignee = checkVisibility(Key.Assignee) && !!assignee
  const showDescription = checkVisibility(Key.Subject) && !!description
  const showName = checkVisibility(Key.Name) && !!name
  const showProducts = checkVisibility(Key.Product) && !!products.length

  const showDetails = showAssignee || showDescription || showName

  const reservationDate = reservationStart ? moment(reservationStart) : null
  const taskDate = dueDate ? moment(dueDate) : null
  const taskTime = dueTime ? dueTime.slice(0, -3) : '–'

  const timeLabel = `${
    taskDate && reservationDate && !reservationDate.isSame(taskDate, 'day')
      ? taskDate.format('DD.MM. ')
      : ''
  }${taskTime}`

  return (
    <Wrapper isNested={isNested}>
      <Time isNested={isNested}>{timeLabel}</Time>

      <DetailsWrapper isNested={isNested}>
        <FlexColumn alignItems="flex-start">
          <span>
            {showName && name}

            {showAssignee && showName && ', '}

            {showAssignee && (assignee as UserGroup).name}

            {(showAssignee || showName) && showDescription && ' – '}

            {showDescription && description}
          </span>

          {showProducts && (
            <Details shiftTop={!showDetails}>
              <DetailsRow flex={1}>
                <Subtitle>
                  <T>SalesDetails:PrintSale.label.PRODUCT</T>
                </Subtitle>

                <FlexColumn flex={1}>
                  {products.map((p) => (
                    <ProductRow
                      flex={1}
                      justifyContent="space-between"
                      key={p.id}
                    >
                      <span>{p.product.name}</span>

                      {showPrice && (
                        <span style={{ fontWeight: 500 }}>
                          {formatCurrency(p.totalPrice.amountVatIncluded)} €
                        </span>
                      )}
                    </ProductRow>
                  ))}
                </FlexColumn>
              </DetailsRow>
            </Details>
          )}
        </FlexColumn>
      </DetailsWrapper>
    </Wrapper>
  )
}

////////////

const Details = styled(FlexColumn)<{ shiftTop: boolean }>`
  font-size: 1rem;
  width: 100%;

  ${({ theme, shiftTop }) =>
    shiftTop &&
    css`
      margin-top: -${theme.spacing.gu(0.5)}rem;
    `}
`

const DetailsRow = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `}
`

const DetailsWrapper = styled.td<{ isNested?: boolean }>`
  white-space: pre-wrap;

  ${({ isNested, theme }) => css`
    padding-top: ${theme.spacing.gu(isNested ? 0 : 1)}rem;
  `}
`

const ProductRow = styled(FlexRow)`
  width: 100%;
`

const Subtitle = styled.span`
  font-weight: 500;
  text-align: end;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(10)}rem;
    padding-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Time = styled.td<{ isNested?: boolean }>`
  text-align: end;
  vertical-align: top;

  ${({ isNested, theme }) => css`
    padding-top: ${theme.spacing.gu(isNested ? 0 : 1)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(22)}rem;
  `}
`

const Wrapper = styled.tr<{ isNested?: boolean }>`
  page-break-inside: avoid;

  ${({ isNested }) =>
    isNested &&
    css`
      font-size: 1rem;
    `}
`
