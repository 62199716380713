import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  AddCreditCardPaymentMutation,
  AddCreditCardPaymentMutationVariables,
} from '~generated-types'

const ADD_CREDIT_CARD_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation AddCreditCardPayment($input: PaymentCreditCardAddInput!) {
    paymentCreditCardAdd(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useAddCreditCardPaymentMutation = () =>
  useMutation<
    AddCreditCardPaymentMutation,
    AddCreditCardPaymentMutationVariables
  >(ADD_CREDIT_CARD_PAYMENT_MUTATION)
