import { useMemo, useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { MobileSalesMeal } from '@/modules/Mobile/SalesList'

import { formatMealTime } from '../utils'
import {
  ChevronIcon,
  Description,
  Details,
  DetailsTitle,
  DetailsWrapper,
  Time,
  Wrapper,
} from './common'

type Props = {
  data: MobileSalesMeal
}

export const MealRow = ({
  data: { duration, meal, notes, quantities, location, start },
}: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(false)

  const time = formatMealTime(start, duration)

  const quantity = useMemo(
    () => quantities.reduce((acc, x) => acc + x.quantity, 0),
    [quantities]
  )

  const isExpandable = !!notes

  return (
    <Wrapper>
      <Time>{time}</Time>

      <FlexColumn alignItems="flex-start" style={{ width: '100%' }}>
        <FlexRow
          onClick={() => isExpandable && setExpanded(!isExpanded)}
          style={{ width: '100%' }}
        >
          <span style={{ width: '100%' }}>
            <span style={{ fontWeight: 500 }}>{location.name}</span>

            <span style={{ whiteSpace: 'pre-wrap' }}>{'  –  '}</span>

            {quantity > 1 && `${quantity} × `}

            {meal.name}
          </span>

          {isExpandable && (
            <ChevronIcon icon="chevron-up" rotation={isExpanded ? 180 : 90} />
          )}
        </FlexRow>

        <DetailsWrapper isOpen={isExpanded}>
          <Details>
            <DetailsTitle>
              <T>SalesDetails:PrintSale.label.NOTES</T>
            </DetailsTitle>

            <Description>{notes}</Description>
          </Details>
        </DetailsWrapper>
      </FlexColumn>
    </Wrapper>
  )
}
