import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  AddOtherPaymentMutation,
  AddOtherPaymentMutationVariables,
} from '~generated-types'

const ADD_OTHER_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation AddOtherPayment($input: PaymentOtherInput!) {
    paymentOtherAdd(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useAddOtherPaymentMutation = () =>
  useMutation<AddOtherPaymentMutation, AddOtherPaymentMutationVariables>(
    ADD_OTHER_PAYMENT_MUTATION
  )
