import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { Theme, useTheme } from '@/theme'

import { SalesLifecycleFragment, SalesState } from '~generated-types'

import { SaleStateModal } from './SaleStateModal'

type Props = {
  salesId: string
  lifecycle: SalesLifecycleFragment
}

export const SaleStatus = ({ salesId, lifecycle }: Props) => {
  const theme = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <SaleStateModal
          salesId={salesId}
          specifier={lifecycle.specifier}
          onClose={() => setModalOpen(false)}
        />
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <StatusWrapper ref={ref} onClick={() => setModalOpen(true)}>
          <FlexColumn alignItems="flex-start">
            <SaleState color={getColorForState(lifecycle.state, theme).code}>
              <T>{`SalesDetails:Lifecycle.state.${lifecycle.state}`}</T>
            </SaleState>
            <Reason>
              {lifecycle.reason && (
                <T>{`SalesDetails:Lifecycle.reason.${lifecycle.reason}`}</T>
              )}
            </Reason>
          </FlexColumn>
          <FontAwesomeIcon
            color={theme.palette.primary.dark}
            icon="chevron-down"
            size="xs"
            style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
          />
        </StatusWrapper>
      )}
    />
  )
}

const getColorForState = (state: SalesState, theme: Theme) => {
  switch (state) {
    case 'CANCELLED':
      return { code: theme.palette.danger.dark, color: 'danger' }
    case 'DRAFT':
    case 'TEMPORAL':
      return { code: theme.palette.text.dark, color: 'default' }
    case 'CLOSED':
      return { code: theme.palette.success.main, color: 'success' }
    default:
      return { code: theme.palette.primary.dark, color: 'primary' }
  }
}

const SaleState = styled.span`
  font-weight: 600;
  color: ${({ color }) => color};

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const StatusWrapper = styled(FlexRow)`
  height: 100%;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
    border-left: 1px solid ${theme.palette.smoke.main};
    border-right: 1px solid ${theme.palette.smoke.main};
  `}

  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }
`

const Reason = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
