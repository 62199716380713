import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { Input } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { ShareToSalesSelector } from '@/modules/Accommodation/SalesReservationList/components/TargetHeader'
import { EventReservationMutations } from '@/modules/Accommodation/SalesReservationList/ReservationList.mutations'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'

import {
  AccommodationTargetAddSalesMutation,
  AccommodationTargetAddSalesMutationVariables,
  AccommodationTargetRemoveSalesMutation,
  AccommodationTargetRemoveSalesMutationVariables,
  AccommodationTargetSalesInput,
  AccommodationTargetUpdateInput,
  AccommodationTargetUpdateMutation,
  AccommodationTargetUpdateMutationVariables,
  TargetByIdQuery,
  TargetByIdQueryVariables,
} from '~generated-types'

type Props = {
  targetId: string
}

const TargetManager = ({ targetId }: Props) => {
  const { data: sales } = salesHooks.useSalesDetailsContext()
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const { data, error, loading } = useQuery<
    TargetByIdQuery,
    TargetByIdQueryVariables
  >(ParticipantsListQueries.TARGET_BY_ID, {
    variables: {
      id: targetId,
    },
  })
  const [updateTarget] = useMutation<
    AccommodationTargetUpdateMutation,
    AccommodationTargetUpdateMutationVariables
  >(EventReservationMutations.UPDATE_ACCOMMODATION_TARGET)
  const [targetAddSales] = useMutation<
    AccommodationTargetAddSalesMutation,
    AccommodationTargetAddSalesMutationVariables
  >(EventReservationMutations.ACCOMMODATION_TARGET_ADD_SALES)
  const [targetRemoveSales] = useMutation<
    AccommodationTargetRemoveSalesMutation,
    AccommodationTargetRemoveSalesMutationVariables
  >(EventReservationMutations.ACCOMMODATION_TARGET_REMOVE_SALES)

  const target = data?.accommodationTarget
  const targetName = target?.name
  const [name, setName] = useState<string>('')

  useEffect(() => {
    targetName !== name && setName(targetName || '')
  }, [targetName])

  const handleUpdateTarget = (input: AccommodationTargetUpdateInput) =>
    updateTarget({
      variables: {
        input,
      },
    })

  const handleTargetAddSales = (input: AccommodationTargetSalesInput) =>
    targetAddSales({
      variables: {
        input,
      },
    })

  const handleTargetRemoveSales = (input: AccommodationTargetSalesInput) =>
    targetRemoveSales({
      variables: {
        input,
      },
    })

  return (
    <TargetWrapper>
      {loading ? (
        <ReactLoading
          type={'bubbles'}
          height={18}
          width={18}
          color={palette.smoke.main}
        />
      ) : error ? (
        <T>common:error.common</T>
      ) : (
        target && (
          <>
            {!target.default ? (
              <StyledInput
                min={0}
                onBlur={() =>
                  targetName !== name &&
                  handleUpdateTarget({
                    id: target.id,
                    name,
                  })
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    handleUpdateTarget({
                      id: target.id,
                      name,
                    })
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
                placeholder={`${translate(
                  `Accommodation:TargetGroup.target`,
                  language
                )} #${target.sortOrder}`}
                type="text"
                value={name}
              />
            ) : (
              <FlexRow
                alignItems="center"
                style={{
                  maxWidth: `${spacing.gu(24)}rem`,
                  width: '100%',
                }}
              >
                <Chip color="primary" variant={'outlined'}>
                  {target.name || <T>Accommodation:TargetGroup.default</T>}
                </Chip>
              </FlexRow>
            )}

            {(sales.type === 'EVENT' || sales.type === 'ENROLLMENT') &&
              !target.default && (
                <ShareToSalesSelector
                  target={target}
                  handleTargetAddSales={handleTargetAddSales}
                  handleTargetRemoveSales={handleTargetRemoveSales}
                />
              )}
          </>
        )
      )}
    </TargetWrapper>
  )
}

export default TargetManager

const TargetWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(3)}rem;
  `}
`

const StyledInput = styled(Input)`
  &&& {
    border: 0;
    border-radius: 0;
    font-weight: 500;

    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeBase};
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-weight: 400;
      opacity: 1;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }
`
