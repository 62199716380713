import React from 'react'
import random from 'lodash.random'

import {
  ChangelogConnection,
  ChangelogEdge,
  ChangelogEntry,
  QueryVariables,
} from '@/modules/Changelog'

// Contains an array of edges ordered by timestamp (dec)
import mockData from './mock-changelog-data.json'

interface UseChangelogProps {
  /**
   * The temporal value that the entries are grouped by. Provided in minutes
   */
  groupingResolution?: number
  target: string
}

interface UseChangelogHook {
  /**
   * All currently fetched Changelog data in a single array
   */
  data: ChangelogEntry[]
  /**
   * Have we encountered a fetch error?
   */
  error: boolean
  /**
   * All currently fetched Changelog data grouped by the provided
   * groupingResolution value.
   */
  groupedData: ChangelogEntry[][]
  /**
   * Is there more data available.
   */
  hasMore: boolean
  /**
   * Are we loading changelog data.
   */
  loading: boolean
  /**
   * Trigger loading of the next page after the current cursor. Is a noop during
   * active loading.
   */
  loadMore: () => void
}

export default function useChangelog({
  groupingResolution = 10,
  target,
}: UseChangelogProps): UseChangelogHook {
  const GROUPING_RESOLUTION_IN_MS = groupingResolution * 60 * 1000

  const {
    data: queryData,
    error,
    fetchMore,
    loading,
  } = useMockQuery(target, {})

  const data = queryData.edges.map(({ node }) => node)
  const hasMore = queryData.pageInfo.hasNextPage

  const groupedData: ChangelogEntry[][] = data.reduce(
    (acc: ChangelogEntry[][], val) => {
      const tailGroup: ChangelogEntry[] = acc.slice(-1)[0]
      const tailGroupHead: ChangelogEntry | null = tailGroup[0] || null

      // This only happens for the first element
      if (!tailGroupHead) {
        return [[val]]
      }

      const diff = Math.abs(
        new Date(tailGroupHead.timestamp).valueOf() -
          new Date(val.timestamp).valueOf()
      )

      if (diff > GROUPING_RESOLUTION_IN_MS) {
        return [...acc, [val]]
      }

      tailGroup.push(val)

      return acc
    },
    [[]]
  )

  const loadMore = () => {
    fetchMore()
  }

  return {
    data,
    error,
    groupedData,
    hasMore,
    loading,
    loadMore,
  }
}

////////////

function useMockQuery(
  id: string,
  variables: QueryVariables
): {
  data: ChangelogConnection
  error: boolean
  fetchMore: () => Promise<void>
  loading: boolean
} {
  const { after = null, first = null } = variables
  const [edges, setEdges] = React.useState<ChangelogEdge[]>([])
  const [endCursor, setEndCursor] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const [hasNextPage, setHasNextPage] = React.useState<boolean>(true)

  React.useEffect(() => {
    setLoading(true)

    getMockRows(after, first).then((newData) => {
      if (newData.length) {
        setEdges((current) => [...current, ...newData])
        setEndCursor(newData[newData.length - 1].cursor)
        setHasNextPage(true)
      } else {
        setHasNextPage(false)
      }
      setLoading(false)
    })
  }, [after, first, id])

  const handleFetchMore = async () => {
    if (loading) {
      console.warn('Already loading')
      return Promise.reject()
    }

    setLoading(true)

    return getMockRows(endCursor, first).then((newData) => {
      if (newData.length) {
        setEdges((current) => [...current, ...newData])
        setEndCursor(newData[newData.length - 1].cursor)
        setHasNextPage(true)
      } else {
        setHasNextPage(false)
      }
      setLoading(false)
    })
  }

  return {
    data: {
      edges,
      pageInfo: { endCursor, hasNextPage },
    },
    error: false,
    fetchMore: handleFetchMore,
    loading,
  }
}

const getMockRows = async (
  afterCursor: string | null,
  count: number | null
): Promise<ChangelogEdge[]> => {
  const typedMockData: ChangelogEdge[] = mockData as ChangelogEdge[]
  const startIdx = !afterCursor
    ? 0
    : typedMockData.findIndex(({ cursor }) => cursor === afterCursor) + 1

  await new Promise((r) => setTimeout(r, random(500, 2000)))

  return startIdx >= 0
    ? typedMockData.slice(startIdx, startIdx + (count || 100))
    : []
}
