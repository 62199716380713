import React, { Fragment, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { LinkLikeButton } from '@/components/ExtraButtons'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { FontWeight, P } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  ParticipantsListQueries,
  useParticipantsListContext,
  useSaleParticipants,
} from '@/modules/ParticipantsList'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'

import {
  SaleAccommodationQuery,
  SuitableParticipantsQuery,
  SuitableParticipantsQueryVariables,
} from '~generated-types'
import { RoomParticipantMatchLabel } from '~generated-types'

type Props = {
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']
  readOnly?: boolean
  setIsExpanded: (isExpanded: boolean) => void
}

export const AddParticipantAction = ({
  room,
  readOnly,
  setIsExpanded,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { data: saleData } = salesHooks.useSalesDetailsContext()
  const { handleAddParticipant } = useSaleParticipants()
  const { handleAddRoom, setProcessing } = useParticipantsListContext()

  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const roomParticipants = useMemo(
    () => room.participantRooms.map(({ participant }) => participant),
    [room]
  )

  const [loadParticipants, { data, loading, error }] = useLazyQuery<
    SuitableParticipantsQuery,
    SuitableParticipantsQueryVariables
  >(ParticipantsListQueries.ROOM_SUITABLE_PARTICIPANTS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: room.id,
      input: {
        filter: RoomParticipantMatchLabel.Context,
      },
    },
  })

  const suitableParticipants =
    data?.accommodationRoomReservation?.suitableParticipants.map(
      ({ participant }) => participant
    ) || []

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal style={{ width: '380px' }}>
          <InlineModalSection style={{ margin: 0, padding: 0 }}>
            <FlexColumn
              flex={1}
              alignItems={'center'}
              style={{ padding: `${spacing.gu(2)}rem 0` }}
            >
              {(saleData?.type === 'SALES' ||
                saleData?.type === 'ENROLLMENT') && (
                <LinkLikeButton
                  onClick={() => {
                    setProcessing(true)
                    handleAddParticipant({ salesId: saleData?.id })
                      .then(
                        (res) =>
                          res &&
                          handleAddRoom({
                            id: res.id,
                            roomReservationId: room.id,
                          })
                      )
                      .then(() => {
                        setProcessing(false)
                        setIsExpanded(true)
                      })
                  }}
                >
                  <T>ParticipantsList:create</T>
                </LinkLikeButton>
              )}
              {loading ? (
                <ReactLoading
                  type={'bubbles'}
                  height={18}
                  width={18}
                  color={palette.smoke.main}
                />
              ) : error ? (
                <T>common:error.common</T>
              ) : suitableParticipants.length > 0 ? (
                suitableParticipants.map(
                  ({
                    id,
                    age,
                    ageCategory,
                    birthday,
                    firstName,
                    lastName,
                    gender,
                    services,
                  }) => {
                    const rooms = services
                      .filter(
                        (service) =>
                          service.__typename === 'ServiceParticipantBed' &&
                          !!service.participantRoom
                      )
                      .map((service) =>
                        service.__typename === 'ServiceParticipantBed'
                          ? service.participantRoom
                          : null
                      )

                    return (
                      <OptionWrapper
                        key={`existing-participant-room-selector-${id}`}
                        onClick={() => {
                          const serviceWithNoRoom = services.find(
                            (service) =>
                              service.__typename === 'ServiceParticipantBed' &&
                              !service.participantRoom
                          )
                          setProcessing(true)
                          handleAddRoom({
                            id: id,
                            roomReservationId: room.id,
                            serviceId: serviceWithNoRoom
                              ? serviceWithNoRoom.id
                              : null,
                          }).then(() => {
                            setProcessing(false)
                            setIsExpanded(true)
                            setModalOpen(false)
                          })
                        }}
                      >
                        {firstName || lastName ? (
                          <>
                            {firstName && `${firstName} `}
                            {lastName && `${lastName} `}
                          </>
                        ) : (
                          <FontWeight light style={{ fontStyle: 'italic' }}>
                            <T>ParticipantsList:noName</T>
                          </FontWeight>
                        )}

                        {age &&
                          ` • ${age} ${translate(
                            'ParticipantsList:ParticipantFormFields.years',
                            language
                          )} `}
                        {ageCategory && ` • ${ageCategory.name} `}
                        {birthday &&
                          ` • ${birthday.date}/${birthday.month}/${birthday.year} `}

                        {gender &&
                          ` • ${translate(
                            `enums:gender.${gender}`,
                            language
                          )} `}

                        <FontWeight style={{ fontStyle: 'italic' }}>
                          {!!rooms.length ? (
                            <>
                              {` • ${translate(
                                `ParticipantsList:ParticipantRooms.rooms`,
                                language
                              )}: `}
                              {rooms.map((room, index: number) => (
                                <Fragment
                                  key={`existing-participant-rooms${room?.id}`}
                                >
                                  {index !== 0 && `, `}
                                  {`#${room?.roomReservation.request.room.number}`}
                                </Fragment>
                              ))}
                            </>
                          ) : (
                            <FontWeight light>
                              {' • '}
                              <T>ParticipantsList:ParticipantRooms.noRooms</T>
                            </FontWeight>
                          )}
                        </FontWeight>
                      </OptionWrapper>
                    )
                  }
                )
              ) : (
                <T>ParticipantsList:empty</T>
              )}
            </FlexColumn>
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <ParticipantSelectorWrapper
          ref={ref}
          disabled={readOnly}
          style={{ padding: `0 ${spacing.gu(1)}rem` }}
          onClick={
            !readOnly
              ? () => {
                  loadParticipants()
                  setModalOpen(true)
                }
              : () => null
          }
        >
          <FlexRow
            style={{
              marginRight: `${spacing.gu(1)}rem`,
              minWidth: '40px',
            }}
            alignItems={'center'}
          >
            <FontAwesomeIcon
              fixedWidth
              icon="user-group"
              style={{ marginRight: `${spacing.gu(1)}rem` }}
              color={
                roomParticipants.length ===
                room.request.beds + room.request.extraBeds
                  ? palette.success.main
                  : palette.gold
              }
            />
            <FontWeight
              style={{
                color: palette.primary.dark,
                marginRight: spacing.gutterSmall,
              }}
            >
              {roomParticipants.length}
            </FontWeight>
          </FlexRow>
          <P
            style={{
              color: palette.text.light,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {roomParticipants.length ? (
              roomParticipants.map(({ firstName, lastName, id }, index) => (
                <React.Fragment key={`name-preview-${id}`}>
                  {firstName || lastName ? (
                    <FontWeight>
                      {firstName && `${firstName}`}
                      {lastName && ` ${lastName}`}
                    </FontWeight>
                  ) : (
                    <FontWeight
                      semiBold
                      style={{
                        fontStyle: 'italic',
                      }}
                    >
                      <T>ParticipantsList:noName</T>
                    </FontWeight>
                  )}
                  {index < roomParticipants.length - 1 && `, `}
                </React.Fragment>
              ))
            ) : (
              <FontWeight
                style={{
                  color: palette.smoke.dark,
                  fontStyle: 'italic',
                  paddingLeft: `${spacing.gu(0.5)}rem`,
                }}
              >
                <T>ParticipantsList:addParticipant</T>
              </FontWeight>
            )}
          </P>
        </ParticipantSelectorWrapper>
      )}
    />
  )
}

const OptionWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  transition: 0.1s;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
    background-color: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const ParticipantSelectorWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  flex: 1;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};
  transition: all 0.1s ease-out;
  border-radius: 8px;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
    border: 2px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
