import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { MobileSalesCustomerAddress } from '@/modules/Mobile/SalesList'

type Props = {
  address: MobileSalesCustomerAddress
}

export const Address = ({ address }: Props) => (
  <Wrapper>
    <Title>
      <T>SalesDetails:PrintSale.label.ADDRESS</T>
    </Title>

    {address.address1 && <span>{address.address1}</span>}
    {address.address2 && <span>{address.address2}</span>}
    {(address.postcode || address.city) && (
      <span>
        {address.postcode ? `${address.postcode} ` : ''}
        {address.city}
      </span>
    )}
    {address.country && <span>{address.country}</span>}
  </Wrapper>
)

/////////

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(3)}rem 0;
  `}
`
