import React, { ReactNode } from 'react'
import { SelectedFilters as LibrarySelectedFilters } from '@appbaseio/reactivesearch'
import type { SelectedFiltersProps } from '@appbaseio/reactivesearch/lib/components/basic/SelectedFilters'
import styled, { createGlobalStyle, css } from 'styled-components/macro'

import { T } from '@/modules/Language'

const buttonClassName = 'rs-selected-filters-button'

interface Props extends SelectedFiltersProps {
  l10nPrefixes?: Record<string, string>
}

type SelectedFilterType = {
  componentType: string | null | undefined
  label: string
  showFilter: boolean
  value: string
}

export const SelectedFilters = ({ l10nPrefixes, ...props }: Props) => {
  return (
    <>
      <DataSearchStyles />
      <LibrarySelectedFilters
        {...props}
        innerClass={{ button: buttonClassName }}
        render={({
          clearAllLabel,
          clearValues,
          innerClass,
          selectedValues,
          setValue,
          showClearAll,
          theme,
        }) => {
          const clearFilter = (key: string) => {
            setValue(key, null)
          }

          const selected = Object.keys(selectedValues)
            .map((key) => ({
              ...(selectedValues[key] as SelectedFilterType),
              key,
            }))
            .filter(
              ({ showFilter, value }) =>
                showFilter && (Array.isArray(value) ? !!value.length : !!value)
            )

          const clearAllAvailable =
            showClearAll === true ||
            showClearAll === 'always' ||
            (showClearAll === 'default' && !!selected.length)

          return (
            <CustomRenderWrapper
              borderColor={theme?.colors?.borderColor || 'inherit'}
            >
              {selected.map(({ componentType, key, label, value }) => (
                <ClearButton
                  className={innerClass.button}
                  key={`selected-filter-${key}`}
                  label={label}
                  l10nPrefix={l10nPrefixes?.[key]}
                  onClick={() => clearFilter(key)}
                  value={formatValue(componentType, value)}
                />
              ))}
              {clearAllAvailable && (
                <button
                  className={innerClass.button}
                  onClick={() => clearValues()}
                >
                  {clearAllLabel}
                </button>
              )}
            </CustomRenderWrapper>
          )
        }}
      />
    </>
  )
}

////////////

const formatValue = (componentType: string | null | undefined, value: any) => {
  if (componentType === 'DYNAMICRANGESLIDER') {
    if (Array.isArray(value) && value.length === 2) {
      return `${value[0]}–${value[1]}`
    }
  }

  return value
}

type ClearButtonProps = {
  className: string | null | undefined
  label: ReactNode
  l10nPrefix: string | null | undefined
  onClick: (...args: Array<any>) => any
  value: string | string[]
}

const ClearButton = ({
  className,
  label,
  l10nPrefix,
  onClick,
  value,
}: ClearButtonProps) => {
  const renderValue = (value: string, isNotFirst: boolean) => (
    <React.Fragment key={`clear-value-${value}`}>
      {isNotFirst && ', '}
      {l10nPrefix ? (
        <T key={`${l10nPrefix}.${value}`} l10n={`${l10nPrefix}.${value}`} />
      ) : (
        value
      )}
    </React.Fragment>
  )

  const renderValues = (label: ReactNode, value: string | string[]) => (
    <span>
      {label}
      {': '}
      {Array.isArray(value)
        ? value.map((v, idx) => renderValue(v, idx > 0))
        : renderValue(value, false)}
    </span>
  )

  return (
    <button className={className || ''} onClick={onClick}>
      {renderValues(label, value)}
      <span>✕</span>
    </button>
  )
}

const DataSearchStyles = createGlobalStyle`
  .${buttonClassName} {
    &&& {
      border-radius: 6px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.dark};
        color: ${theme.palette.text.main};
        font-family: ${theme.typography.fontFamily};
        font-size: ${theme.typography.fontSizeSmall};
      `}

      &:hover {
        ${({ theme }) => css`
          background: ${theme.palette.smoke.lighter};
        `}
      }

      &:focus,
      &:active {
        ${({ theme }) => css`
          background: ${theme.palette.smoke.light};
        `}
      }

      & > span:first-child {
        max-width: 420px;
      }
    }
  }
`

interface CustomRenderWrapperProps {
  borderColor: string
}

const CustomRenderWrapper = styled.div<CustomRenderWrapperProps>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -3px;
  max-width: 100%;
  // Always reserve space for at least one row of buttons (→28px + 4px margin)
  min-height: 32px;

  & > button {
    margin: 2px 3px;
    padding: 5px 8px;
    font-size: 0.85rem;
    position: relative;
    display: inline-flex;
    height: 28px;
  }

  & > button span {
    line-height: 1.2rem;
  }

  & > button span:first-child {
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 26px;
  }

  & > button span:last-child {
    display: flex;
    height: 100%;
    top: 0;
    right: 8px;
    position: absolute;
    align-items: center;
    border-left: 1px solid ${({ borderColor }) => borderColor};
    padding-left: 8px;
    margin-left: 8px;
  }

  & > button:focus span:first-child,
  & > button:hover span:first-child {
    -webkit-text-decoration: line-through;
    text-decoration: line-through;
  }
`
