import { FlexRow } from '@/components/Layout'
import { useLanguageContext } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'

import {
  SalesForPrintParticipant,
  SalesForPrintParticipantServiceBed,
  SalesPrintConfigKey as Key,
} from '../../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../../utils'
import { formatRoom, getFullName, getTargetName } from '../../../utils'
import { GuestDetails } from '../GuestDetails'
import { Section, Wrapper } from './common'

type Props = {
  data: SalesForPrintParticipant
}

export const GuestRow = ({ data }: Props) => {
  const { age, ageCategory, firstName, group, lastName, services } = data

  const { language } = useLanguageContext()
  const {
    salesPrintConfig: config,
    data: { groups },
  } = useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Participants}.${key}`)

  const isGroupsAvailable = !!groups?.values.length

  const [firstService, ...nextServices] = services.filter(
    (s) => s.__typename === 'ServiceParticipantBed'
  ) as SalesForPrintParticipantServiceBed[]

  const dates = firstService?.dates
  const room =
    firstService?.participantRoom?.roomReservation.request.room.number
  const target = firstService?.accommodationTarget

  return (
    <Wrapper>
      <FlexRow>
        {checkVisibility(Key.Name) && (
          <Section style={{ fontWeight: 600, width: '12rem' }}>
            {getFullName(firstName, lastName) || '–'}
          </Section>
        )}

        {checkVisibility(Key.Age) && (
          <Section flex={0.5}>
            {age ?? ageCategory?.abbreviation ?? '–'}
          </Section>
        )}

        {checkVisibility(Key.Target) && (
          <Section flex={1.5}>
            {target ? getTargetName(target, language) : '–'}
          </Section>
        )}

        {checkVisibility(Key.Dates) && (
          <Section flex={1.5}>
            {dates
              ? formatDate(dates.checkIn.date, dates.checkOut.date, 'short')
              : '–'}
          </Section>
        )}

        {checkVisibility(Key.Room) && (
          <Section flex={1.5}>{room ? formatRoom(room) : '–'}</Section>
        )}

        {checkVisibility(Key.Group) && isGroupsAvailable && (
          <Section flex={1.5}>
            <span style={{ whiteSpace: 'pre-wrap' }}>{group || '–'}</span>
          </Section>
        )}
      </FlexRow>

      {nextServices.map(
        ({ accommodationTarget: target, dates, id, participantRoom }) => {
          const room = participantRoom?.roomReservation.request.room.number

          return (
            <FlexRow key={id} style={{ marginTop: '0.5rem' }}>
              {checkVisibility(Key.Name) && (
                <Section style={{ width: '12rem' }} />
              )}

              {checkVisibility(Key.Age) && <Section flex={0.5} />}

              {checkVisibility(Key.Target) && (
                <Section flex={1.5}>
                  {target ? getTargetName(target, language) : '–'}
                </Section>
              )}

              {checkVisibility(Key.Dates) && (
                <Section flex={1.5}>
                  {dates
                    ? formatDate(
                        dates.checkIn.date,
                        dates.checkOut.date,
                        'short'
                      )
                    : '–'}
                </Section>
              )}

              {checkVisibility(Key.Room) && (
                <Section flex={1.5}>{room ? formatRoom(room) : '–'}</Section>
              )}

              {checkVisibility(Key.Group) && isGroupsAvailable && (
                <Section flex={1.5} />
              )}
            </FlexRow>
          )
        }
      )}

      <GuestDetails data={data} />
    </Wrapper>
  )
}
