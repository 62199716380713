import { createContext, ReactNode, useContext, useRef, useState } from 'react'
import { ApolloCache, ApolloError } from '@apollo/client'

import { DangerColor } from '@/components/Colors'
import { useDialogService } from '@/components/DialogService'
import { FlexColumn } from '@/components/Layout'
import { notify } from '@/components/NotificationService'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import {
  ExtractPurchaseMutation,
  ExtractTargetRoom,
  PurchaseProductUpdateInput,
  SalesProductUpdateInput,
  TargetType,
} from '~generated-types'

import { useSalesProducts } from '../../hooks'
import {
  useAddPurchaseFromSalesProductMutation,
  useCopySalesProductToCatalogMutation,
  useCopySalesProductToSalesMutation,
  useCreateSalesProductMutation,
  useDeletePurchaseMutation,
  useDeleteSalesProductMutation,
  useExtractPurchaseMutation,
  useUpdatePurchaseMutation,
  useUpdateSalesProductMutation,
} from '../../mutations'
import { productQueries } from '../../queries'
import {
  CatalogProduct,
  ProductPurchase,
  SalesProduct,
  SalesProductsPayload,
} from '../../types'
import { ListViewMode } from './types'

type ExtractPurchaseInput = {
  productId: string
  purchaseId: string
  quantity?: number
  rooms?: ExtractTargetRoom[]
}

type ContextType = {
  // Data
  error?: ApolloError
  listViewMode: ListViewMode
  loading: boolean
  products: SalesProduct[]
  // Methods
  addPurchase: (productId: string) => Promise<ProductPurchase | undefined>
  copySalesProduct: (productId: string) => Promise<SalesProduct | undefined>
  createProduct: (catalogProductId: string) => Promise<SalesProduct | undefined>
  deleteProduct: (productId: string) => Promise<string | undefined>
  deleteProductWithConfirm: (productId: string) => Promise<string | undefined>
  deletePurchase: (purchaseId: string) => Promise<string | undefined>
  deletePurchaseWithConfirm: (purchaseId: string) => Promise<string | undefined>
  extractPurchase: (
    input: ExtractPurchaseInput
  ) => Promise<ExtractPurchaseMutation['purchaseProductExtract'] | undefined>
  saveSalesProduct: (
    productId: string,
    catalogId: string
  ) => Promise<CatalogProduct | undefined>
  setListViewMode: (listViewMode: ListViewMode) => void
  updateProduct: (
    input: SalesProductUpdateInput
  ) => Promise<SalesProduct | undefined>
  updatePurchase: (
    input: PurchaseProductUpdateInput
  ) => Promise<ProductPurchase | undefined>
}

const SalesProductListContext = createContext<ContextType>({
  addPurchase: () => Promise.reject(),
  copySalesProduct: () => Promise.reject(),
  createProduct: () => Promise.reject(),
  deleteProduct: () => Promise.reject(),
  deleteProductWithConfirm: () => Promise.reject(),
  deletePurchase: () => Promise.reject(),
  deletePurchaseWithConfirm: () => Promise.reject(),
  error: undefined,
  extractPurchase: () => Promise.reject(),
  listViewMode: ListViewMode.Products,
  loading: false,
  products: [],
  saveSalesProduct: () => Promise.reject(),
  setListViewMode: () => undefined,
  updateProduct: () => Promise.reject(),
  updatePurchase: () => Promise.reject(),
})

type Props = {
  children: ReactNode
}

export const SalesProductListContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { confirm } = useDialogService()

  const {
    data: { id: salesId },
  } = useSalesDetailsContext()

  const { error, loading, products } = useSalesProducts({ salesId })

  const [listViewMode, setListViewMode] = useState<ListViewMode>(
    ListViewMode.Products
  )

  const [addPurchaseMutation] = useAddPurchaseFromSalesProductMutation()
  const [createProductMutation] = useCreateSalesProductMutation()
  const [extractPurchaseMutation] = useExtractPurchaseMutation()
  const [copySalesProductMutation] = useCopySalesProductToSalesMutation()
  const [deleteProductMutation] = useDeleteSalesProductMutation()
  const [deletePurchaseMutation] = useDeletePurchaseMutation()
  const [saveSalesProductMutation] = useCopySalesProductToCatalogMutation()
  const [updateProductMutation] = useUpdateSalesProductMutation()
  const [updatePurchaseMutation] = useUpdatePurchaseMutation()

  const addPurchase = (productId: string) =>
    addPurchaseMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: cachedSales.sales.products.map((p) =>
                p.id === productId
                  ? {
                      ...p,
                      purchases: [
                        ...p.purchases,
                        data.purchaseProductAddFromSalesProduct,
                      ],
                    }
                  : p
              ),
            })
          )
        }
      },
      variables: {
        input: { add: { link: { salesId } }, salesProductId: productId },
      },
    })
      .then(({ data }) => data?.purchaseProductAddFromSalesProduct)
      .catch(() => undefined)

  const copySalesProduct = (productId: string) =>
    copySalesProductMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: [
                ...cachedSales.sales.products,
                data.salesProductCopyToSales,
              ],
            })
          )
        }
      },
      variables: { input: { id: productId, salesId } },
    })
      .then(({ data }) => {
        if (data) {
          notify({
            content: (
              <FlexColumn>
                <span style={{ fontWeight: 500, marginBottom: 2 }}>
                  {data.salesProductCopyToSales.name}
                </span>
                <T>Products:Notification.copyProduct</T>
              </FlexColumn>
            ),
            type: 'SUCCESS',
          })
        }

        return data?.salesProductCopyToSales
      })
      .catch(() => undefined)

  const createProduct = (catalogProductId: string) =>
    createProductMutation({
      // Manually update GraphQL cache after creating
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: [
                ...cachedSales.sales.products,
                data.salesProductCreate,
              ],
            })
          )
        }
      },
      variables: { input: { catalogProductId, salesId } },
    })
      .then(({ data }) => {
        if (data && listViewMode === ListViewMode.Purchases) {
          return addPurchase(data.salesProductCreate.id).then(
            () => data.salesProductCreate
          )
        }

        return data?.salesProductCreate
      })

      .catch(() => undefined)

  const deleteProduct = (productId: string) =>
    deleteProductMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'SalesProduct',
            id: data.salesProductDelete.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: productId },
    })
      .then(({ data }) => data?.salesProductDelete.id)
      .catch(() => undefined)

  const deleteProductWithConfirm = (productId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deleteProduct.description</T>,
      title: <T>Products:Confirmation.deleteProduct.title</T>,
    })
      .then(() => deleteProduct(productId))
      .catch(() => undefined)

  const deletePurchase = (purchaseId: string) =>
    deletePurchaseMutation({
      // Manually update GraphQL cache after deleting
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'PurchaseProduct',
            id: data.purchaseProductDelete.id,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { id: purchaseId },
    })
      .then(({ data }) => data?.purchaseProductDelete.id)
      .catch(() => undefined)

  const deletePurchaseWithConfirm = (purchaseId: string) =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>Products:ProductManager.action.delete</T>
        </DangerColor>
      ),
      description: <T>Products:Confirmation.deletePurchase.description</T>,
      title: <T>Products:Confirmation.deletePurchase.title</T>,
    })
      .then(() => deletePurchase(purchaseId))
      .catch(() => undefined)

  const extractPurchase = ({
    productId,
    purchaseId,
    quantity,
    rooms,
  }: ExtractPurchaseInput) =>
    extractPurchaseMutation({
      update(cache, { data }) {
        const cachedSales = getCachedSales(cache, salesId)

        if (cachedSales && data) {
          const getPurchases = (purchases: ProductPurchase[]) => {
            const { source, extracted } = data.purchaseProductExtract

            const newPurchases = [...purchases, ...extracted]

            if (source) {
              return newPurchases.map((p) => (p.id === purchaseId ? source : p))
            }

            return newPurchases.filter(({ id }) => id !== purchaseId)
          }

          cache.writeQuery<SalesProductsPayload>(
            updateSalesProductsCache({
              cache: cachedSales,
              products: cachedSales.sales.products.map((p) =>
                p.id === productId
                  ? { ...p, purchases: getPurchases(p.purchases) }
                  : p
              ),
            })
          )
        }
      },
      variables: {
        input: { purchaseId, quantity, rooms, target: TargetType.Participant },
      },
    })
      .then(({ data }) => data?.purchaseProductExtract)
      .catch(() => undefined)

  const saveSalesProduct = (productId: string, catalogId: string) =>
    saveSalesProductMutation({
      variables: { input: { copyTo: { catalogId }, id: productId } },
    })
      .then(({ data }) => {
        if (data) {
          notify({
            content: (
              <FlexColumn>
                <span style={{ fontWeight: 500, marginBottom: 2 }}>
                  {data.salesProductCopyToCatalog.name}
                </span>
                <T>Products:Notification.saveProduct</T>
                <span style={{ fontWeight: 500, marginTop: 2 }}>
                  {data.salesProductCopyToCatalog.catalog.name}
                </span>
              </FlexColumn>
            ),
            type: 'SUCCESS',
          })
        }

        return data?.salesProductCopyToCatalog
      })
      .catch(() => undefined)

  const updateProduct = (input: SalesProductUpdateInput) =>
    updateProductMutation({ variables: { input } })
      .then(({ data }) => data?.salesProductUpdate)
      .catch(() => undefined)

  const updatePurchase = (input: PurchaseProductUpdateInput) =>
    updatePurchaseMutation({ variables: { input } })
      .then(({ data }) => data?.purchaseProductUpdate)
      .catch(() => undefined)

  contextValueRef.current = {
    addPurchase,
    copySalesProduct,
    createProduct,
    deleteProduct,
    deleteProductWithConfirm,
    deletePurchase,
    deletePurchaseWithConfirm,
    error,
    extractPurchase,
    listViewMode,
    loading,
    products,
    saveSalesProduct,
    setListViewMode,
    updateProduct,
    updatePurchase,
  }

  return (
    <SalesProductListContext.Provider value={contextValueRef.current}>
      {children}
    </SalesProductListContext.Provider>
  )
}

export const useSalesProductListContext = () =>
  useContext(SalesProductListContext)

/////

const getCachedSales = (cache: ApolloCache<any>, salesId: string) =>
  cache.readQuery<SalesProductsPayload>({
    query: productQueries.GET_SALES_PRODUCTS,
    variables: { id: salesId },
  })

const updateSalesProductsCache = ({
  cache,
  products,
}: {
  cache: SalesProductsPayload
  products: SalesProduct[]
}) => ({
  data: {
    ...cache,
    sales: {
      ...cache.sales,
      products,
    },
  },
  query: productQueries.GET_SALES_PRODUCTS,
})
