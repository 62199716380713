import { CSSProperties, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InlineModalIconSection } from '@/components/InlineModal'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { UserGroup, useUserGroups } from '@/modules/Registry'
import type { TaskAssignee } from '@/modules/Task/types'
import { Theme, useTheme } from '@/theme'

import type { AssigneeInput } from '~generated-types'

import { EditButton, Placeholder } from './common'

type Props = {
  assignee: TaskAssignee | null | undefined
  readOnly: boolean
  updateAssignee: (newValue: AssigneeInput | null) => Promise<any>
}

export const Assignee = ({ updateAssignee, readOnly, assignee }: Props) => {
  const theme = useTheme()

  const { userGroups } = useUserGroups()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const handleSelectAssignee = (target: AssigneeInput | null) => {
    setProcessing(true)

    updateAssignee(target)
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        setEditMode(false)
        setHover(false)
      })
  }

  const options = userGroups.map((group: UserGroup) => ({
    label: group.name,
    searchValue: group.name,
    value: group.id,
  }))

  return (
    <InlineModalIconSection icon={['far', 'user']}>
      {isEditMode ? (
        <>
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(theme)}
            isClearable
            isCompact
            isLoading={processing}
            isSearchable
            menuIsOpen={isEditMode}
            name="assignee-selector"
            noOptionsMessage={() => (
              <T>SalesDetails:dimensions.error.emptyLabels</T>
            )}
            onBlur={() => {
              setEditMode(false)
              setHover(false)
            }}
            onChange={(selected: Option | null | undefined) =>
              handleSelectAssignee(
                // @ts-ignore
                selected && { id: selected.value, type: 'USER_GROUP' }
              )
            }
            options={options}
            placeholder={<T>Tasks:Task.assigneePlaceholder</T>}
            value={
              assignee
                ? {
                    label: (assignee as UserGroup).name,
                    value: (assignee as UserGroup).id,
                  }
                : null
            }
          />
        </>
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {assignee ? (
            (assignee as UserGroup).name
          ) : (
            <Placeholder>
              <T>Tasks:Task.assigneePlaceholder</T>
            </Placeholder>
          )}

          {isHover && (
            <FontAwesomeIcon
              color={theme.palette.text.lighter}
              icon="pen"
              size="sm"
            />
          )}
        </EditButton>
      )}
    </InlineModalIconSection>
  )
}

////////////

const getExtraStyles = (theme: Theme) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${theme.spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${theme.spacing.gu(1)}rem`,
    width: `calc(100% + ${theme.spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
})
