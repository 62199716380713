import React, { useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'

import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { Gutter } from '@/components/Layout'
import type { SortOption } from '@/components/Reactivesearch'
import { translate, useLanguageContext } from '@/modules/Language'
import { ElasticSales, salesQueries, SearchListContext } from '@/modules/Sales'

import {
  SalesPaymentInfoQuery,
  SalesPaymentInfoQueryVariables,
} from '~generated-types'

import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'

const SALES_INDEX = 'sales.sales'

type SalesSearchListProps = {
  context: SearchListContext
  hideFilters?: boolean
  componentIds: {
    [key: string]: string
  }
  listTitle: React.ReactNode
  defaultQueryFn: () => {
    [key: string]: unknown
  }
  renderFilters?: () => React.ReactNode
}

export const SalesSearchList = ({
  context,
  hideFilters,
  componentIds,
  listTitle,
  defaultQueryFn,
  renderFilters,
}: SalesSearchListProps) => {
  const { language } = useLanguageContext()

  const history = useHistory()
  const { pathname } = useLocation()

  const allElasticDataIds = useRef<string[]>([])

  const handleOpenItem = (itemId: string) => {
    // We need to use window.location.search, because we use ReactiveSearch
    // components in the filters and their URLParams-functionality does not play
    // nicely with search in useLocation()
    const search = window.location.search

    history.push(
      `/sales/details/${itemId}?close-url=${encodeURIComponent(
        `${pathname}${search}`
      )}`
    )
  }

  const sortOptions: SortOption[] = [
    {
      dataField: 'estimatedDates.start',
      direction: 'asc',
      label: translate(
        'SalesSearchList:SortOptions.estimatedDatesAsc',
        language
      ),
    },
    {
      dataField: 'estimatedDates.start',
      direction: 'desc',
      label: translate(
        'SalesSearchList:SortOptions.estimatedDatesDesc',
        language
      ),
    },
    {
      dataField: 'orderNumber',
      direction: 'desc',
      label: translate('SalesSearchList:SortOptions.orderNumberDesc', language),
    },
    {
      dataField: 'customerName.keyword',
      direction: 'asc',
      label: translate('SalesSearchList:SortOptions.customerNameAsc', language),
    },
    {
      dataField: 'name.keyword',
      direction: 'asc',
      label: translate('SalesSearchList:SortOptions.nameAsc', language),
    },
    {
      dataField: 'participants',
      direction: 'desc',
      label: translate(
        'SalesSearchList:SortOptions.participantsDesc',
        language
      ),
    },
    {
      dataField: 'participants',
      direction: 'asc',
      label: translate('SalesSearchList:SortOptions.participantsAsc', language),
    },
  ]

  const [sortProperty, setSortProperty] = React.useState<SortOption | null>(
    context === SearchListContext.SALES ? sortOptions[0] : sortOptions[2]
  )

  const sort = sortProperty
    ? [
        { [sortProperty.dataField]: sortProperty.direction },
        { orderNumber: 'desc' },
      ]
    : { orderNumber: 'desc' }

  const [loadSales, { data: salesData, loading }] = useLazyQuery<
    SalesPaymentInfoQuery,
    SalesPaymentInfoQueryVariables
  >(salesQueries.SALES_PAYMENT_INFO)

  const handleLoadSales = (ids: string[]) =>
    loadSales({
      variables: {
        ids,
      },
    })

  const onElasticData = (data: ElasticSales[]) => {
    if (!data || loading) {
      return
    }

    const gqlDataIds = salesData?.salesAll
      ? salesData.salesAll.map(({ id }) => id)
      : []
    const elasticDataIds = data.map(({ id }) => id)
    const prevElasticDataIds = allElasticDataIds.current

    const isElasticIdsEqual =
      JSON.stringify(prevElasticDataIds.sort()) ===
      JSON.stringify(elasticDataIds.sort())

    if (isElasticIdsEqual) {
      return
    }

    const isIdsEqual =
      JSON.stringify(gqlDataIds.sort()) ===
      JSON.stringify(elasticDataIds.sort())

    if (!isIdsEqual) {
      allElasticDataIds.current = elasticDataIds
      handleLoadSales(elasticDataIds)
    }
  }

  return (
    <Gutter type={[0, 5, 10]}>
      <ElasticFilterSearchList
        columnCount={8}
        indexName={SALES_INDEX}
        reactiveListProps={{
          componentId: componentIds.LIST,
          dataField: 'name',
          defaultQuery: () => ({
            ...defaultQueryFn(),
            sort,
          }),
          exactQueryProps: {
            fields: 'orderNumber.text',
            key: componentIds.SEARCH,
          },
          react: {
            and: Object.keys(componentIds).map((key) => componentIds[key]),
          },
          size: 50,
        }}
        renderListFilters={hideFilters ? undefined : renderFilters || undefined}
        renderListHeader={() => <ListHeader />}
        renderListItem={(i: ElasticSales) => (
          <ListItem
            data={i}
            gqlPaymentData={
              salesData?.salesAll.find(({ id }) => id === i.id) || null
            }
            key={i.id}
            onClick={handleOpenItem}
          />
        )}
        selectedFiltersProps={{
          l10nPrefixes: {
            state: 'SalesDetails:Lifecycle.state',
          },
        }}
        sortProps={{
          options: sortOptions,
          setValue: setSortProperty,
          value: sortProperty,
        }}
        title={listTitle}
        elasticData={onElasticData}
      />
    </Gutter>
  )
}

export default SalesSearchList
