import { ReactNode, useState } from 'react'
import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components/macro'

import { FetchStates } from '@/common/types'
import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow, Gutter } from '@/components/Layout'
import { P } from '@/components/Typography'
import { T } from '@/modules/Language'
import { CompactView } from '@/modules/Sales/components/SaleCompactView'
import { useTheme } from '@/theme'

import Enrollment from './Enrollment'
import { EnrollmentFooter } from './EnrollmentFooter'
import { useEnrollmentsContext } from './EnrollmentsContext'
import { StatesSelector } from './StatesSelector'

type Props = {
  readOnly?: boolean
}

export default function Enrollments({ readOnly }: Props) {
  const theme = useTheme()

  const {
    activeStates,
    handleSetActiveStates,
    addEnrollment,
    enrollments,
    fetchState,
    pagination,
  } = useEnrollmentsContext()
  const [expandedEnrollment, setExpandedEnrollment] = useState<string>('')
  const [processing, setProcessing] = useState<boolean>(false)

  const handleCreate = () => {
    if (!processing) {
      setProcessing(true)

      addEnrollment()
        .then(setExpandedEnrollment)
        .finally(() => setProcessing(false))
    }
  }

  const renderContent = () => {
    switch (fetchState) {
      case FetchStates.ERROR:
        return (
          <Placeholder>
            – <T>SalesDetails:Enrollments.error</T> –
          </Placeholder>
        )
      case FetchStates.LOADING:
        return (
          <ReactLoading
            type={'spin'}
            color={theme.palette.smoke.main}
            height={24}
            width={24}
          />
        )
      case FetchStates.IDLE:
      default:
        return enrollments.length ? (
          enrollments.map((e, index) => (
            <CompactView key={`enrollment-${e.id}`} saleId={e.id}>
              <Enrollment
                data={e}
                isExpanded={e.id === expandedEnrollment}
                isFirst={index === 0}
                isLast={index === enrollments.length - 1}
                readOnly={readOnly}
                setExpanded={() =>
                  setExpandedEnrollment(expandedEnrollment === e.id ? '' : e.id)
                }
              />
            </CompactView>
          ))
        ) : (
          <Placeholder>
            – <T>SalesDetails:Enrollments.empty</T> –
          </Placeholder>
        )
    }
  }

  return (
    <FlexColumn>
      <FlexRow style={{ marginBottom: theme.spacing.gutter }}>
        <StatesSelector
          activeStates={activeStates}
          handleSetActiveStates={handleSetActiveStates}
        />

        <Spacer />

        <InnocuousButton
          compact
          disabled={readOnly || processing}
          onClick={handleCreate}
        >
          <PrimaryColor>
            + <T>SalesDetails:Enrollments.add</T>
          </PrimaryColor>
        </InnocuousButton>
      </FlexRow>

      {renderContent()}

      {pagination.totalPages > 1 && <EnrollmentFooter />}
    </FlexColumn>
  )
}

////////////

type PlaceholderProps = {
  children: ReactNode
}

const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </Gutter>
)

const PlaceholderLabel = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const Spacer = styled.div`
  flex: 1;
`
