import { ApolloClient, gql, useQuery } from '@apollo/client'

import {
  CategorySetsByTargetQuery as QueryData,
  CategorySetsByTargetQueryVariables as QueryVariables,
} from '~generated-types'

import categoryFragments from '../fragments'
import { QUERY as USE_CATEGORY_QUERY } from './useCategory'

type CategorySetWithCategories =
  QueryData['registry']['categorySetsByTarget'][0]

export type CategorySet = CategorySetWithCategories
export type CategorySetCategory = CategorySetWithCategories['rootCategories'][0]

// By default, query categories down four (4) full levels
const QUERY = gql`
  ${categoryFragments.category}

  query CategorySetsByTarget($targetKey: String!) {
    registry {
      categorySetsByTarget(input: { targetKey: $targetKey }) {
        id
        name

        rootCategories {
          ...Category

          children {
            ...Category

            children {
              ...Category

              children {
                ...Category

                children {
                  ...Category
                }
              }
            }
          }
        }
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
  targetKey: string
}

export default function useCategorySetsByTarget({
  forceRefetch,
  targetKey,
}: Params) {
  const { client, data, error, loading, refetch } = useQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
    onCompleted: (res) => {
      // Manually update apollo cache to add all
      // resolved category({ categoryId }) queries.
      // @ts-ignore
      updateCategoriesCache(res, client)
    },
    variables: { targetKey },
  })

  const categorySets: CategorySet[] = data
    ? [...data.registry.categorySetsByTarget].map((x) => ({
        ...x,
        rootCategories: [...x.rootCategories],
      }))
    : []

  return {
    categorySets,
    error,
    loading,
    refetch,
  }
}

////////////

function updateCategoriesCache(res: QueryData, client: ApolloClient<any>) {
  const categories: any[] = []
  const categorySets: CategorySet[] = res
    ? res.registry.categorySetsByTarget
    : []

  const processCategories = (
    input: {
      [key: string]: any
    }[]
  ) =>
    input.forEach((x) => {
      if (Array.isArray(x.children)) {
        categories.push(x)
        processCategories(x.children || [])
      }
    })

  categorySets.forEach(({ rootCategories }) =>
    processCategories(rootCategories)
  )

  categories.forEach((category: any) => {
    client.writeQuery({
      data: {
        registry: {
          __typename: 'Registry',
          category,
        },
      },
      query: USE_CATEGORY_QUERY,
      variables: {
        categoryId: category.id,
      },
    })
  })
}
