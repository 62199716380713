import { useRef } from 'react'
import isNumber from 'lodash.isnumber'

import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { ReactiveComponent } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'

import { TaskStatusFilter } from './TaskStatusFilter'
import { TaskStatus, TaskStatusStats } from './types'

type Props = {
  componentId: string
  filterLabel: string
  isCollapsed: boolean
  l10nPrefix: string
  showFilter: boolean
  URLParams: boolean
}

export const TaskStatusFilterContainer = ({
  componentId,
  filterLabel,
  isCollapsed,
  l10nPrefix,
  showFilter,
  URLParams,
}: Props) => {
  const isInitialised = useRef<boolean>(false)

  const { language } = useLanguageContext()

  const getQuery = (selection: TaskStatus) => {
    const value = translate(`${l10nPrefix}.${selection}`, language)

    if (selection === TaskStatus.All) {
      return {}
    }

    return {
      query: {
        bool: {
          should: {
            bool: {
              must: [{ match: { isOpen: selection === TaskStatus.Open } }],
            },
          },
        },
      },
      value,
    }
  }

  const transformSelectorValueToSelection = (value?: string): TaskStatus => {
    if (value) {
      if (value === translate(`${l10nPrefix}.${TaskStatus.All}`, language)) {
        return TaskStatus.All
      }
      if (value === translate(`${l10nPrefix}.${TaskStatus.Closed}`, language)) {
        return TaskStatus.Closed
      }
      if (value === translate(`${l10nPrefix}.${TaskStatus.Open}`, language)) {
        return TaskStatus.Open
      }
    }

    return TaskStatus.All
  }

  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={() => ({
        aggs: {
          isOpen: {
            terms: {
              field: 'isOpen',
            },
          },
        },
      })}
      filterLabel={filterLabel}
      render={({ aggregations, error, loading, setQuery, value: rawValue }) => {
        if (!aggregations && loading) {
          return <LoadingPlaceholder size="lg" style={{ width: '100%' }} />
        }

        if (error) {
          return (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          )
        }

        const value: TaskStatus = transformSelectorValueToSelection(rawValue)

        // Update the query on first render with data
        if (!isInitialised.current) {
          isInitialised.current = true
          setQuery(getQuery(TaskStatus.Open))
        }

        const stats: TaskStatusStats = {
          [TaskStatus.All]: 0,
          [TaskStatus.Closed]: 0,
          [TaskStatus.Open]: 0,
        }

        if (Array.isArray(aggregations?.isOpen?.buckets)) {
          const closedBucket = aggregations.isOpen.buckets.find(
            (bucket: any) => bucket?.key_as_string === 'false'
          )
          const openBucket = aggregations.isOpen.buckets.find(
            (bucket: any) => bucket?.key_as_string === 'true'
          )

          if (isNumber(openBucket?.doc_count)) {
            stats[TaskStatus.Open] = openBucket.doc_count
          }
          if (isNumber(closedBucket?.doc_count)) {
            stats[TaskStatus.Closed] = closedBucket.doc_count
          }

          stats[TaskStatus.All] =
            stats[TaskStatus.Open] + stats[TaskStatus.Closed]
        }

        return (
          <TaskStatusFilter
            componentId={componentId}
            isCollapsed={isCollapsed}
            l10nPrefix={l10nPrefix}
            stats={stats}
            toggleValue={(state: TaskStatus) => setQuery(getQuery(state))}
            value={value}
          />
        )
      }}
      showFilter={showFilter}
      URLParams={URLParams}
    />
  )
}
