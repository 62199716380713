import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import {
  CountLabel,
  TreeEndCap,
  TreePadding,
  TreeRow,
  TreeRowLabel,
} from '@/components/TreeSelector'
import { DimensionLabel } from '@/modules/Registry'

type Props = {
  data: DimensionLabel
  documentCounts: { [key: string]: number }
  hideCounts?: boolean
  isLast: boolean
  isSelected: boolean
  toggle: () => void
}

const DimensionLabelNode: FC<Props> = ({
  data: { id, name },
  documentCounts,
  hideCounts,
  isLast,
  isSelected,
  toggle,
}): ReactElement => (
  <TreeRow key={`dimensionLabel-${id}`}>
    <TreePadding mode={isLast ? 'half' : 'full'} />
    <TreeEndCap />
    <CheckboxWrapper>
      <CheckboxInput checked={isSelected} noMargin onChange={() => toggle()}>
        <StyledTreeRowLabel>{name}</StyledTreeRowLabel>
        {!hideCounts && <CountLabel>{documentCounts[id] || 0}</CountLabel>}
      </CheckboxInput>
    </CheckboxWrapper>
  </TreeRow>
)

export default DimensionLabelNode

/////////

const CheckboxWrapper = styled(FlexRow)`
  label {
    flex: 1;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const StyledTreeRowLabel = styled(TreeRowLabel)`
  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(31)}rem;
  `}
`
