import styled, { css } from 'styled-components/macro'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales'

import { SignerSelector } from './SignerSelector'

type Props = {
  isExpanded: boolean
  setExpanded: (isExpanded: boolean) => void
}

export const Header = ({ isExpanded, setExpanded }: Props) => {
  const {
    data: { vst },
  } = salesHooks.useSalesDetailsContext()

  const mainCode = vst?.mainCode ?? null
  const sideCode = vst?.sideCode ?? null
  const showSigner = vst?.vstDocumentConfig.showSignature ?? false

  return (
    <FlexRow
      alignItems={mainCode && sideCode ? 'flex-start' : 'center'}
      justifyContent="space-between"
    >
      <FlexColumn alignItems="flex-start" noPadding>
        {mainCode && (
          <FlexColumn>
            <Label>{mainCode.title}</Label>
            <Code alignItems="center">{mainCode.code}</Code>
          </FlexColumn>
        )}

        {mainCode && sideCode && <Spacer />}

        {sideCode && (
          <FlexColumn>
            <Label>{sideCode.title}</Label>
            <Code alignItems="center">{sideCode.code}</Code>
          </FlexColumn>
        )}
      </FlexColumn>

      {(mainCode || sideCode) && showSigner && <Divider />}

      {showSigner && <SignerSelector />}

      <FlexRow justifyContent="flex-end" alignItems="center">
        <InnocuousButton compact onClick={() => setExpanded(!isExpanded)}>
          <PrimaryColor>
            <T>
              {!isExpanded
                ? 'SalesDetails:section.showSummary'
                : 'SalesDetails:section.hideSummary'}
            </T>
          </PrimaryColor>
        </InnocuousButton>
      </FlexRow>
    </FlexRow>
  )
}

/////////

const Code = styled(FlexRow)`
  min-height: 30px;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(50)}rem;
    margin-bottom: -${theme.spacing.gu(1)}rem;
  `}
`

const Divider = styled.div`
  height: 100%;
  min-width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(3)}rem;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(3)}rem;
  `}
`
