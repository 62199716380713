import { CSSProperties, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import styled, { css } from 'styled-components/macro'

import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { salesHooks, salesMutations } from '@/modules/Sales'
import { Spacing, useTheme } from '@/theme'

import {
  SignersForVstQuery as Query,
  SignersForVstQueryVariables as Variables,
} from '~generated-types'

type Signer = Query['sales']['participantConnection']['nodes'][0]

const QUERY = gql`
  query SignersForVST($id: ID!) {
    sales(id: $id) {
      id
      participantConnection(input: { pagination: { size: 10000 } }) {
        nodes {
          firstName
          id
          lastName
          sortOrder
        }
      }
    }
  }
`

export const SignerSelector = () => {
  const { spacing } = useTheme()
  const {
    data: { id, vst },
    saleReadOnly: readOnly,
    refresh,
  } = salesHooks.useSalesDetailsContext()

  const signer = vst?.vstDocumentConfig.documentSigner ?? null

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const [setVstSigner] = salesMutations.useSetVstSignerMutation()

  const { data, loading } = useQuery<Query, Variables>(QUERY, {
    fetchPolicy: 'no-cache',
    skip: !isEditMode,
    variables: { id },
  })

  const signerOptions =
    data?.sales.participantConnection.nodes.sort(compareSignersFn).map((s) => ({
      label: getSignerLabel(s),
      searchValue: `${s.lastName} ${s.firstName}`,
      value: s.id,
    })) || []

  const clearOption = {
    label: (
      <ClearSelectionLabel>
        <T>Reactivesearch:clearSelection</T>
      </ClearSelectionLabel>
    ),
    value: '',
  }

  const options = signer ? [...signerOptions, clearOption] : signerOptions

  const handleUpdateSigner = (signerId: string) => {
    if (signerId === signer?.id) {
      return
    }

    setProcessing(true)

    setVstSigner({
      variables: {
        input: { documentSigner: signerId || null, salesId: id },
      },
    })
      .then(refresh)
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        setEditMode(false)
      })
  }

  return (
    <Wrapper alignItems="flex-start" flex={1} noPadding>
      <Label>
        <T>SalesDetails:VST.vstSigner</T>
      </Label>

      <SelectorWrapper>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={processing || loading}
            isDisabled={readOnly}
            isSearchable
            menuIsOpen={isEditMode}
            name="vst-signer-selector"
            noOptionsMessage={() => <T>SalesDetails:VST.noParticipants</T>}
            onChange={(option?: Option | null) =>
              option && handleUpdateSigner(option.value)
            }
            onBlur={() => setEditMode(false)}
            options={options}
            placeholder="—"
            value={
              signer
                ? {
                    label: getSignerLabel(signer),
                    value: signer.id,
                  }
                : null
            }
          />
        ) : (
          <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
            {signer ? getSignerLabel(signer) : '—'}
          </EditButton>
        )}
      </SelectorWrapper>
    </Wrapper>
  )
}

////////

const compareSignersFn = (a: Signer, b: Signer) => {
  if (a.lastName < b.lastName) {
    return -1
  }

  if (a.lastName > b.lastName) {
    return 1
  }

  return a.firstName < b.firstName ? -1 : 1
}

const getSignerLabel = ({ firstName, lastName }: Signer) =>
  firstName || lastName ? (
    `${lastName}${firstName && lastName ? ' ' : ''}${firstName}`
  ) : (
    <Placeholder>
      <T>ParticipantsList:unnamedParticipant</T>
    </Placeholder>
  )

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const ClearSelectionLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const SelectorWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(28)}rem;
    margin-bottom: -${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
