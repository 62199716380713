import {
  CalendarNestedResourceElastic,
  CalendarResourceElastic,
} from '@/modules/Registry'
import { CalendarViewMode } from '@/modules/Reservations'

import { ResourceDisplayName } from '../components/ResourceDisplayName'
import { GridGroupSection } from '../ReservationsGridState'

type GridGroupSectionProps = {
  isLast: boolean
  isNested: boolean
  parentResourceId?: string
  resource: CalendarResourceElastic | CalendarNestedResourceElastic
  targetDate: string
  viewMode: CalendarViewMode
}

export const getGridGroupSection = ({
  isLast,
  isNested,
  parentResourceId,
  resource,
  targetDate,
  viewMode,
}: GridGroupSectionProps): GridGroupSection => ({
  id: `${resource.id}.${targetDate}`,
  isPooled: (resource as CalendarResourceElastic).isPooled,
  nestedSections: (resource as CalendarResourceElastic).nestedResources?.map(
    (r, idx, arr) =>
      getGridGroupSection({
        isLast: idx === arr.length - 1,
        isNested: true,
        parentResourceId: resource.id,
        resource: r,
        targetDate,
        viewMode,
      })
  ),
  resourceId: resource.id,
  targetDate,
  title: (
    <ResourceDisplayName
      isNested={isNested}
      isLastNested={isLast && isNested}
      isParent={!!(resource as CalendarResourceElastic).nestedResources?.length}
      parentResourceId={parentResourceId}
      resource={resource}
      viewMode={viewMode}
    />
  ),
})
