import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  AddReferencePaymentMutation,
  AddReferencePaymentMutationVariables,
} from '~generated-types'

const ADD_REFERENCE_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation AddReferencePayment($input: PaymentReferenceAddInput!) {
    paymentReferenceAdd(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useAddReferencePaymentMutation = () =>
  useMutation<
    AddReferencePaymentMutation,
    AddReferencePaymentMutationVariables
  >(ADD_REFERENCE_PAYMENT_MUTATION)
