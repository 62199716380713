import { MobileSalesParticipant } from '@/modules/Mobile/SalesList'
import { formatDate, getFullName } from '@/modules/Mobile/SalesList/utils'
import { typeGuardFactory } from '@/utils/types'

import { Content, ContentWrapper } from '../../../components'

type Props = {
  participant: MobileSalesParticipant
  roomDates?: { checkIn: string; checkOut: string }
}

export const RoomParticipantRow = ({
  participant: { firstName, lastName, services },
  roomDates,
}: Props) => {
  const servicesBed = services.filter(
    typeGuardFactory('__typename', 'ServiceParticipantBed')
  )

  const showDates =
    !!servicesBed[0] &&
    (servicesBed[0].dates?.checkIn.date !== roomDates?.checkIn ||
      servicesBed[0].dates?.checkOut.date !== roomDates?.checkOut)

  return (
    <ContentWrapper>
      <Content
        bold
        style={{ paddingLeft: '20px' }}
        width={showDates ? '60%' : '100%'}
      >
        {getFullName(firstName, lastName) || '–'}
      </Content>

      {showDates && (
        <Content width="40%">
          {servicesBed[0].dates &&
            formatDate({
              end: servicesBed[0].dates.checkOut.date,
              start: servicesBed[0].dates.checkIn.date,
            })}
        </Content>
      )}
    </ContentWrapper>
  )
}
