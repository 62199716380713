import { get } from 'dot-prop'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { ChangelogEntry } from '@/modules/Changelog'
import { T } from '@/modules/Language'

export interface ChangelogEntryRowProps {
  data: ChangelogEntry
}

export const ChangelogEntryRow = ({ data }: ChangelogEntryRowProps) => {
  const timestamp = moment(data.timestamp)
  const entityType = data.targets.slice(-1)[0].type

  return (
    <Wrapper>
      <Header>
        <span>
          {timestamp.format('LL')} {timestamp.format('LT')}
        </span>
        <span>{get(data, 'actor.name') || 'system'}</span>
      </Header>
      <OperationName>
        <T l10n={`Changelog:operation.${data.operation}`} />
        <small>
          {data.targets.slice(1).map(({ id, label, type }) => (
            <TargetSpecifier key={`target-specifier-${id}`}>
              <T l10n={`Changelog:field.${type}.__typename`} />:{' '}
              <em>{label}</em>
            </TargetSpecifier>
          ))}
        </small>
      </OperationName>
      {data.values.map(({ next, previous, property }) => (
        <ValueChange key={`value-change-${data.id}-${property}`}>
          <dt>
            <T l10n={`Changelog:field.${entityType}.${property}`} />:
          </dt>
          <dd>
            {previous && (
              <>
                <OldValue>{previous}</OldValue> →{' '}
              </>
            )}
            <NewValue>{next || '—'}</NewValue>
          </dd>
        </ValueChange>
      ))}
    </Wrapper>
  )
}

export default ChangelogEntryRow

////////////

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`

const NewValue = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.success.dark};
  `}
`

const OldValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.dark};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const OperationName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const TargetSpecifier = styled.span`
  display: block;
  font-weight: 400;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`

const ValueChange = styled.dl`
  display: flex;
  margin: 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gutterSmall};
    `}
  }

  & dt {
    display: inline-block;
    flex: 0 0 30%;
    font-weight: 600;
    text-align: right;
    width: 30%;
  }

  & dd {
    display: inline-block;

    ${({ theme }) => css`
      margin-left: ${theme.spacing.gutter};
    `}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}

  &:not(:last-child) {
    ${({ theme }) => css`
      border-bottom: dashed 1px ${theme.palette.smoke.main};
    `}
  }
`
