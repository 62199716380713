import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  AddGiftCardPaymentMutation,
  AddGiftCardPaymentMutationVariables,
} from '~generated-types'

const ADD_GIFT_CARD_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation AddGiftCardPayment($input: PaymentGiftCardAddInput!) {
    paymentGiftCardAdd(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useAddGiftCardPaymentMutation = () =>
  useMutation<AddGiftCardPaymentMutation, AddGiftCardPaymentMutationVariables>(
    ADD_GIFT_CARD_PAYMENT_MUTATION
  )
