import { useState } from 'react'

import { MobileSalesParticipant } from '@/modules/Mobile/SalesList'

import { PurchaseForSalesPrintFragment } from '~generated-types'

import { InnerContainer, InnerSection } from '../../../components'
import { ParticipantDetails } from './ParticipantDetails'
import { ParticipantRow } from './ParticipantRow'
import { RoomParticipantRow } from './RoomParticipantRow'

type Props = {
  isRoomParticipant?: boolean
  participant: MobileSalesParticipant
  roomDates?: { checkIn: string; checkOut: string }
  showPrices: boolean
}

export const Participant = ({
  isRoomParticipant,
  participant,
  roomDates,
  showPrices,
}: Props) => {
  const {
    accommodationRequest,
    additionalInfo,
    age,
    ageCategory,
    allergies,
    allergyDescription,
    diets,
    drugAllergyDescription,
    group,
    id,
    services,
  } = participant

  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const expandParticipant = (id: string) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((existingId) => existingId !== id)
        : [...prevIds, id]
    )
  }

  const products = services
    .map(({ purchaseProduct }) => purchaseProduct)
    .filter(Boolean) as PurchaseForSalesPrintFragment[]

  const showDetails =
    !!accommodationRequest ||
    !!additionalInfo ||
    !!age ||
    !!ageCategory ||
    !!allergies.length ||
    !!allergyDescription ||
    !!diets.length ||
    !!drugAllergyDescription ||
    !!group ||
    !!products.length

  return (
    <InnerContainer compact={isRoomParticipant}>
      <InnerSection
        expand={() => expandParticipant(id)}
        isExpanded={expandedIds.includes(id)}
        isExpandable
        visibleContent={
          isRoomParticipant ? (
            <RoomParticipantRow
              participant={participant}
              roomDates={roomDates}
            />
          ) : (
            <ParticipantRow participant={participant} />
          )
        }
      >
        {showDetails && (
          <ParticipantDetails
            isRoomParticipant={isRoomParticipant}
            participant={participant}
            showPrices={showPrices}
          />
        )}
      </InnerSection>
    </InnerContainer>
  )
}
