import { useEffect, useState } from 'react'

import { CategorySelector as ElasticCategorySelector } from '@/components/Reactivesearch'
import { CalendarResourcesService } from '@/modules/Registry'
import { generateCompareFn } from '@/utils/arrays'

type Props = {
  initialSelection: string[]
  isCollapsed: boolean
  isRestrictedMode: boolean
  onUpdateCategories: (categoryPaths: string[]) => void
  onUpdateResources: (resourceIds: string[]) => void
  selectorId: string
}

const CategorySelector = ({
  initialSelection,
  isCollapsed,
  isRestrictedMode,
  onUpdateCategories,
  onUpdateResources,
  selectorId,
}: Props) => {
  const [selectedCategories, setSelectedCategories] = useState<{
    [setId: string]: string[]
  } | null>(null)

  useEffect(() => {
    if (!selectedCategories) {
      return
    }

    const paths = Object.values(selectedCategories).flat()
    onUpdateCategories(paths)

    if (!paths.length) {
      onUpdateResources([])
    } else {
      CalendarResourcesService.findAllByCategories(
        Object.keys(selectedCategories).map((x) => selectedCategories[x]),
        isRestrictedMode
      )
        .then((res) =>
          onUpdateResources(
            res.sort(generateCompareFn('name')).map(({ id }) => id) ?? []
          )
        )
        .catch((err) => {
          console.warn('Failed to resolve calendar resources', err)
          return []
        })
    }
  }, [isRestrictedMode, selectedCategories])

  return (
    <ElasticCategorySelector
      categoryTargetKey="RESOURCE_SEARCH"
      componentId={`category-${selectorId}`}
      dataField="category.path"
      defaultQuery={() => ({})}
      hideEmpty
      forcedInitialSelection={initialSelection}
      isCollapsed={isCollapsed}
      onUpdateSelection={(selected) => setSelectedCategories(selected)}
      showFilter={false}
      URLParams={false}
    />
  )
}

export default CategorySelector
