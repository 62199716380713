import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { EnrollmentStateFilterType } from '@/modules/ParticipantsList'

import { SalesState } from '~generated-types'

import { CheckboxLabel, CheckboxWrapper } from './common'

type Props = {
  filters: EnrollmentStateFilterType[] | null
  setFilters: (filters: EnrollmentStateFilterType[] | null) => void
}

export const EnrollmentStateFilter = ({ filters, setFilters }: Props) => {
  const { language } = useLanguageContext()

  const handleSelect = (targetFilter: EnrollmentStateFilterType) => {
    if (!filters) {
      return setFilters([targetFilter])
    }

    const newFilters = !!filters.find((f) => f.value === targetFilter.value)
      ? filters.filter((f) => f.value !== targetFilter.value)
      : [...filters, targetFilter]

    return setFilters(newFilters.length ? newFilters : null)
  }

  return (
    <FlexColumn noPadding>
      {Object.values(SalesState).map((value) => (
        <CheckboxWrapper alignItems="center" key={value}>
          <CheckboxInput
            checked={!!filters?.find((v) => v.value === value)}
            noMargin
            onChange={() =>
              handleSelect({
                label: translate(
                  `SalesDetails:Lifecycle.state.${value}`,
                  language
                ),
                value,
              })
            }
          >
            <CheckboxLabel>
              {translate(`SalesDetails:Lifecycle.state.${value}`, language)}
            </CheckboxLabel>
          </CheckboxInput>
        </CheckboxWrapper>
      ))}
    </FlexColumn>
  )
}
