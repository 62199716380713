import { Owner, WithId } from '@/common/types'
import { ElasticEmbeddedCustomer } from '@/modules/Registry'
import { ElasticSalesContext } from '@/modules/Sales'

// GENERATED TYPES
import type {
  ConfirmationFragment,
  ConfirmationInfoFragment,
  DocumentFragment,
  InvoiceFragment,
  InvoiceInfoFragment,
  OfferFragment,
  OfferInfoFragment,
  OrderInfoFragment,
  OrderState,
  PaymentPointOfSaleFragment,
} from '~generated-types'

export type Confirmation = ConfirmationFragment
export type ConfirmationInfo = ConfirmationInfoFragment
export type ConfirmationValidatedAction =
  Confirmation['lifecycle']['validatedActions'][0]

export type Document = DocumentFragment
export type DocumentUserAttribute = Document['userAttributes'][0]
export type DocumentTemplate = Document['template']

export type Payment = Invoice['payments'][0]
export type PaymentDetails = Payment['details']
export type PointOfSale = PaymentPointOfSaleFragment
export type PaymentExternalDetails = NonNullable<Payment['externalPayment']>

export type Invoice = InvoiceFragment
export type InvoiceConfig = Invoice['config']
export type InvoiceInfo = InvoiceInfoFragment
export type InvoiceSeller = NonNullable<InvoiceFragment['seller']>
export type InvoiceSellerProductsConfig = InvoiceSeller['productsConfig']
export type InvoiceSellerProduct =
  | InvoiceSellerProductsConfig['advanceProducts'][0]
  | InvoiceSellerProductsConfig['serviceFeeProducts'][0]
export type InvoiceValidatedAction = Invoice['lifecycle']['validatedActions'][0]

export type Offer = OfferFragment
export type OfferInfo = OfferInfoFragment
export type OfferValidatedAction = Offer['lifecycle']['validatedActions'][0]

export type OrderType = Omit<
  OrderInfoFragment,
  'confirmations' | 'invoices' | 'offers'
> & {
  confirmations: (ConfirmationInfo | Confirmation)[]
  invoices: (InvoiceInfo | Invoice)[]
  offers: (OfferInfo | Offer)[]
}
export type Customer = NonNullable<OrderType['customer']>
export type CustomerDetails = Customer['details']
export type CustomerAddress = CustomerDetails['address']

export type OrderLifecycle = OrderType['lifecycle']
export type OrderSales = OrderType['sales']
export type OrderValidatedAction = OrderType['lifecycle']['validatedActions'][0]
export type ValidationIssue = OrderValidatedAction['issues'][0]

export type OrderItem = OrderType['items'][0]
export type OrderItemParticipant = NonNullable<
  NonNullable<OrderItem['source']['purchase']>['link']['participant']
>
export type OrderItemProduct = OrderItem['products'][0]

export type {
  ConfirmationByIdQuery as ConfirmationByIdPayload,
  ConfirmationByIdQueryVariables as ConfirmationByIdVariables,
  DocumentSubscription,
  DocumentSubscriptionVariables,
  InvoiceByIdQuery as InvoiceByIdPayload,
  InvoiceByIdQueryVariables as InvoiceByIdVariables,
  OfferByIdQuery as OfferByIdPayload,
  OfferByIdQueryVariables as OfferByIdVariables,
  OrdersQuery as OrdersPayload,
  OrdersQueryVariables as OrdersVariables,
  OrderLifecycleQuery,
  OrderLifecycleQueryVariables,
  OrderSubscription,
  OrderSubscriptionVariables,
  OtherPaymentTypesQuery,
  PaymentCashDetailsFragment as PaymentCashDetails,
  PaymentCreditCardDetailsFragment as PaymentCreditCardDetails,
  PaymentGiftCardDetailsFragment as PaymentGiftCardDetails,
  PaymentOtherDetailsFragment as PaymentOtherDetails,
  PaymentReferenceTransferDetailsFragment as PaymentReferenceTransferDetails,
  PaymentRefundDetailsFragment as PaymentRefundDetails,
  PaymentReservationFeeDetailsFragment as PaymentReservationFeeDetails,
  PaymentSubscription,
  PaymentSubscriptionVariables,
  PaymentTerminalsQuery,
  PaymentType,
  PaymentVoucherDetailsFragment as PaymentVoucherDetails,
  PointsOfSalesQuery,
  VoucherProvidersQuery,
} from '~generated-types'

// MANUAL TYPES

export enum OtherPaymentMethod {
  Payment = 'PAYMENT',
  Refund = 'REFUND',
}

type OrderInvoice = {
  type: 'CHARGE' | 'REFUND'
}

type ElasticOrderPrices = {
  vatExcluded: number | null | undefined
  vatIncluded: number | null | undefined
  vatAmount: number | null | undefined
}

export type ElasticOrder = ElasticEmbeddedCustomer &
  WithId & {
    createdAt: string
    invoice: OrderInvoice | null | undefined
    name: string | null | undefined
    order: number
    orderState: OrderState
    owner: Owner | null | undefined
    prices: ElasticOrderPrices | null | undefined
    sales: ElasticSalesContext | null | undefined
  }
