import React, { ReactNode } from 'react'

import {
  ListItem as Wrapper,
  ListItemSection as Section,
} from '@/components/List'
import { ElasticCustomer } from '@/modules/Registry'

import AddressInfo from './components/AddressInfo'
import ContactInfo from './components/ContactInfo'
import Labels from './components/Labels'
import NameInfo from './components/NameInfo'
import TypeInfo from './components/TypeInfo'

type Props = {
  data: ElasticCustomer
  isHighlighted?: boolean
  itemControls?: ReactNode | null | undefined
  onOpen: () => void
}

const ListItem = ({ data, isHighlighted, itemControls, onOpen }: Props) => (
  <Wrapper highlight={isHighlighted} onClick={onOpen}>
    <TypeInfo type={data.customerType} />

    <NameInfo data={data} />

    <AddressInfo address={data.defaultAddress} />

    <ContactInfo data={data} />

    <Labels data={data.labels || []} />

    {itemControls && (
      <Section align="right" minimalRightPadding>
        {itemControls}
      </Section>
    )}
  </Wrapper>
)

export default ListItem
