import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import {
  DataSearch,
  ElasticBaseUrl,
  ReactiveBase,
} from '@/components/Reactivesearch'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { ElasticSales } from '@/modules/Sales'

import { Title } from './common'

type Props = {
  reference: string
  setReference: (reference: string) => void
}

type SuggestionType = {
  _source: ElasticSales
}

export const SalesReferenceInput = ({ reference, setReference }: Props) => {
  const [value, setValue] = useState<string>(reference ? `#${reference}` : '')

  return (
    <InlineModalSection style={{ marginTop: `0` }}>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.salesReference</T>
        </Title>

        <ReactiveBase app="sales.sales" url={ElasticBaseUrl}>
          <DataSearch
            componentId="search"
            render={({
              loading,
              rawData,
              downshiftProps: { isOpen, selectItem },
            }) => {
              const data = rawData?.hits.hits || []

              return !!data.length
                ? isOpen && (
                    <SuggestionsWrapper>
                      {data.map(
                        ({ _source }: SuggestionType, index: number) => (
                          <Suggestion
                            isFirst={index === 0}
                            isLast={index === data.length - 1}
                            key={_source.orderNumber}
                            onClick={() => selectItem({ source: _source })}
                          >
                            {`#${_source.orderNumber} ${
                              _source.name ? `– ${_source.name}` : ''
                            }`}
                          </Suggestion>
                        )
                      )}
                    </SuggestionsWrapper>
                  )
                : isOpen && value && !loading && (
                    <SuggestionsWrapper>
                      <Suggestion isFirst isLast readOnly>
                        <FontColor lighter>
                          <T>ElasticFilterSearchList:empty</T>
                        </FontColor>
                      </Suggestion>
                    </SuggestionsWrapper>
                  )
            }}
            dataField={['name', 'orderNumber.text']}
            onChange={(value) => setValue(value)}
            onValueSelected={(_, cause, source) => {
              if (source && cause === 'SUGGESTION_SELECT') {
                const { name, orderNumber } = source

                setReference(`${orderNumber}`)
                setValue(`#${orderNumber} ${name ? `– ${name}` : ''}`)
              }
              if (cause === 'CLEAR_VALUE') {
                setReference('')
                setValue('')
              }
            }}
            placeholder=""
            queryFormat="and"
            showClear
            showIcon={false}
            value={value}
          />
        </ReactiveBase>
      </FlexColumn>
    </InlineModalSection>
  )
}

////////

const Suggestion = styled.div<{
  isFirst?: boolean
  isLast?: boolean
  readOnly?: boolean
}>`
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gu(1)}rem;
    border-bottom: 1px solid ${theme.palette.smoke.light};
  `}

  ${({ isLast }) => isLast && `border-bottom: none;`}

  &:hover {
    ${({ readOnly, theme }) =>
      !readOnly && `background: ${theme.palette.smoke.lighter};`}

    ${({ isFirst }) =>
      isFirst &&
      `
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  `}

    ${({ isLast }) =>
      isLast &&
      `
    border-bottom: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  `}
  }
`

const SuggestionsWrapper = styled(FlexColumn)`
  position: absolute;
  overflow-y: auto;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.24);
  z-index: 1;

  ${({ theme }) => css`
    max-height: ${theme.spacing.gu(33)}rem;
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
  `}
`
