import styled, { css } from 'styled-components/macro'

import { Label } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { salesHooks } from '@/modules/Sales'

import { Cell } from '../Cell'
import { FormatNumberInput } from './FormatNumberInput'

export const Lessons = () => {
  const {
    data: { id: salesId, vst, type },
    setVstAttributes,
    refresh,
  } = salesHooks.useSalesDetailsContext()

  const otherLessonHours = vst?.otherLesson.hours || 0
  const participantLessonHours = vst?.participantLessonSummary.hours || 0

  const handleSetLessonHours = (value: string | null) =>
    setVstAttributes({
      otherLessonHours: Number(value || 0) || null,
      salesId,
    }).then(refresh)

  return (
    <FlexColumn alignItems="flex-start" noPadding>
      <Title>
        <T>ParticipantsList:VST.lessons</T>
      </Title>

      <FlexRow>
        <Cell justifyContent="flex-start" width={12}>
          <T>ParticipantsList:participant</T>
        </Cell>

        <FlexRow>
          <Cell justifyContent="flex-end" width={10}>
            {participantLessonHours} <T>enums:quantityUnit.abbrev.HOUR</T>
          </Cell>
        </FlexRow>
      </FlexRow>

      {type === 'ENROLLMENT' ? (
        otherLessonHours && (
          <FlexRow>
            <Cell justifyContent="flex-start" width={22}>
              <Cell justifyContent="flex-start" width={12}>
                <T>ParticipantsList:VST.other</T>
              </Cell>

              <Cell justifyContent="flex-end" width={10}>
                {otherLessonHours} <T>enums:quantityUnit.abbrev.HOUR</T>
              </Cell>
            </Cell>
          </FlexRow>
        )
      ) : (
        <FormatNumberInputWrapper>
          <FormatNumberInput
            value={(otherLessonHours || '').toString()}
            handleSubmit={handleSetLessonHours}
            endAdornment={<T>enums:quantityUnit.abbrev.HOUR</T>}
            placeholder={<T>SalesDetails:VST.setOtherHours</T>}
          />
        </FormatNumberInputWrapper>
      )}
    </FlexColumn>
  )
}

/////////

const FormatNumberInputWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
