import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T } from '@/modules/Language'
import { orderMutations } from '@/modules/Order'
import { EditButton } from '@/modules/Order/components/Sections'
import {
  Document as DocumentType,
  DocumentTemplate,
} from '@/modules/Order/types'
import { Spacing, useTheme } from '@/theme'

import { Section, SubTitle } from './common'

type Props = {
  document: DocumentType
  onUpdateDocument: (document: DocumentType) => void
}

export const TemplateSelector = ({
  document: { id, template, templateOptions },
  onUpdateDocument,
}: Props) => {
  const { spacing } = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const [setDocumentTemplate] = orderMutations.useSetDocumentTemplateMutation()

  const onSetDocumentTemplate = (templateId: string) => {
    if (template.id === templateId) {
      setEditMode(false)
      return
    }

    setProcessing(true)

    setDocumentTemplate({ variables: { input: { id, templateId } } })
      .then(({ data }) => data && onUpdateDocument(data.documentSetTemplate))
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        setEditMode(false)
      })
  }

  const options = [
    ...templateOptions.map((o: DocumentTemplate) => ({
      label: o.name,
      value: o.id,
    })),
    {
      label: template.name,
      value: template.id,
    },
  ]

  return (
    <Section>
      <SubTitle>
        <T>Orders:Documents.template.title</T>
      </SubTitle>

      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={isProcessing}
          menuIsOpen={isEditMode}
          name="document-template-selector"
          noOptionsMessage={() => <T>Orders:Documents.template.empty</T>}
          onBlur={() => setEditMode(false)}
          onChange={(selected: Option | null | undefined) =>
            onSetDocumentTemplate(selected ? selected.value : '')
          }
          options={options}
          placeholder={<T>Orders:Documents.template.placeholder</T>}
          value={{
            label: template.name,
            value: template.id,
          }}
        />
      ) : (
        <StyledEditButton onClick={() => setEditMode(true)}>
          {template.name}
        </StyledEditButton>
      )}
    </Section>
  )
}

////////

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
  }),
})

const StyledEditButton = styled(EditButton)`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    margin-left: -${theme.spacing.gu(1)}rem;
    height: 30px;
  `}
`
