import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { MobileSalesCustomer } from '@/modules/Mobile/SalesList'
import { parseCustomer } from '@/modules/Mobile/SalesList/utils'

import { Section } from '../components'
import { Address } from './Address'
import { Notes } from './Notes'

type Props = {
  customer: MobileSalesCustomer | null
  notes: string
}

export const Customer = ({ customer, notes }: Props) => {
  if (!customer) {
    return (
      <Section isExpandable title={<T>SalesDetails:PrintSale.label.NOTES</T>}>
        <Notes notes={notes} noTitle />
      </Section>
    )
  }

  const { contactPerson, email, name, phone, postalAddress } =
    parseCustomer(customer)

  const showAddress =
    !!postalAddress?.address1 ||
    !!postalAddress?.address2 ||
    !!postalAddress?.city ||
    !!postalAddress?.country ||
    !!postalAddress?.postcode

  return (
    <Section
      isExpandable={showAddress || !!notes}
      extraDetails={
        <DetailsWrapper>
          <span>
            {name || (
              <Placeholder>
                <T>Customers:unnamed</T>
              </Placeholder>
            )}
          </span>
          {contactPerson && <span>{contactPerson}</span>}
          {phone && <span>{phone}</span>}
          {email && <span>{email}</span>}
        </DetailsWrapper>
      }
      title={<T>SalesDetails:PrintSale.label.CUSTOMER</T>}
    >
      {showAddress && <Address address={postalAddress} />}

      {showAddress && <Spacer />}

      {notes && <Notes notes={notes} />}
    </Section>
  )
}

/////////

const DetailsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(2)}rem;
  `}
`
