import { ReactNode, useEffect } from 'react'
import { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { H3 } from '@/components/Typography'
import { T, useLanguageContext } from '@/modules/Language'
import type { CalendarResourceElastic } from '@/modules/Registry'
import type { CategorySelection } from '@/modules/Reservations'

import { AssigneeType, TaskContext } from '~generated-types'

import { PlaceholderLabel } from '../../common'
import { PrintSize } from '../../types'
import {
  ShowReservationGroups,
  SortOption,
} from '../CalendarResourceListingContainer'
import { TasksSection } from './TaskSection/TasksSection'
import { ReservationSection } from './ReservationSection'
import useReservationList from './useReservationList'
import { ListingTask, useTaskList } from './useTaskList'

type Props = {
  allResources: CalendarResourceElastic[]
  date: Moment
  printSize: PrintSize
  resourceIds: string[]
  selectedCategories: CategorySelection[]
  selectedResources: CalendarResourceElastic[]
  showAllTasks: boolean
  showExtendedTasks: boolean
  showReservationGroups: ShowReservationGroups
  sortBy: SortOption
  taskTarget: string[]
  taskTargetResources: CalendarResourceElastic[]
  viewAssigneeSelection: string[]
}

const ReservationListingForDate = ({
  allResources,
  date,
  printSize,
  resourceIds,
  selectedCategories,
  selectedResources,
  showAllTasks,
  showExtendedTasks,
  showReservationGroups,
  sortBy,
  taskTarget,
  taskTargetResources,
  viewAssigneeSelection,
}: Props) => {
  const { error, loading, reservations } = useReservationList({
    date,
    resourceIds,
  })

  const {
    loadTasks,
    loading: tasksLoading,
    error: tasksError,
    salesTasks,
  } = useTaskList()
  const { language } = useLanguageContext()

  // Handle sales tasks query only when task target has value
  useEffect(() => {
    if (taskTarget.length && !tasksLoading) {
      loadTasks({
        variables: {
          input: {
            assignees: taskTarget.map((id) => ({
              id,
              type: AssigneeType.UserGroup,
            })),
            context: TaskContext.Sales,
            date: date.format('YYYY-MM-DD'),
          },
        },
      })
    }
  }, [JSON.stringify(taskTarget)])

  const getReservationsForResources = (
    resourceIds: string[],
    taskTarget?: string[]
  ) =>
    reservations
      .filter((r) => r.resource?.id && resourceIds.includes(r.resource.id))
      .filter(
        ({ tasks }) =>
          !taskTarget?.length ||
          !!tasks.find((t) => taskTarget?.includes(t.assignee?.id ?? ''))
      ) || []

  const renderReservationsForCategorySelection = (
    allResources: CalendarResourceElastic[],
    { active, id, label, resourceIds }: CategorySelection,
    idx: number
  ) => {
    const targetResourceIds = resourceIds.reduce((acc: string[], id) => {
      const nestedResourceIds =
        allResources
          .find((r) => r.id === id)
          ?.nestedResources?.map(({ id }) => id) || []
      return [...acc, id, ...nestedResourceIds]
    }, [])

    const targetReservations = getReservationsForResources(targetResourceIds)

    return active ? (
      <ReservationSection
        key={`category-reservations-${id}`}
        label={
          label || (
            <span>
              <T>ResourceReservationsCalendar:ResourceSearchGroup.title</T> #
              {idx + 1}
            </span>
          )
        }
        printSize={printSize}
        reservations={targetReservations}
        showAllTasks={showAllTasks}
        showExtendedTasks={showExtendedTasks}
        showReservationGroups={showReservationGroups}
        sortBy={sortBy}
        viewAssigneeSelection={viewAssigneeSelection}
      />
    ) : null
  }

  const renderReservationsForResources = ({
    label,
    resources,
    taskTarget,
  }: {
    label: ReactNode
    resources: CalendarResourceElastic[]
    taskTarget?: string[]
  }) => {
    const resourceIds = resources.reduce(
      (acc: string[], resource: CalendarResourceElastic) => {
        acc.push(resource.id)
        resource.nestedResources?.map(({ id }) => acc.push(id))
        return acc
      },
      []
    )
    const targetReservations = getReservationsForResources(
      resourceIds,
      taskTarget
    )

    return (
      !(taskTarget?.length && !targetReservations.length) && (
        <ReservationSection
          label={label}
          printSize={printSize}
          reservations={targetReservations}
          showAllTasks={showAllTasks}
          showExtendedTasks={showExtendedTasks}
          showReservationGroups={showReservationGroups}
          sortBy={sortBy}
          viewAssigneeSelection={viewAssigneeSelection}
        />
      )
    )
  }

  const renderSalesTasks = ({
    label,
  }: {
    label: ReactNode
    salesTasks: ListingTask[]
  }) => {
    return (
      <TasksSection
        label={label}
        printSize={printSize}
        salesTasks={salesTasks}
      />
    )
  }

  const renderContent = () => {
    if (loading || tasksLoading) {
      return <LoadingPlaceholder size="xl" />
    }

    const hasError = error || tasksError
    const noSalesTasks =
      !salesTasks.length || (salesTasks.length && !taskTarget)

    if (hasError || (!reservations.length && noSalesTasks)) {
      return (
        <PlaceholderLabel>
          – <T>Listings:CalendarResourceListing.empty</T> –
        </PlaceholderLabel>
      )
    }

    return (
      <>
        {!!taskTargetResources.length &&
          renderReservationsForResources({
            label: <T>Listings:CalendarResourceListing.taskTargetResources</T>,
            resources: taskTargetResources,
            taskTarget,
          })}

        {taskTarget &&
          renderSalesTasks({
            label: <T>Listings:CalendarResourceListing.salesTasks</T>,
            salesTasks: salesTasks,
          })}

        {!!selectedResources.length &&
          renderReservationsForResources({
            label: <T>Listings:CalendarResourceListing.selectedResources</T>,
            resources: selectedResources,
          })}
        {selectedCategories.map((x, idx) =>
          renderReservationsForCategorySelection(allResources, x, idx)
        )}
      </>
    )
  }

  return (
    <Wrapper>
      <DateLabel>{date.locale(language).format('dd D.M.YYYY')}</DateLabel>
      {renderContent()}
    </Wrapper>
  )
}

export default ReservationListingForDate

////////////

const Wrapper = styled(FlexColumn)`
  padding: 0;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(6)}rem;
  `}
`

const DateLabel = styled(H3)`
  font-weight: 500;
  break-after: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
    font-size: ${theme.typography.fontSizeBigger};
  `}

  @media print {
    display: block;
  }
`
