import { gql, useQuery } from '@apollo/client'

import {
  CategoryQuery as QueryData,
  CategoryQueryVariables as QueryVariables,
} from '~generated-types'

import categoryFragments from '../fragments'

type CategoryWithChildren = QueryData['registry']['category']

export type Category = CategoryWithChildren

export const QUERY = gql`
  ${categoryFragments.category}

  query Category($categoryId: ID!) {
    registry {
      category(categoryId: $categoryId) {
        ...Category

        children {
          ...Category
        }
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
  categoryId: string
}

export default function useCategory({ forceRefetch, categoryId }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
      variables: { categoryId },
    }
  )

  const category: CategoryWithChildren | null | undefined = data
    ? {
        ...data.registry.category,
        children: [...data.registry.category.children],
      }
    : null

  return {
    category,
    error,
    loading,
    refetch,
  }
}
