import { gql } from '@apollo/client'

import { dimensionFragments } from '@/modules/Dimensions'
import customerReference from '@/modules/Registry/Customer/fragments/customerReference'

import webshopSalesToken from '../components/SalesDetails/routes/Publish/fragments/webshopSalesToken'

export default gql`
  ${customerReference}
  ${dimensionFragments.dimensionReference}
  ${webshopSalesToken}

  subscription SalesSubscription($filter: SalesFilterInput!) {
    sales(filter: $filter) {
      event {
        ... on SalesDimensionEvent {
          id
          type
          data {
            ...DimensionReference
          }
        }
        ... on SalesTokenEvent {
          id
          type
          data {
            ...WebshopSalesToken
          }
        }
        ... on SalesCustomerEvent {
          id
          type
          data {
            ...CustomerReference
          }
        }
      }
      salesId
    }
  }
`
