import { useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Input, Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { InputEditor, ToggleEditableValue } from '@/components/ToggleHelpers'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { useSalesDetailsContext } from '../../../'
import { HeaderValue, HeaderValuePlaceholder } from './HeaderValue'

export const EstimatedParticipants = () => {
  const theme = useTheme()
  const {
    data: { id, estimatedParticipants },
    saleReadOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  const [participants, setParticipants] = useState<number | null | undefined>(
    estimatedParticipants
  )

  const handleUpdate = (participants: number | null | undefined) =>
    updateAttributes(id, { participants })
      .then((data) =>
        setParticipants(data?.estimatedParticipants ?? participants)
      )
      .catch(() => undefined)

  return (
    <Wrapper alignItems="flex-start">
      <Label style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}>
        <T>SalesDetails:EstimatedParticipants.title</T>
      </Label>
      {saleReadOnly ? (
        participants ? (
          <HeaderValue>
            {participants} <T>SalesDetails:EstimatedParticipants.unit</T>
          </HeaderValue>
        ) : (
          <HeaderValuePlaceholder>–</HeaderValuePlaceholder>
        )
      ) : (
        <ToggleEditableValue
          hideEditIcon
          mode="input"
          placeholder={
            <HeaderValuePlaceholder>
              <T>SalesDetails:EstimatedParticipants.placeholder</T>
            </HeaderValuePlaceholder>
          }
          renderEditor={(onClose) => (
            <InputEditor
              close={onClose}
              handleSubmit={(newValue: string | null) =>
                handleUpdate(newValue ? parseInt(newValue, 10) : null)
              }
              inputComponent={Input}
              onFocus={(e) => e.currentTarget.select()}
              style={{
                height: 28,
                margin: `-${theme.spacing.gu(1)}rem`,
                width: `${theme.spacing.gu(13)}rem`,
              }}
              type="number"
              value={participants ? `${participants}` : null}
            />
          )}
          value={
            participants ? (
              <HeaderValue>
                {participants} <T>SalesDetails:EstimatedParticipants.unit</T>
              </HeaderValue>
            ) : null
          }
        />
      )}
    </Wrapper>
  )
}

////////////

const Wrapper = styled(FlexColumn)`
  white-space: nowrap;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
    padding: ${theme.spacing.gutterSmall} 0;
  `}
`
