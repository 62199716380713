import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PrimaryColor } from '@/components/Colors'
import { InnocuousButtonLink } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { T, useLanguageContext } from '@/modules/Language'
import { ElasticSalesContext, SalesTypeChip } from '@/modules/Sales'
import { useTheme } from '@/theme'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

type Props = {
  data: ElasticSalesContext
}

export const SalesDetails = ({ data }: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const [dates, setDates] = useState<string | null | undefined>(null)

  useEffect(() => {
    setDates(
      data.estimatedDates
        ? formatDateRangeWithoutRepetition(
            data.estimatedDates.start,
            data.estimatedDates.end
          )
        : null
    )
  }, [language, data.estimatedDates])

  return (
    <FlexRow alignItems="center" justifyContent="space-between">
      <FlexColumn alignItems="flex-start">
        <FlexRow style={{ marginBottom: spacing.gutterSmall }}>
          <SalesTypeChip format="full" size="xs" type={data.type} />
          <span>
            {data.name ? (
              data.name
            ) : (
              <FontColor lighter>
                <T l10n={`SalesSearchList:ListItem.unnamed.${data.type}`} />
              </FontColor>
            )}
          </span>
        </FlexRow>
        {dates && <small>{dates}</small>}
      </FlexColumn>
      <InnocuousButtonLink
        color="primary"
        href={`/sales/details/${data.id}`}
        size="small"
        target="_blank"
      >
        <PrimaryColor>
          <T>Customers:orders.openTarget</T>
          <FontAwesomeIcon
            icon="up-right-from-square"
            size="sm"
            style={{ marginLeft: `${spacing.gu(1)}rem` }}
          />
        </PrimaryColor>
      </InnocuousButtonLink>
    </FlexRow>
  )
}
