import React, { Component, ReactNode } from 'react'

import { PostalAddress as PostalAddressType } from '@/common/types'
import { PlainLinkButtonButton } from '@/components/ExtraButtons/PlainLinkButtonButton'
import {
  InputErrorDescription,
  Label,
  TextInput,
} from '@/components/FormControls'
import { FormField } from '@/components/FormWrappers'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  readonly namePrefix: string
  readonly value: PostalAddressType
  readonly onChange: (...args: Array<any>) => any
  readonly onBlur?: (...args: Array<any>) => any
  readonly disabled?: boolean
  readonly errors?: {
    [key: string]: any
  }
}
type State = {
  showCountry: boolean
}
export class PostalAddress extends Component<Props, State> {
  state = {
    showCountry: false,
  }

  showCountryInputs = () => {
    this.setState({
      showCountry: true,
    })
  }

  render() {
    const { disabled, errors, namePrefix, onBlur, onChange, value } = this.props
    const { showCountry } = this.state
    const eventHandlers = { onBlur, onChange }
    const showCountryInputs = showCountry || (value && value.country)
    const getName = (field: string): string => `${namePrefix}.${field}`
    const getErrorMessage = (field: string, error: string): ReactNode => (
      <React.Fragment>
        <T>{`PostalAddressControl:fields.${field}`}</T>{' '}
        <T>{`PostalAddressControl:errors.${error}`}</T>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <FormField whitespace="gutter">
          <Label>
            <T>PostalAddressControl:fields.address1</T>*
          </Label>
          <TextInput
            name={getName('address1')}
            value={value.address1 || ''}
            {...eventHandlers}
            disabled={disabled}
          />
          <TextInput
            name={getName('address2')}
            value={value.address2 || ''}
            {...eventHandlers}
            disabled={disabled}
          />
          {errors && errors.streetAddress && (
            <InputErrorDescription>
              {getErrorMessage('streetAddress', errors.streetAddress)}
            </InputErrorDescription>
          )}
        </FormField>
        <FlexRow>
          <FormField whitespace="gutter">
            <Label>
              <T>PostalAddressControl:fields.postcode</T>*
            </Label>
            <TextInput
              name={getName('postcode')}
              value={value.postcode || ''}
              {...eventHandlers}
              disabled={disabled}
            />
            {errors && errors.postcode && (
              <InputErrorDescription>
                {getErrorMessage('postcode', errors.postcode)}
              </InputErrorDescription>
            )}
          </FormField>
          <FormField whitespace="gutter">
            <Label>
              <T>PostalAddressControl:fields.city</T>*
            </Label>
            <TextInput
              name={getName('city')}
              value={value.city || ''}
              {...eventHandlers}
              disabled={disabled}
            />
            {errors && errors.city && (
              <InputErrorDescription>
                {getErrorMessage('city', errors.city)}
              </InputErrorDescription>
            )}
          </FormField>
        </FlexRow>
        <FlexRow>
          {!showCountryInputs ? (
            <PlainLinkButtonButton
              id="show-country-inputs-button"
              type="button"
              asText
              noNudge
              onClick={this.showCountryInputs}
            >
              <T>PostalAddressControl:action.controlCountry</T>
            </PlainLinkButtonButton>
          ) : (
            <FlexRow>
              <FormField whitespace="gutter">
                <Label>
                  <T>PostalAddressControl:fields.country</T>
                </Label>
                <TextInput
                  name={getName('country')}
                  value={value.country || ''}
                  {...eventHandlers}
                  disabled={disabled}
                />
                {errors && errors.country && (
                  <InputErrorDescription>
                    {getErrorMessage('country', errors.country)}
                  </InputErrorDescription>
                )}
              </FormField>
            </FlexRow>
          )}
        </FlexRow>
      </React.Fragment>
    )
  }
}
