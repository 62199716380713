import { gql, useMutation } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

import type {
  CreateConfirmationMutation,
  CreateConfirmationMutationVariables,
} from '~generated-types'

const CREATE_CONFIRMATION_MUTATION = gql`
  ${orderFragments.confirmation}

  mutation CreateConfirmation($input: OrderCreateConfirmationInput!) {
    orderCreateConfirmation(input: $input) {
      confirmation {
        ...Confirmation
      }
    }
  }
`

export const useCreateConfirmationMutation = () =>
  useMutation<CreateConfirmationMutation, CreateConfirmationMutationVariables>(
    CREATE_CONFIRMATION_MUTATION
  )
