import { ChangeEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'
import { Textarea } from '@/components/FormControls'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { MealRow, useSetMealNotesMutation } from '@/modules/Meals'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import { ScheduledMealStatus as Status } from '~generated-types'

import { useMealsContext } from '../../../../MealsState'
import { Row, Section } from '../../../common'

type Props = {
  meal: MealRow
}

export const NoteRow = ({ meal: { ids, notes, statuses } }: Props) => {
  const { palette, spacing } = useTheme()
  const { saleReadOnly } = useSalesDetailsContext()
  const { isEditMode } = useMealsContext()

  const [isNoteOpen, setNoteOpen] = useState<boolean>(false)
  const [noteValue, setNoteValue] = useState(notes ?? '')

  const isRemoved = statuses.includes(Status.ManuallyRemoved)

  const [setNotes] = useSetMealNotesMutation()

  const handleSetNotes = () =>
    setNotes({
      variables: {
        id: ids[0],
        input: {
          notes: noteValue,
        },
      },
    })
      .then(() => setNoteOpen(false))
      .catch(() => undefined)

  return (
    <>
      {(isEditMode || !isRemoved) && (
        <Row>
          <Section
            width={`${spacing.gu(6)}rem`}
            style={{ justifyContent: 'center' }}
          >
            <ModalContainer
              placement="bottom-start"
              isOpen={isNoteOpen}
              onClose={() => setNoteOpen(false)}
              modal={
                <InlineModal>
                  <TextareaWrapper>
                    <Textarea
                      disabled={isRemoved || saleReadOnly}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setNoteValue(e.target.value)
                      }
                      autoFocus
                      value={noteValue || ''}
                      onBlur={() => !saleReadOnly && handleSetNotes()}
                      style={{ height: 200, width: 360 }}
                    />
                  </TextareaWrapper>
                </InlineModal>
              }
              referenceElement={({ ref }) => (
                <IconButton
                  disabled={(saleReadOnly || isRemoved) && !notes}
                  onClick={() => setNoteOpen(true)}
                  ref={ref}
                >
                  <FontAwesomeIcon
                    color={notes ? palette.warning.dark : palette.primary.main}
                    fixedWidth
                    icon={notes ? ['far', 'note-sticky'] : 'plus'}
                  />
                </IconButton>
              )}
            />
          </Section>
        </Row>
      )}
    </>
  )
}

////////////

const IconButton = styled(Button)`
  display: flex;
  justify-content: center;
  background: transparent;
  border: none;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
    height: ${theme.spacing.gu(4)}rem;
  `}

  &:not(:disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:not(:disabled):active,
  &:not(:disabled):focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const TextareaWrapper = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}

  & > span {
    margin: 0;
  }
`
