import React from 'react'
import { ReactiveComponent } from '@appbaseio/reactivesearch'

import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T } from '@/modules/Language'
import { dimensionHooks } from '@/modules/Registry'
import { generateCompareFn } from '@/utils/arrays'

import { DimensionTarget } from '~generated-types'

import DimensionSelector from './DimensionSelector'
import transformSelectorValueToSelection from './transformSelectorValueToSelection'

type Props = {
  componentId: string
  defaultQuery: () => {
    [key: string]: any
  }
  dimensionTarget: DimensionTarget
  filterLabel?: string
  hideCounts?: boolean
  hideEmpty?: boolean
  isCollapsed?: boolean
  showFilter?: boolean
  URLParams?: boolean
}

const DimensionSelectorContainer = ({
  componentId,
  defaultQuery,
  dimensionTarget,
  filterLabel,
  hideCounts,
  hideEmpty,
  isCollapsed,
  showFilter = true,
  URLParams,
}: Props) => {
  const {
    dimensions,
    error: apiError,
    loading: apiLoading,
  } = dimensionHooks.useDimensionsByTarget({ target: dimensionTarget })

  const alphabeticalDimensions = dimensions.sort(generateCompareFn('name'))

  return (
    <ReactiveComponent
      componentId={componentId}
      defaultQuery={() => ({
        ...defaultQuery(),
        aggs: {
          dimensionLabels: {
            terms: {
              field: 'dimensions.key',
              // This is 25% of the default search.max_buckets value.
              // It should be more than enough as the amount of categories
              // is in the hundreds
              size: 2500,
            },
          },
          dimensions: {
            terms: {
              field: 'dimensions.dimension.key',
              // This is 25% of the default search.max_buckets value.
              // It should be more than enough as the amount of categories
              // is in the hundreds
              size: 2500,
            },
          },
        },
        size: 0,
      })}
      filterLabel={filterLabel}
      render={({ aggregations, error, loading, setQuery, value }) => {
        if (apiLoading || (!aggregations && loading)) {
          return <LoadingPlaceholder size="lg" style={{ width: '100%' }} />
        }

        if (apiError || error) {
          return (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          )
        }

        const dimensionCounts = getDimensionCounts(aggregations)
        const dimensionLabelCounts = getLabelCounts(aggregations)
        const selection = transformSelectorValueToSelection(value)

        return (
          <DimensionSelector
            dimensions={alphabeticalDimensions}
            componentId={componentId}
            documentCounts={{ ...dimensionCounts, ...dimensionLabelCounts }}
            hideCounts={hideCounts}
            hideEmpty={hideEmpty}
            isCollapsed={isCollapsed}
            setQuery={setQuery}
            value={selection}
          />
        )
      }}
      showFilter={showFilter}
      URLParams={URLParams}
    />
  )
}

export default DimensionSelectorContainer

////////////

const getDimensionCounts = (rawAggs: {
  [key: string]: any
}): {
  [path: string]: number
} => {
  const counts: { [key: string]: number } = {}
  const buckets = rawAggs?.dimensions?.buckets || []

  if (Array.isArray(buckets)) {
    buckets.forEach((x) => {
      if (x.key && typeof x.doc_count === 'number') {
        counts[x.key] = x.doc_count
      }
    })
  }

  return counts
}

const getLabelCounts = (rawAggs: {
  [key: string]: any
}): {
  [path: string]: number
} => {
  const counts: { [key: string]: number } = {}
  const buckets = rawAggs?.dimensionLabels?.buckets || []

  if (Array.isArray(buckets)) {
    buckets.forEach((x) => {
      if (x.key && typeof x.doc_count === 'number') {
        counts[x.key] = x.doc_count
      }
    })
  }

  return counts
}
