import { useRef } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components/macro'

import { DisabledColor } from '@/components/Colors'
import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import {
  CollapsedFilterValue,
  ReactiveComponent,
} from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'

export enum ExpiredState {
  HIDE = 'HIDE',
  SHOW = 'SHOW',
}

type Props = {
  componentId: string
  filterLabel: string
  isCollapsed: boolean
  l10nPrefix: string
  showFilter: boolean
  URLParams: boolean
}

export const ExpiredTasksFilter = ({
  componentId,
  filterLabel,
  isCollapsed,
  l10nPrefix,
  showFilter,
  URLParams,
}: Props) => {
  const isInitialised = useRef<boolean>(false)

  const { language } = useLanguageContext()

  const getQuery = (selection: ExpiredState) => {
    if (selection === ExpiredState.SHOW) {
      return {}
    }

    const value = translate(`${l10nPrefix}.${selection}`, language)

    return {
      query: {
        bool: {
          should: {
            bool: {
              must: [
                { range: { dueDate: { gte: moment().format('YYYY-MM-DD') } } },
              ],
            },
          },
        },
      },
      value,
    }
  }

  const transformSelectorValueToSelection = (value?: string): ExpiredState => {
    if (value) {
      if (value === translate(`${l10nPrefix}.${ExpiredState.HIDE}`, language)) {
        return ExpiredState.HIDE
      }
      if (value === translate(`${l10nPrefix}.${ExpiredState.SHOW}`, language)) {
        return ExpiredState.SHOW
      }
    }

    return ExpiredState.SHOW
  }

  return (
    <ReactiveComponent
      componentId={componentId}
      filterLabel={filterLabel}
      render={({ error, loading, setQuery, value: rawValue }) => {
        if (loading) {
          return <LoadingPlaceholder size="lg" style={{ width: '100%' }} />
        }

        if (error) {
          return (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          )
        }

        const value: ExpiredState = transformSelectorValueToSelection(rawValue)

        // Update the query on first render with data
        if (!isInitialised.current) {
          isInitialised.current = true
          setQuery(getQuery(value))
        }

        if (value === null) {
          setQuery({ value: [] })
        }

        if (isCollapsed) {
          return (
            <CollapsedFilterValue
              componentId={componentId}
              placeholder={<T>Reactivesearch:unfiltered</T>}
            />
          )
        }

        const handleChange = () =>
          setQuery(
            getQuery(
              value === ExpiredState.HIDE
                ? ExpiredState.SHOW
                : ExpiredState.HIDE
            )
          )

        return (
          <CheckboxInputWrapper>
            <CheckboxInput
              checked={value === ExpiredState.HIDE}
              onChange={handleChange}
            >
              <T>{`${l10nPrefix}.label`}</T>
            </CheckboxInput>
          </CheckboxInputWrapper>
        )
      }}
      showFilter={showFilter}
      URLParams={URLParams}
    />
  )
}

////////////

const CheckboxInputWrapper = styled(FlexRow)`
  &&&& label {
    margin-left: 2px;

    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}

    span {
      border-width: 1px;
    }

    &:hover {
      ${({ theme }) => css`
        color: ${theme.palette.primary.main};
      `}

      span {
        ${({ theme }) => css`
          border-color: ${theme.palette.primary.main};
        `}
      }
    }
  }
`
