import { Fragment } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { MobileSalesRoomFeature } from '@/modules/Mobile/SalesList'
import { useGlobalIconsContext } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'

type Props = {
  features: MobileSalesRoomFeature[]
}

export const Features = ({ features }: Props) => {
  const { checkIconValidity } = useGlobalIconsContext()

  return (
    <FlexRow flex={1}>
      {[...features].sort(generateCompareFn('name')).map((feature) => (
        <Fragment key={feature.id}>
          {checkIconValidity(feature.icon) ? (
            <Icon fixedWidth icon={feature.icon as IconProp} size="sm" />
          ) : (
            <ShortName>{feature.shortName}</ShortName>
          )}
        </Fragment>
      ))}
    </FlexRow>
  )
}

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const ShortName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
