import React from 'react'
import { createPortal } from 'react-dom'

import { ChangelogFAB, ChangelogOverlay } from '@/modules/Changelog'

interface ChangelogOverlayWidgetProps {
  offsetTop?: number
  target: string
}

export const ChangelogOverlayWidget = ({
  offsetTop,
  target,
}: ChangelogOverlayWidgetProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <>
      <ChangelogFAB onClick={() => setIsOpen((current) => !current)} />

      {createPortal(
        <ChangelogOverlay
          isVisible={isOpen}
          offsetTop={offsetTop}
          onClose={() => setIsOpen(false)}
          target={target}
        />,
        document.getElementById('modal-root') as HTMLDivElement
      )}
    </>
  )
}

export default ChangelogOverlayWidget
