import { FlexRow } from '@/components/Layout'
import { MobileSalesRoomReservation } from '@/modules/Mobile/SalesList'
import { formatDate, formatRoom } from '@/modules/Mobile/SalesList/utils'

import { Content, ContentWrapper } from '../../../components'

type Props = {
  roomReservation: MobileSalesRoomReservation
}

export const RoomRow = ({ roomReservation: { request } }: Props) => {
  const {
    beds,
    checkIn,
    checkOut,
    extraBeds,
    room: { number },
  } = request

  const end = checkOut.date
  const start = checkIn.date

  return (
    <ContentWrapper>
      <Content width="60%">
        <FlexRow alignItems="center" wrap="nowrap">
          <span style={{ fontWeight: 600 }}>{formatRoom(number)}</span>
          <span style={{ fontSize: '0.9rem', whiteSpace: 'pre-wrap' }}>
            {' '}
            ({beds} + {extraBeds})
          </span>
        </FlexRow>
      </Content>

      <Content width="40%">
        {end && start && formatDate({ end, start })}
      </Content>
    </ContentWrapper>
  )
}
